import React from "react";
import { connect } from "react-redux";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import { apiRequestType, routerTypes } from "_types";
import { startupData } from "_state/ducks/session/selectors";
import Reports from "./Reports";

const ReportsContainer = ({ startupData, match }) => (
  <Reports
    qboLink={startupData.data.currentCompany.qboLink}
    companyId={match.params.companyId}
  />
);

ReportsContainer.propTypes = {
  startupData: apiRequestType.isRequired,
  match: routerTypes.match.isRequired
};

const mapStateToProps = makeMapStateToProps({
  startupData
});

export default connect(
  mapStateToProps,
  null
)(ReportsContainer);
