import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "reactstrap";
import styles from "./Spinner.module.scss";

const withSpinner = InputComponent => {
  const WrappedComponent = ({ loading, ...rest }) => {
    if (loading) {
      return (
        <div className={`mx-auto ${styles.spinnerContainer}`}>
          <Spinner
            data-testid="spinner"
            color="primary"
            className={styles.spinner}
            type="grow"
          />
        </div>
      );
    }
    return <InputComponent {...rest} />;
  };

  WrappedComponent.propTypes = {
    loading: PropTypes.bool.isRequired
  };

  return WrappedComponent;
};

export default withSpinner;
