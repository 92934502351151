import { makeApiCall, makeSelectCall } from "_state/api";
import { queryState, updateState, importState } from "./creator";

const base = "/class";

const queryClasses = makeApiCall("post", `${base}/query`, queryState);

const selectClasses = makeSelectCall(`${base}/query`);

const setClasses = queryState.actions.success;

const updateClass = makeApiCall("post", `${base}/update`, updateState, true);

const importClasses = makeApiCall(
  "upload",
  `${base}/import`,
  importState,
  true
);

export { queryClasses, selectClasses, setClasses, updateClass, importClasses };
