import privateRoutes from "./privateRoutes";

const getAttributes = (
  noCompanies,
  needsSubscription,
  subscriptionExpired
) => ({
  disabled:
    (noCompanies && needsSubscription) ||
    (subscriptionExpired && needsSubscription)
});

const getNav = (
  noCompanies,
  companyId,
  enableCustomFields,
  subscriptionExpired
) => ({
  items: [
    {
      name: privateRoutes.dashboard.name,
      url: privateRoutes.dashboard.path,
      icon: "fas fa-tachometer-alt",
      attributes: getAttributes(
        noCompanies,
        privateRoutes.dashboard.needsSubscription,
        subscriptionExpired
      )
    },
    {
      name: privateRoutes.reports.name,
      url: privateRoutes.reports.getUrl(companyId),
      icon: "fas fa-book-open",
      attributes: getAttributes(
        noCompanies,
        privateRoutes.reports.needsSubscription,
        subscriptionExpired
      )
    },
    {
      name: privateRoutes.tagYourData.name,
      url: privateRoutes.tagYourData.getUrl(companyId),
      icon: "fas fa-pencil-alt",
      attributes: getAttributes(
        noCompanies,
        privateRoutes.tagYourData.needsSubscription,
        subscriptionExpired
      )
    },
    {
      name: "Manage Your Tags",
      icon: "fas fa-tags",
      children: [
        {
          name: privateRoutes.valueStreams.name,
          url: privateRoutes.valueStreams.getUrl(companyId),
          icon: "fas fa-dollar-sign",
          attributes: getAttributes(
            noCompanies,
            privateRoutes.valueStreams.needsSubscription,
            subscriptionExpired
          )
        },
        {
          name: privateRoutes.sectors.name,
          url: privateRoutes.sectors.getUrl(companyId),
          icon: "fas fa-industry",
          attributes: getAttributes(
            noCompanies,
            privateRoutes.sectors.needsSubscription,
            subscriptionExpired
          )
        },
        {
          name: privateRoutes.segments.name,
          url: privateRoutes.segments.getUrl(companyId),
          icon: "fas fa-puzzle-piece",
          attributes: getAttributes(
            noCompanies,
            privateRoutes.segments.needsSubscription,
            subscriptionExpired
          )
        },
        {
          name: privateRoutes.subregions.name,
          url: privateRoutes.subregions.getUrl(companyId),
          icon: "fas fa-map-signs",
          attributes: getAttributes(
            noCompanies,
            privateRoutes.subregions.needsSubscription,
            subscriptionExpired
          )
        },
        ...(enableCustomFields
          ? [
              {
                name: privateRoutes.custom1.name,
                url: privateRoutes.custom1.getUrl(companyId),
                icon: "fas fa-angle-double-up",
                attributes: getAttributes(
                  noCompanies,
                  privateRoutes.custom1.needsSubscription,
                  subscriptionExpired
                )
              },
              {
                name: privateRoutes.custom2.name,
                url: privateRoutes.custom2.getUrl(companyId),
                icon: "fas fa-angle-double-up",
                attributes: getAttributes(
                  noCompanies,
                  privateRoutes.custom2.needsSubscription,
                  subscriptionExpired
                )
              }
            ]
          : [])
      ]
    },
    {
      name: "QBO Sync",
      icon: "fas fa-sync",
      children: [
        {
          name: privateRoutes.classes.name,
          url: privateRoutes.classes.getUrl(companyId),
          icon: "fas fa-cubes",
          attributes: getAttributes(
            noCompanies,
            privateRoutes.classes.needsSubscription,
            subscriptionExpired
          )
        },
        {
          name: privateRoutes.customers.name,
          url: privateRoutes.customers.getUrl(companyId),
          icon: "fas fa-address-book",
          attributes: getAttributes(
            noCompanies,
            privateRoutes.customers.needsSubscription,
            subscriptionExpired
          )
        },
        {
          name: privateRoutes.products.name,
          url: privateRoutes.products.getUrl(companyId),
          icon: "fas fa-box",
          attributes: getAttributes(
            noCompanies,
            privateRoutes.products.needsSubscription,
            subscriptionExpired
          )
        }
      ]
    },
    {
      name: privateRoutes.myCompanies.name,
      url: privateRoutes.myCompanies.path,
      icon: "fas fa-building",
      attributes: getAttributes(
        noCompanies,
        privateRoutes.myCompanies.needsSubscription,
        subscriptionExpired
      )
    },
    {
      name: privateRoutes.help.name,
      url: privateRoutes.help.path,
      icon: "fas fa-question-circle",
      attributes: getAttributes(
        noCompanies,
        privateRoutes.help.needsSubscription,
        subscriptionExpired
      )
    },
    {
      name: privateRoutes.payment.name,
      url: privateRoutes.payment.getUrl(companyId),
      icon: "fas fa-question-circle",
      attributes: getAttributes(
        noCompanies,
        privateRoutes.payment.needsSubscription,
        subscriptionExpired
      )
    }
  ]
});

export default getNav;
