import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const options = [
  { value: true, label: "Active" },
  { value: false, label: "Inactive" }
];

class ActiveSelect extends Component {
  onChange = option => {
    const { onChange } = this.props;
    onChange(option ? option.value : option);
  };

  render() {
    const { value, disabled, isClearable } = this.props;
    let option;

    if (value === true) {
      option = options[0];
    } else if (value === false) {
      option = options[1];
    }

    return (
      <Select
        value={option}
        onChange={this.onChange}
        options={options}
        menuPortalTarget={document.body}
        isDisabled={disabled}
        isSearchable={false}
        isClearable={isClearable}
        backspaceRemovesValue={false}
      />
    );
  }
}

ActiveSelect.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool
};

ActiveSelect.defaultProps = {
  value: null,
  disabled: false,
  isClearable: false
};

export default ActiveSelect;
