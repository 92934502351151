export const createReducer = (initialState, handlers) => (
  state = initialState,
  action
) => {
  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action);
  }

  return state;
};

export const makeActionCreator = (type, ...argNames) => (...args) => {
  const action = { type };
  argNames.forEach((arg, index) => {
    action[argNames[index]] = args[index];
  });
  return action;
};

export const getTypePrefix = (duckName, stateName) =>
  `${duckName}/${stateName}_`;

export const makeApiSelector = (duckName, stateName) => state =>
  state[duckName][stateName];
