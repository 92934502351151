import { combineReducers } from "redux";
import { createApiRequestState } from "_state/boilerplateKillers";

const duckName = "nameValuePair";

export const queryState = createApiRequestState(duckName, "query");

export const createState = createApiRequestState(duckName, "create");

export const updateState = createApiRequestState(duckName, "update");

export const sectorsState = createApiRequestState(duckName, "sectors");
export const segmentsState = createApiRequestState(duckName, "segments");
export const valueStreamsState = createApiRequestState(
  duckName,
  "valueStreams"
);
export const custom1State = createApiRequestState(duckName, "custom1");
export const custom2State = createApiRequestState(duckName, "custom2");

const reducer = combineReducers({
  [queryState.stateName]: queryState.reducer,
  [createState.stateName]: createState.reducer,
  [updateState.stateName]: updateState.reducer,
  [sectorsState.stateName]: sectorsState.reducer,
  [segmentsState.stateName]: segmentsState.reducer,
  [valueStreamsState.stateName]: valueStreamsState.reducer,
  [custom1State.stateName]: custom1State.reducer,
  [custom2State.stateName]: custom2State.reducer
});

export default reducer;
