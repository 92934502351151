import React from "react";
import PropTypes from "prop-types";
import ExportButton from "shared/ExportButton/ExportButton";

const ExportReport = ({ report: { formData, reportType } }) => {
  let url;
  switch (reportType) {
    case "pnl":
      url = "/profit-and-loss/export";
      break;
    case "productSales":
      url = "/product-sales/export";
      break;
    default:
      url = "";
  }

  return <ExportButton exportParams={[url, formData, "report.xlsx"]} />;
};

ExportReport.propTypes = {
  report: PropTypes.objectOf(Object).isRequired
};

export default ExportReport;
