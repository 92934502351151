import React from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardFooter } from "reactstrap";
import { apiRequestType } from "_types";
import ConnectionStatus from "shared/ConnectionStatus/ConnectionStatus";
import QboDisconnectButton from "./QboDisconnectButton";
import QboButtonContainer from "shared/QboButton/QboButtonContainer";
import CardTable from "./CardTable";

const QboLinkCard = ({
  companySettings: {
    data: { id, realmId, authedByEmail, authedDate, qboLink, name }
  },
  refreshPage,
  disconnect
}) => {
  const rows = [
    { name: "Id:", value: id },
    { name: "Company #:", value: realmId },
    { name: "Linked By:", value: authedByEmail },
    { name: "Linked On:", value: authedDate && authedDate.substring(0, 10) },
    {
      name: "QBO Link:",
      value: <ConnectionStatus noPageLink connected={qboLink} companyId={id} />
    }
  ];

  return (
    <Card className="cardInDeck">
      <CardHeader>{name ? name : "QuickBooks Link"}</CardHeader>
      <CardTable rows={rows} />
      <CardFooter>
        {qboLink ? (
          <QboDisconnectButton
            onSubmitComplete={refreshPage}
            disconnect={disconnect}
          />
        ) : (
          <QboButtonContainer
            method="update"
            realmId={realmId}
            companyId={id}
          />
        )}
      </CardFooter>
    </Card>
  );
};

QboLinkCard.propTypes = {
  companySettings: apiRequestType.isRequired,
  refreshPage: PropTypes.func.isRequired,
  disconnect: PropTypes.func.isRequired
};

export default QboLinkCard;
