import React from "react";
import PropTypes from "prop-types";
import { CardDeck } from "reactstrap";
import { apiRequestType } from "_types";
import withSpinner from "shared/withSpinner/withSpinner";
import withError from "shared/withError/withError";
import ErrorAlert from "shared/withError/ErrorAlert";
import QboLinkCard from "./QboLinkCard";
import CompanySettingsCard from "./CompanySettingsCard";

const getErrorMessage = qboConnectError => {
  switch (qboConnectError) {
    case "mismatch":
      return "The company you picked in the QuickBooks dialog does not match this company.";
    case "notAdmin":
      return "You must be an admin to connect to a company.";
    default:
      return "There was an error while trying to connect to QuickBooks, please try again later.";
  }
};

const CompanySettings = ({
  companySettings,
  refreshPage,
  disconnect,
  update,
  qboConnectError
}) => (
  <div>
    <ErrorAlert
      error={qboConnectError && { message: getErrorMessage(qboConnectError) }}
    />
    <CardDeck>
      <QboLinkCard
        companySettings={companySettings}
        refreshPage={refreshPage}
        disconnect={disconnect}
      />
      <CompanySettingsCard
        companySettings={companySettings}
        refreshPage={refreshPage}
        update={update}
      />
    </CardDeck>
  </div>
);

CompanySettings.propTypes = {
  companySettings: apiRequestType.isRequired,
  disconnect: PropTypes.func.isRequired,
  refreshPage: PropTypes.func.isRequired,
  qboConnectError: PropTypes.string,
  update: PropTypes.func.isRequired
};

CompanySettings.defaultProps = {
  qboConnectError: null
};

export default withSpinner(withError(CompanySettings));
