import { makeApiCall, makeSelectCall } from "_state/api";
import {
  queryState,
  createState,
  updateState,
  sectorsState,
  segmentsState,
  valueStreamsState,
  custom1State,
  custom2State
} from "./creator";

const base = "/name-value-pair";

const queryNameValuePairs = makeApiCall("post", `${base}/query`, queryState);

const selectNameValuePairs = makeSelectCall(`${base}/query`);

const setNameValuePairs = queryState.actions.success;

const onSubmitComplete = dataOrParams => {
  return fetchAllNvpOfType(dataOrParams.type, dataOrParams.companyId);
};

const createNameValuePair = makeApiCall(
  "post",
  `${base}/create`,
  createState,
  true,
  onSubmitComplete
);

const updateNameValuePair = makeApiCall(
  "post",
  `${base}/update`,
  updateState,
  true,
  onSubmitComplete
);

const createNvpFetch = (type, state) => ({ companyId }) => {
  const query = makeApiCall("post", `${base}/query`, state);
  return query({
    companyId,
    cursor: null,
    equalityFilters: [{ id: "type", value: type }],
    pageSize: 1000,
    direction: 1
  });
};

const fetchSectors = createNvpFetch("sector", sectorsState);
const fetchSegments = createNvpFetch("segment", segmentsState);
const fetchValueStreams = createNvpFetch("valueStream", valueStreamsState);
const fetchCustom1 = createNvpFetch("custom1", custom1State);
const fetchCustom2 = createNvpFetch("custom2", custom2State);

const fetchAllNvpOfType = (type, companyId) => {
  // eslint-disable-next-line default-case
  switch (type) {
    case "sector":
      return fetchSectors({ companyId });
    case "segment":
      return fetchSegments({ companyId });
    case "valueStream":
      return fetchValueStreams({ companyId });
    case "custom1":
      return fetchCustom1({ companyId });
    case "custom2":
      return fetchCustom2({ companyId });
  }
};

export {
  queryNameValuePairs,
  setNameValuePairs,
  createNameValuePair,
  updateNameValuePair,
  selectNameValuePairs,
  fetchSectors,
  fetchSegments,
  fetchValueStreams,
  fetchCustom1,
  fetchCustom2,
  fetchAllNvpOfType
};
