import React, { Component } from "react";
import PropTypes from "prop-types";
import Firebase, { withFirebase } from "_auth/Firebase";
import Login from "./Login";

class LoginContainer extends Component {
  componentDidMount() {
    // Redirect to Dashboard if a user is logged in
  }

  render() {
    const { firebase } = this.props;
    const signIn = (email, password) =>
      firebase.doSignInWithEmailAndPassword(email, password);
    return <Login signIn={signIn} />;
  }
}

LoginContainer.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired
};

export default withFirebase(LoginContainer);
