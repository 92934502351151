import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import { getMonthNow } from "_utils";
import { startupData, authUser } from "_state/ducks/session/selectors";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import { apiRequestType, authUserType } from "_types";
import { fetchDashboard } from "_state/ducks/plan/operations";
import { dashboard } from "_state/ducks/plan/selectors";
import NoCompany from "./NoCompany";
import Dashboard from "./Dashboard";

const getNoCompany = startupData => startupData.data.currentCompany === null;

class DashboardContainer extends Component {
  componentDidMount() {
    const { startupData, fetchDashboard } = this.props;

    const noCompany = getNoCompany(startupData);

    if (!noCompany) {
      fetchDashboard({
        companyId: startupData.data.currentCompany.id,
        month: getMonthNow()
      });
    }
  }

  forceRefreshDashboard = () => {
    const { startupData, fetchDashboard } = this.props;

    fetchDashboard({
      companyId: startupData.data.currentCompany.id,
      month: getMonthNow(),
      forceRefresh: true
    });
  };

  render() {
    const { startupData, authUser, dashboard } = this.props;
    const noCompany = getNoCompany(startupData);

    if (noCompany) {
      return <NoCompany />;
    }

    return (
      <Dashboard
        email={authUser.email}
        companyId={
          startupData.data.currentCompany
            ? startupData.data.currentCompany.id
            : null
        }
        dashboard={dashboard}
        loading={
          dashboard.loading ||
          (_.isEmpty(dashboard.data.plans) && !dashboard.error)
        }
        error={dashboard.error}
        forceRefreshDashboard={this.forceRefreshDashboard}
      />
    );
  }
}

DashboardContainer.propTypes = {
  startupData: apiRequestType.isRequired,
  authUser: authUserType.isRequired,
  fetchDashboard: PropTypes.func.isRequired,
  dashboard: apiRequestType.isRequired
};

const mapStateToProps = makeMapStateToProps({
  startupData,
  authUser,
  dashboard
});

const mapDispatchToProps = {
  fetchDashboard
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardContainer);
