import { makeApiCall } from "_state/api";
import { queryState, updateState } from "./creator";

const base = "/role-in-company";

const queryRoleInCompany = makeApiCall("post", `${base}/query`, queryState);

const setRoleInCompany = queryState.actions.success;

const updateRoleInCompany = makeApiCall(
  "post",
  `${base}/update`,
  updateState,
  true
);

export { queryRoleInCompany, setRoleInCompany, updateRoleInCompany };
