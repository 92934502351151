import React from "react";
import { selectProducts } from "_state/ducks/product/operations";
import AsyncSelect from "./AsyncSelect";

const ProductSelect = props => (
  <AsyncSelect
    callApiForOptions={selectProducts}
    labelProperty="FullyQualifiedName"
    filterProperty="FullyQualifiedName"
    {...props}
  />
);

export default ProductSelect;
