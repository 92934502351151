import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardText,
  CardFooter
} from "reactstrap";
import MyModal from "shared/MyModal/MyModal";
import InviteCodeForm from "shared/Forms/InviteCodeForm";

const InviteCompanyCard = ({ fetchStartupData, submitCode }) => (
  <Card className="cardInDeck">
    <CardHeader>Invitation</CardHeader>
    <CardBody>
      <CardText>
        {`If you were invited to an existing company and have an invitation code, then
        click the button below. If you need a code, ask a company administrator to send you an invite.`}
      </CardText>
    </CardBody>
    <CardFooter>
      <MyModal
        renderButton={toggle => (
          <React.Fragment>
            <Button color="success" className="float-right" onClick={toggle}>
              Enter Code
            </Button>
          </React.Fragment>
        )}
        renderBody={toggle => (
          <InviteCodeForm
            toggle={toggle}
            title={"Enter an invitation code to join a company."}
            submit={submitCode}
            onSubmitComplete={fetchStartupData}
            buttonText="Join Company"
          />
        )}
      />
    </CardFooter>
  </Card>
);

InviteCompanyCard.propTypes = {
  fetchStartupData: PropTypes.func.isRequired,
  submitCode: PropTypes.func.isRequired
};

export default InviteCompanyCard;
