import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import BlankForm from "shared/Forms/BlankForm";
import MyModal from "shared/MyModal/MyModal";

const QboDisconnectButton = ({ disconnect, onSubmitComplete }) => (
  <React.Fragment>
    <MyModal
      renderButton={toggle => (
        <Button className="float-right" color="danger" onClick={toggle}>
          <i className="fas fa-unlink" /> Disconnect
        </Button>
      )}
      renderBody={toggle => (
        <BlankForm
          toggle={toggle}
          title={`Are you sure you want to disconnect this Quickbooks company?
          You will no longer be able to sync Quickbooks data or run reports.`}
          submit={disconnect}
          onSubmitComplete={onSubmitComplete}
          buttonText="Disconnect"
          buttonColor="danger"
        />
      )}
    />
  </React.Fragment>
);

QboDisconnectButton.propTypes = {
  disconnect: PropTypes.func.isRequired,
  onSubmitComplete: PropTypes.func.isRequired
};

export default QboDisconnectButton;
