import React from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardFooter, Button } from "reactstrap";
import { apiRequestType } from "_types";
import MyModal from "shared/MyModal/MyModal";
import CardTable from "./CardTable";
import CompanySettingsForm from "shared/Forms/CompanySettingsForm";

const CompanySettingsCard = ({
  companySettings: {
    data: { enableCustomFields, custom1Name, custom2Name, numberOfEmployees }
  },
  refreshPage,
  update
}) => {
  const rows = [
    { name: "Enable Custom Fields:", value: enableCustomFields ? "Yes" : "No" },
    { name: "Custom 1 Name:", value: custom1Name },
    { name: "Custom 2 Name:", value: custom2Name },
    { name: "# of Employees", value: numberOfEmployees }
  ];

  return (
    <Card className="cardInDeck">
      <CardHeader>Company Settings</CardHeader>
      <CardTable rows={rows} columnWidth={7} />
      <CardFooter>
        <MyModal
          renderButton={toggle => (
            <Button color="primary" className="float-left" onClick={toggle}>
              <i className="fa fa-pencil-alt" /> Edit
            </Button>
          )}
          renderBody={toggle => (
            <CompanySettingsForm
              toggle={toggle}
              submit={update}
              onSubmitComplete={refreshPage}
              initialFormValues={{
                enableCustomFields,
                custom1Name,
                custom2Name,
                numberOfEmployees
              }}
            />
          )}
        />
      </CardFooter>
    </Card>
  );
};

CompanySettingsCard.propTypes = {
  companySettings: apiRequestType.isRequired,
  refreshPage: PropTypes.func.isRequired,
  update: PropTypes.func.isRequired
};

export default CompanySettingsCard;
