export const groupByOptions = [
  { label: "Class", value: "class" },
  { label: "Value Stream", value: "valueStream" },
  { label: "Sector", value: "sector" },
  { label: "Segment", value: "segment" },
  { label: "Region", value: "region" },
  { label: "Subregion", value: "subregion" },
  { label: "Custom 1", value: "custom1" },
  { label: "Custom 2", value: "custom2" },
  { label: "Customer", value: "customer" },
  { label: "Product", value: "product" }
];

export const groupByValues = groupByOptions.map(item => item.value);
