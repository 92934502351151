import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Input, Card, CardBody, Button, CardHeader } from "reactstrap";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import { apiRequestType, routerTypes } from "_types";
import { startupData } from "_state/ducks/session/selectors";
import { queriedClasses, updateClassObj } from "_state/ducks/class/selectors";
import {
  queryClasses,
  updateClass,
  setClasses,
  importClasses
} from "_state/ducks/class/operations";
import privateRoutes from "_constants/privateRoutes";
import ClassSelect from "shared/Selects/ClassSelect";
import CustomerSelect from "shared/Selects/CustomerSelect";
import ProductSelect from "shared/Selects/ProductSelect";
import SubregionSelect from "shared/Selects/SubregionSelect";
import NameValuePairSelect from "shared/Selects/NameValuePairSelect";
import EditTable from "shared/SmartTable/EditTable";
import ExportClasses from "shared/ExportClasses/ExportClasses";
import ImportForm from "shared/Forms/ImportForm";
import MyModal from "shared/MyModal/MyModal";
import ExportInfoBlock from "./ExportInfoBlock";

const getColumn = (Header, type, companyId) => ({
  Header,
  accessor: type,
  MyCellComponent: NameValuePairSelect,
  myCellPassedProps: {
    companyId: companyId,
    readAccessor: "name",
    type
  },
  MyFilterComponent: NameValuePairSelect,
  myFilterPassedProps: { type },
  sortable: false
});

class TagYourDataContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 1 // Incrementing key remounts the table
    };
  }

  refreshTable = () => {
    this.setState(prevState => ({
      key: prevState.key + 1
    }));
  };

  importFromExcel = file => {
    const { importClasses, match } = this.props;

    return importClasses({
      file,
      companyId: match.params.companyId
    });
  };

  render() {
    const {
      queriedClasses,
      queryClasses,
      updateClassObj,
      updateClass,
      setClasses,
      startupData,
      match
    } = this.props;

    const { key } = this.state;

    const customColumns = startupData.data.currentCompany.enableCustomFields
      ? [
          getColumn(
            privateRoutes.custom1.name,
            "custom1",
            match.params.companyId
          ),
          getColumn(
            privateRoutes.custom2.name,
            "custom2",
            match.params.companyId
          )
        ]
      : [];

    return (
      <React.Fragment>
        <Card>
          <CardHeader>
            Import/Export <ExportInfoBlock />
          </CardHeader>
          <CardBody>
            <ExportClasses companyId={match.params.companyId} />
            <br />
            <MyModal
              renderButton={toggle => (
                <Button onClick={toggle}>
                  <i className="fas fa-file-import" /> Import from Excel
                </Button>
              )}
              renderBody={toggle => (
                <ImportForm
                  toggle={toggle}
                  submit={this.importFromExcel}
                  onSubmitComplete={this.refreshTable}
                  companyId={match.params.companyId}
                />
              )}
            />
          </CardBody>
        </Card>
        <EditTable
          key={key}
          oneFilterAtATime
          isQbo
          defaultSortId="lowercaseName"
          defaultSortDesc
          match={match}
          query={queryClasses}
          queriedEntities={queriedClasses}
          updateObj={updateClassObj}
          update={updateClass}
          set={setClasses}
          columns={[
            {
              Header: "Fully Qualified Name",
              accessor: "FullyQualifiedName",
              MyCellComponent: Input,
              myCellPassedProps: { type: "text" },
              MyFilterComponent: ClassSelect
            },
            {
              Header: "Customer",
              accessor: "customer",
              MyCellComponent: CustomerSelect,
              myCellPassedProps: {
                companyId: match.params.companyId,
                readAccessor: "FullyQualifiedName"
              },
              MyFilterComponent: CustomerSelect,
              sortable: false
            },
            getColumn("Sector", "sector", match.params.companyId),
            getColumn("Segment", "segment", match.params.companyId),
            {
              Header: "Region",
              accessor: "subregion",
              MyCellComponent: SubregionSelect,
              myCellPassedProps: {
                companyId: match.params.companyId,
                readAccessor: "FullyQualifiedName"
              },
              MyFilterComponent: SubregionSelect,
              sortable: false
            },
            getColumn("Value Stream", "valueStream", match.params.companyId),
            {
              Header: "Product",
              accessor: "product",
              MyCellComponent: ProductSelect,
              myCellPassedProps: {
                companyId: match.params.companyId,
                readAccessor: "FullyQualifiedName"
              },
              MyFilterComponent: ProductSelect,
              sortable: false
            },
            ...customColumns
          ]}
        />
      </React.Fragment>
    );
  }
}

TagYourDataContainer.propTypes = {
  queriedClasses: apiRequestType.isRequired,
  queryClasses: PropTypes.func.isRequired,
  updateClassObj: apiRequestType.isRequired,
  updateClass: PropTypes.func.isRequired,
  setClasses: PropTypes.func.isRequired,
  importClasses: PropTypes.func.isRequired,
  startupData: apiRequestType.isRequired,
  match: routerTypes.match.isRequired
};

const mapStateToProps = makeMapStateToProps({
  queriedClasses,
  updateClassObj,
  startupData
});

const mapDispatchToProps = {
  queryClasses,
  updateClass,
  setClasses,
  importClasses
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TagYourDataContainer);
