import PropTypes from "prop-types";

const nameValuePairType = PropTypes.oneOf([
  "sector",
  "valueStream",
  "custom1",
  "custom2",
  "segment"
]);

export default nameValuePairType;
