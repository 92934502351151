import React from "react";
import moment from "moment";
import { Input } from "reactstrap";

const getYears = () => {
  let years = [];

  // Outer loop to create parent
  for (let i = -5; i <= 10; i++) {
    years.push(
      <option key={i} value={moment().year() + i}>
        {moment().year() + i}
      </option>
    );
  }
  return years;
};

// eslint-disable-next-line react/prop-types
const MonthElement = ({ month, onMonthSelect, onYearSelect, disabled }) => (
  <div style={{ display: "flex", justifyContent: "center" }}>
    <div>
      <Input
        type="select"
        onChange={e => onMonthSelect(month, e.target.value)}
        value={month.month()}
        disabled={disabled}
      >
        {moment.months().map((label, value) => (
          <option key={label} value={value}>
            {label}
          </option>
        ))}
      </Input>
    </div>
    <div>
      <Input
        type="select"
        onChange={e => {
          onYearSelect(month, e.target.value);
        }}
        value={month.year()}
        disabled={disabled}
      >
        {getYears()}
      </Input>
    </div>
  </div>
);

export default MonthElement;
