// makes a url from query parameters
function makeUrl(base, params) {
  return `${base}?${Object.keys(params)
    .map(function(key) {
      return [key, params[key]].map(encodeURIComponent).join("=");
    })
    .join("&")}`;
}

export default makeUrl;
