import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import { apiRequestType, routerTypes } from "_types";
import { getMonthNow } from "_utils";
import { queriedPlans } from "_state/ducks/plan/selectors";
import {
  queryPlans,
  createPlan,
  updatePlan
} from "_state/ducks/plan/operations";

import Plans from "./Plans";

class PlansContainer extends Component {
  componentDidMount() {
    this.fetchPlans();
  }

  fetchPlans = (forceRefresh = false) => {
    const { queryPlans, match } = this.props;
    return queryPlans({
      companyId: match.params.companyId,
      month: getMonthNow(),
      forceRefresh
    });
  };

  forceRefreshPlans = () => {
    this.fetchPlans(true);
  };

  addNewPlan = formData => {
    const { createPlan, match } = this.props;
    return createPlan({ companyId: match.params.companyId, ...formData });
  };

  render() {
    const { match, queriedPlans, updatePlan } = this.props;
    return (
      <Plans
        companyId={match.params.companyId}
        addNewPlan={this.addNewPlan}
        fetchPlans={this.fetchPlans}
        queriedPlans={queriedPlans}
        updatePlan={updatePlan}
        loading={queriedPlans.loading}
        error={queriedPlans.error}
        forceRefreshPlans={this.forceRefreshPlans}
      />
    );
  }
}

PlansContainer.propTypes = {
  queriedPlans: apiRequestType.isRequired,
  queryPlans: PropTypes.func.isRequired,
  createPlan: PropTypes.func.isRequired,
  updatePlan: PropTypes.func.isRequired,
  match: routerTypes.match.isRequired
};

const mapStateToProps = makeMapStateToProps({
  queriedPlans
});

const mapDispatchToProps = {
  queryPlans,
  createPlan,
  updatePlan
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlansContainer);
