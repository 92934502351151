import React from "react";
import PropTypes from "prop-types";
import { CardHeader, Button } from "reactstrap";
import ExportReport from "./ExportReport";
// import PrintButton from "shared/PrintButton/PrintButton";

const ReportToolbar = ({ report, toggleAll, expanded }) => (
  <CardHeader className="bg-white d-print-none">
    <Button color="link" onClick={toggleAll}>
      {expanded ? "Collapse" : "Expand"}
    </Button>
    <div className="float-right text-right">
      {/* <PrintButton />  */}
      <ExportReport report={report} />
    </div>
  </CardHeader>
);

ReportToolbar.propTypes = {
  report: PropTypes.oneOfType([PropTypes.objectOf(Object), PropTypes.array])
    .isRequired,
  toggleAll: PropTypes.func.isRequired,
  expanded: PropTypes.bool.isRequired
};

export default ReportToolbar;
