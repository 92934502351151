import React from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Button, Card, CardBody } from "reactstrap";

const ForceRefreshButton = ({ calculatedAt, doRefresh }) => (
  <Card>
    <CardBody>
      <div>
        Last updated: {moment(calculatedAt).format("ddd, MMM Do YYYY, h:mmA")}
      </div>
      <div>
        <Button onClick={() => doRefresh()}>Refresh Now</Button>
      </div>
    </CardBody>
  </Card>
);

ForceRefreshButton.propTypes = {
  calculatedAt: PropTypes.string.isRequired,
  doRefresh: PropTypes.func.isRequired
};

export default ForceRefreshButton;
