import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import { apiRequestType, routerTypes } from "_types";
import { isExpired } from "_utils";
import privateRoutes from "_constants/privateRoutes";
import { queriedInvites } from "_state/ducks/invite/selectors";
import {
  queryInvites,
  resendInvite,
  deleteInvite,
  createInvite
} from "_state/ducks/invite/operations";
import EmailForm from "shared/Forms/EmailForm";
import TableHeader from "shared/SmartTable/TableHeader";
import InviteActions from "./InviteActions";
import SmartTable from "shared/SmartTable";

class PendingInvitesContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 1 // Incrementing key remounts the table
    };
  }

  refreshTable = () => {
    this.setState(prevState => ({
      key: prevState.key + 1
    }));
  };

  create = entity => {
    const { createInvite, match } = this.props;

    return createInvite({
      ...entity,
      companyId: match.params.companyId
    });
  };

  resend = id => {
    const { resendInvite, match } = this.props;

    return resendInvite({
      id,
      companyId: match.params.companyId
    });
  };

  delete = id => {
    const { deleteInvite, match } = this.props;

    return deleteInvite({
      id,
      companyId: match.params.companyId
    });
  };

  renderActions = props => (
    <InviteActions
      resend={() => this.resend(props.original.id)}
      _delete={() => this.delete(props.original.id)}
      onSubmitComplete={this.refreshTable}
    />
  );

  renderDate = props => {
    let className = "";
    if (props.column.id === "expiresAt") {
      if (isExpired(props.original.expiresAt)) {
        className = "text-danger";
      }
    }
    const dateString = new Date(props.value).toString();
    return (
      <span className={className} title={dateString}>
        {dateString}
      </span>
    );
  };

  renderCode = props => <span title={props.value}>{props.value}</span>;

  render() {
    const { queriedInvites, queryInvites, match } = this.props;
    const { key } = this.state;

    return (
      <SmartTable
        key={key}
        renderHeader={() => (
          <TableHeader
            pluralName={"Pending Invites"}
            singularName={privateRoutes.manageUsers.singularName}
            create={this.create}
            onSubmitComplete={this.refreshTable}
            FormComponent={EmailForm}
            companyId={match.params.companyId}
          />
        )}
        error={queriedInvites.error}
        queriedEntities={queriedInvites}
        queryEntities={queryInvites}
        companyId={match.params.companyId}
        columns={[
          {
            Header: "Email",
            accessor: "email",
            filterable: false,
            sortable: false
          },
          {
            Header: "Code",
            accessor: "code",
            filterable: false,
            sortable: false,
            Cell: this.renderCode
          },
          {
            Header: "Date Sent",
            accessor: "dateSent",
            filterable: false,
            sortable: false,
            Cell: this.renderDate
          },
          {
            Header: "Expires At",
            accessor: "expiresAt",
            filterable: false,
            sortable: false,
            Cell: this.renderDate
          },
          {
            Header: "Actions",
            accessor: "id",
            maxWidth: 120,
            Cell: this.renderActions,
            filterable: false,
            sortable: false
          }
        ]}
      />
    );
  }
}

PendingInvitesContainer.propTypes = {
  queriedInvites: apiRequestType.isRequired,
  queryInvites: PropTypes.func.isRequired,
  resendInvite: PropTypes.func.isRequired,
  deleteInvite: PropTypes.func.isRequired,
  createInvite: PropTypes.func.isRequired,
  match: routerTypes.match.isRequired
};

const mapStateToProps = makeMapStateToProps({
  queriedInvites
});

const mapDispatchToProps = {
  queryInvites,
  resendInvite,
  deleteInvite,
  createInvite
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PendingInvitesContainer);
