import api, { makeApiCall } from "_state/api";
import {
  myCompaniesState,
  switchCompanyState,
  syncCompanyState,
  companySettingsState,
  disconnectFromQboState,
  updateCompanySettingsState
} from "./creator";
import { startupDataState } from "_state/ducks/session/creator";

const fetchMyCompanies = makeApiCall(
  "get",
  "/company/my-companies",
  myCompaniesState
);

const syncCompany = makeApiCall("post", "/company/sync", syncCompanyState);

const switchCompany = companyId => dispatch => {
  const path = "/company/switch-company";
  dispatch(switchCompanyState.actions.begin());
  console.log(`Calling api "${path}"`);

  return api
    .post(path, {
      companyId
    })
    .then(resp => {
      console.log(`Called api "${path}" and received`, resp);
      dispatch(syncCompany({ companyId }));
      dispatch(switchCompanyState.actions.success(resp.data));
      dispatch(startupDataState.actions.success(resp.data));
      return resp;
    })
    .catch(err => {
      console.log(err.response);
      err.response.message = `Api call for path "${path}" failed with ${
        err.response.status
      } ${err.response.statusText}`;
      dispatch(switchCompanyState.actions.failure(err.response));
    });
};

const fetchCompanySettings = makeApiCall(
  "post",
  "/company/fetch-settings",
  companySettingsState
);

const disconnectFromQbo = makeApiCall(
  "post",
  "/company/disconnect",
  disconnectFromQboState,
  true
);

const updateCompanySettings = makeApiCall(
  "post",
  "/company/update-settings",
  updateCompanySettingsState,
  true
);

export {
  fetchMyCompanies,
  switchCompany,
  syncCompany,
  fetchCompanySettings,
  updateCompanySettings,
  disconnectFromQbo
};
