import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import BlankForm from "shared/Forms/BlankForm";
import MyModal from "shared/MyModal/MyModal";

const InviteActions = ({ resend, _delete, onSubmitComplete }) => (
  <React.Fragment>
    <MyModal
      renderButton={toggle => (
        <React.Fragment>
          <Button color="success" onClick={toggle}>
            <i className="fas fa-paper-plane" />
          </Button>{" "}
        </React.Fragment>
      )}
      renderBody={toggle => (
        <BlankForm
          toggle={toggle}
          title={"Are you sure you want to resend the invitation?"}
          submit={resend}
          onSubmitComplete={onSubmitComplete}
          buttonText="Resend"
        />
      )}
    />
    <MyModal
      renderButton={toggle => (
        <Button color="secondary" onClick={toggle}>
          <i className="fas fa-trash" />
        </Button>
      )}
      renderBody={toggle => (
        <BlankForm
          toggle={toggle}
          title={"Are you sure you want to delete the invitation?"}
          submit={_delete}
          onSubmitComplete={onSubmitComplete}
          buttonText="Delete"
          buttonColor="danger"
        />
      )}
    />
  </React.Fragment>
);

InviteActions.propTypes = {
  resend: PropTypes.func.isRequired,
  _delete: PropTypes.func.isRequired,
  onSubmitComplete: PropTypes.func.isRequired
};

export default InviteActions;
