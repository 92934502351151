import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Input } from "reactstrap";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import { apiRequestType, routerTypes } from "_types";
import {
  queriedRoleInCompany,
  updateRoleInCompanyObj
} from "_state/ducks/roleInCompany/selectors";
import {
  queryRoleInCompany,
  updateRoleInCompany,
  setRoleInCompany
} from "_state/ducks/roleInCompany/operations";
import RoleSelectContainer from "shared/Selects/RoleSelectContainer";
import RoleDescriptions from "./RoleDescriptions";
import EditTable from "shared/SmartTable/EditTable";

const ManageUsersContainer = ({
  match,
  queryRoleInCompany,
  queriedRoleInCompany,
  updateRoleInCompanyObj,
  updateRoleInCompany,
  setRoleInCompany
}) => (
  <EditTable
    match={match}
    query={queryRoleInCompany}
    queriedEntities={queriedRoleInCompany}
    updateObj={updateRoleInCompanyObj}
    update={updateRoleInCompany}
    set={setRoleInCompany}
    create={null}
    FormComponent={null}
    columns={[
      {
        Header: "Email",
        accessor: "email",
        MyCellComponent: Input,
        myCellPassedProps: {
          disabled: true
        },
        filterable: false,
        sortable: false
      },
      {
        Header: () => (
          <div>
            Role <RoleDescriptions />
          </div>
        ),
        accessor: "role",
        MyCellComponent: RoleSelectContainer,
        myCellPassedProps: {
          readAccessor: "name"
        },
        filterable: false,
        sortable: false
      }
    ]}
  />
);

ManageUsersContainer.propTypes = {
  queriedRoleInCompany: apiRequestType.isRequired,
  queryRoleInCompany: PropTypes.func.isRequired,
  updateRoleInCompanyObj: apiRequestType.isRequired,
  updateRoleInCompany: PropTypes.func.isRequired,
  setRoleInCompany: PropTypes.func.isRequired,
  match: routerTypes.match.isRequired
};

const mapStateToProps = makeMapStateToProps({
  queriedRoleInCompany,
  updateRoleInCompanyObj
});

const mapDispatchToProps = {
  queryRoleInCompany,
  updateRoleInCompany,
  setRoleInCompany
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManageUsersContainer);
