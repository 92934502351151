import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";

const ToggleActive = ({ toggle, showActive }) => (
  <Button className="float-right" onClick={toggle}>
    {showActive ? "Show Inactive" : "Show Active"}
  </Button>
);

ToggleActive.propTypes = {
  showActive: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

export default ToggleActive;
