import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import { apiRequestType, routerTypes } from "_types";
import { fetchStartupData } from "_state/ducks/session/operations";
import {
  switchCompanyObj,
  companySettings
} from "_state/ducks/company/selectors";
import {
  fetchCompanySettings,
  disconnectFromQbo,
  updateCompanySettings
} from "_state/ducks/company/operations";
import CompanySettings from "./CompanySettings";

const getQboConnectError = location => {
  if (location) {
    if (location.search) {
      const params = new URLSearchParams(location.search);
      return params.get("qboConnectError");
    }
  }
  return null;
};

export class CompanySettingsContainer extends Component {
  componentDidMount() {
    const { fetchCompanySettings, match } = this.props;
    fetchCompanySettings({ companyId: match.params.companyId });
  }

  refreshPage = () => {
    const { fetchStartupData } = this.props;
    // fetchStartupData in order to reload the whole app
    // it will fetch the new company settings and use it to initialize the ui
    fetchStartupData();
  };

  disconnect = () => {
    const { disconnectFromQbo, match } = this.props;
    return disconnectFromQbo({ companyId: match.params.companyId });
  };

  update = formData => {
    const { updateCompanySettings, match } = this.props;

    return updateCompanySettings({
      ...formData,
      companyId: match.params.companyId
    });
  };

  render() {
    const { companySettings, switchCompanyObj, location } = this.props;

    return (
      <CompanySettings
        loading={
          companySettings.loading ||
          switchCompanyObj.loading ||
          _.isEmpty(companySettings.data)
        }
        error={companySettings.error || switchCompanyObj.error}
        companySettings={companySettings}
        refreshPage={this.refreshPage}
        disconnect={this.disconnect}
        qboConnectError={getQboConnectError(location)}
        update={this.update}
      />
    );
  }
}

CompanySettingsContainer.propTypes = {
  companySettings: apiRequestType.isRequired,
  fetchCompanySettings: PropTypes.func.isRequired,
  updateCompanySettings: PropTypes.func.isRequired,
  switchCompanyObj: apiRequestType.isRequired,
  disconnectFromQbo: PropTypes.func.isRequired,
  fetchStartupData: PropTypes.func.isRequired,
  location: routerTypes.location.isRequired,
  match: routerTypes.match.isRequired
};

const mapStateToProps = makeMapStateToProps({
  companySettings,
  switchCompanyObj
});

const mapDispatchToProps = {
  fetchCompanySettings,
  disconnectFromQbo,
  updateCompanySettings,
  fetchStartupData
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CompanySettingsContainer);
