import React from "react";
import { connect } from "react-redux";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import { apiRequestType } from "_types";
import { startupData } from "_state/ducks/session/selectors";
import MySelect from "./MySelect";

const RoleSelectContainer = ({ startupData, ...rest }) => (
  <MySelect
    options={startupData.data.roles}
    error={startupData.error}
    isSearchable={false}
    isClearable={false}
    {...rest}
  />
);

RoleSelectContainer.propTypes = {
  startupData: apiRequestType.isRequired
};

const mapStateToProps = makeMapStateToProps({
  startupData
});

export default connect(
  mapStateToProps,
  null
)(RoleSelectContainer);
