import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Input, Button } from "reactstrap";
import privateRoutes from "_constants/privateRoutes";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import { apiRequestType, routerTypes } from "_types";
import { startupData } from "_state/ducks/session/selectors";
import {
  queriedSubregions,
  updateSubregionObj
} from "_state/ducks/subregion/selectors";
import {
  querySubregions,
  createSubregion,
  updateSubregion,
  setSubregions
} from "_state/ducks/subregion/operations";
import RegionSelect from "shared/Selects/RegionSelect";
import SubregionForm from "shared/Forms/SubregionForm";
import SubregionSelect from "shared/Selects/SubregionSelect";
import EditTable from "shared/SmartTable/EditTable";

const SubregionsContainer = ({
  match,
  querySubregions,
  queriedSubregions,
  updateSubregionObj,
  updateSubregion,
  setSubregions,
  createSubregion,
  startupData
}) => (
  <EditTable
    readOnly={
      startupData.data.currentCompany.myRole !== "level1" &&
      startupData.data.currentCompany.myRole !== "level2"
    }
    defaultSortId="lowercaseName"
    match={match}
    query={querySubregions}
    queriedEntities={queriedSubregions}
    updateObj={updateSubregionObj}
    update={updateSubregion}
    set={setSubregions}
    create={createSubregion}
    FormComponent={SubregionForm}
    columns={[
      {
        Header: "Region",
        accessor: "region",
        MyCellComponent: RegionSelect,
        myCellPassedProps: {
          companyId: match.params.companyId,
          readAccessor: "name",
          isClearable: false
        },
        MyFilterComponent: RegionSelect,
        sortable: false
      },
      {
        Header: "Subregion",
        accessor: "name",
        MyCellComponent: Input,
        myCellPassedProps: { type: "text" },
        MyFilterComponent: SubregionSelect
      }
    ]}
    renderFooter={() => (
      <Button
        tag={Link}
        to={privateRoutes.regions.getUrl(match.params.companyId)}
      >
        Manage Regions
      </Button>
    )}
  />
);

SubregionsContainer.propTypes = {
  queriedSubregions: apiRequestType.isRequired,
  querySubregions: PropTypes.func.isRequired,
  createSubregion: PropTypes.func.isRequired,
  updateSubregionObj: apiRequestType.isRequired,
  updateSubregion: PropTypes.func.isRequired,
  setSubregions: PropTypes.func.isRequired,
  startupData: apiRequestType.isRequired,
  match: routerTypes.match.isRequired
};

const mapStateToProps = makeMapStateToProps({
  queriedSubregions,
  updateSubregionObj,
  startupData
});

const mapDispatchToProps = {
  querySubregions,
  createSubregion,
  updateSubregion,
  setSubregions
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubregionsContainer);
