import React, { Component } from "react";
import PropTypes from "prop-types";
import Firebase, { withFirebase } from "_auth/Firebase";
import Register from "./Register";

class RegisterContainer extends Component {
  componentDidMount() {
    // Redirect to Dashboard if a user is logged in
  }

  render() {
    const { firebase } = this.props;
    const createUser = (email, password) =>
      firebase.doCreateUserWithEmailAndPassword(email, password);
    return <Register createUser={createUser} />;
  }
}

RegisterContainer.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired
};

export default withFirebase(RegisterContainer);
