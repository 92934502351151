import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import Firebase, { withFirebase } from "_auth/Firebase";

const withInactivityLogout = InputComponent => {
  class WithInactivityLogout extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        timer: null
      };
    }

    componentDidMount() {
      this.startTimer();
    }

    componentWillUnmount() {
      const { timer } = this.state;

      clearInterval(timer);
    }

    startTimer = () => {
      const { firebase } = this.props;

      this.setState(() => ({
        timer: setInterval(() => {
          firebase.doSignOut();
        }, 60 * 60 * 1000)
      }));
    };

    resetInterval = () => {
      const { timer } = this.state;

      if (timer) {
        clearInterval(timer);
        this.startTimer();
      }
    };

    // eslint-disable-next-line react/sort-comp
    throttleResetInterval = _.throttle(this.resetInterval, 5000);

    render() {
      return (
        <div
          onMouseMove={this.throttleResetInterval}
          onClick={this.throttleResetInterval}
          onKeyUp={this.throttleResetInterval}
        >
          <InputComponent {...this.props} />
        </div>
      );
    }
  }

  WithInactivityLogout.propTypes = {
    firebase: PropTypes.instanceOf(Firebase).isRequired
  };

  return withFirebase(WithInactivityLogout);
};

export default withInactivityLogout;
