import React from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
import ValidationMessage from "./ValidationMessage";
import isInvalid from "./isInvalid";

const InputField = ({
  field,
  form: { touched, errors, isSubmitting },
  autoComplete,
  type,
  checkboxLabel
}) => {
  const invalid = isInvalid(touched, errors, field);
  return (
    <React.Fragment>
      <Input
        {...field}
        invalid={invalid}
        disabled={isSubmitting}
        autoComplete={autoComplete}
        checked={type === "checkbox" ? field.value : undefined}
        type={type}
      />
      {checkboxLabel}
      <ValidationMessage name={field.name} invalid={invalid} />
    </React.Fragment>
  );
};

InputField.propTypes = {
  field: PropTypes.objectOf(Object).isRequired,
  form: PropTypes.objectOf(Object).isRequired,
  autoComplete: PropTypes.oneOf(["on", "off"]),
  type: PropTypes.oneOf(["number", "text", "checkbox"]),
  checkboxLabel: PropTypes.string
};

InputField.defaultProps = {
  autoComplete: "on",
  type: "text",
  checkboxLabel: ""
};

export default InputField;
