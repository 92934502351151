import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import privateRoutes from "_constants/privateRoutes";

const Connected = () => (
  <span>
    Connected
    <span className="float-right">
      <i className="fas fa-check-circle fa-lg text-success" />
    </span>
  </span>
);

const NotConnected = () => (
  <span className="text-danger">
    Not Connected
    <span className="float-right">
      <i className="fas fa-exclamation-circle fa-lg text-danger" />
    </span>
  </span>
);

const ConnectionStatus = ({ connected, noPageLink, companyId }) => {
  if (connected) {
    return <Connected />;
  }
  if (noPageLink) {
    return <NotConnected />;
  }
  return (
    <Link to={privateRoutes.companySettings.getUrl(companyId)}>
      <NotConnected />
    </Link>
  );
};

ConnectionStatus.propTypes = {
  connected: PropTypes.bool.isRequired,
  noPageLink: PropTypes.bool,
  companyId: PropTypes.string.isRequired
};

ConnectionStatus.defaultProps = {
  noPageLink: false
};

export default ConnectionStatus;
