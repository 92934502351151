import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Alert, Card, CardBody, Container, Row, Col } from "reactstrap";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import Firebase, { withFirebase } from "_auth/Firebase";
import { authUserType } from "_types";
import { authUser } from "_state/ducks/session/selectors";

const ERROR_CODE_TOO_MANY_REQUESTS = "auth/too-many-requests";

const ERROR_MSG_TOO_MANY_REQUESTS = `Too many requests have been made to send confirmation emails.
  If you still need a confirmation email, please try again later.`;

const withEmailVerification = InputComponent => {
  class WithEmailVerification extends Component {
    constructor(props) {
      super(props);

      this.state = { isSent: false, error: null };
    }

    signOut = e => {
      e.preventDefault();
      const { firebase } = this.props;
      firebase.doSignOut();
    };

    onSendEmailVerification = e => {
      e.preventDefault();
      const { firebase } = this.props;
      firebase
        .doSendEmailVerification()
        .then(() => this.setState({ isSent: true }))
        .catch(error => {
          if (error.code === ERROR_CODE_TOO_MANY_REQUESTS) {
            error.message = ERROR_MSG_TOO_MANY_REQUESTS;
          }

          this.setState({ error });
        });
    };

    render() {
      const { authUser, ...rest } = this.props;
      const { isSent, error } = this.state;

      if (authUser.emailVerified) {
        return <InputComponent {...rest} />;
      }

      return (
        <div className="app flex-row align-items-center">
          <Container>
            <Row className="justify-content-center">
              <Col sm="8" md="6" lg="5" xl="4">
                <Card>
                  <CardBody className="p-4">
                    <Alert color="success">
                      <h4 className="alert-heading">Thanks for signing up!</h4>
                      <p>
                        An email confirmation has been sent to{" "}
                        <span className="alert-link">{authUser.email}</span>
                      </p>
                      <hr />
                      <p>
                        Please check you inbox (Spam folder included) for the
                        confirmation email.
                      </p>
                      <hr />
                      {isSent ? (
                        <p>Email confirmation sent!</p>
                      ) : (
                        <div>
                          <p>
                            If you do not see the email, you can always{" "}
                            {/* eslint-disable-next-line */}
                            <a
                              href="#"
                              className="alert-link"
                              onClick={this.onSendEmailVerification}
                              disabled={isSent}
                            >
                              resend the confirmation
                            </a>
                            .
                          </p>
                          <hr />
                          <p>
                            Return to the {/* eslint-disable-next-line */}
                            <a
                              href="#"
                              className="alert-link"
                              onClick={this.signOut}
                            >
                              login screen
                            </a>
                            .
                          </p>
                        </div>
                      )}
                    </Alert>
                    {error && <Alert color="danger">{error.message}</Alert>}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      );
    }
  }

  WithEmailVerification.propTypes = {
    authUser: authUserType,
    firebase: PropTypes.instanceOf(Firebase).isRequired
  };

  WithEmailVerification.defaultProps = {
    authUser: null
  };

  const mapStateToProps = makeMapStateToProps({
    authUser
  });

  return withFirebase(connect(mapStateToProps)(WithEmailVerification));
};

export default withEmailVerification;
