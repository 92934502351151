// Separate file so when we mapStateToProps we can use shorthand object notation
import {
  myCompaniesState,
  switchCompanyState,
  syncCompanyState,
  companySettingsState,
  disconnectFromQboState
} from "./creator";

export const myCompanies = myCompaniesState.selector;
export const switchCompanyObj = switchCompanyState.selector;
export const syncCompanyObj = syncCompanyState.selector;
export const companySettings = companySettingsState.selector;
export const disconnectFromQboObj = disconnectFromQboState.selector;
