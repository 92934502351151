import React from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import InfoPopover from "shared/InfoPopover/InfoPopover";

const PlanHelpPopover = () => (
  <InfoPopover>
    <ListGroup flush>
      <h5>Calculations:</h5>
      <ListGroupItem>Delta $ = Actual $ - Plan $</ListGroupItem>
      <ListGroupItem>Delta % = Actual % - Plan %</ListGroupItem>
      <ListGroupItem>
        Gross Profit = Revenue - Cogs - Direct Labour
      </ListGroupItem>
      <ListGroupItem>
        LER = Gross Profit / (Direct Labour + Indirect Labour)
      </ListGroupItem>
    </ListGroup>
  </InfoPopover>
);

export default PlanHelpPopover;
