import React from "react";
import PropTypes from "prop-types";
import {
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button
} from "reactstrap";
import ValidationMessage from "./ValidationMessage";
import isInvalid from "./isInvalid";

class IconField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPassword: false
    };
  }

  toggleShowPassword = () => {
    this.setState(state => ({ showPassword: !state.showPassword }));
  };

  getPropsAndIcon = (type, showPassword) => {
    if (type === "email") {
      return {
        type: "email",
        autoComplete: "email",
        placeholder: "Email",
        className: "rounded-right",
        icon: "fas fa-envelope"
      };
    }
    if (type === "password") {
      return {
        type: showPassword ? "text" : "password",
        autoComplete: "current-password",
        placeholder: "Password",
        className: "border-right-0 inputBorder",
        icon: "fas fa-lock"
      };
    }
  };

  render() {
    const {
      field,
      form: { touched, errors, isSubmitting },
      type
    } = this.props;

    const { showPassword } = this.state;

    const invalid = isInvalid(touched, errors, field);
    const btnClass = `border-1 border-left-0 rounded-right inputBorder${
      invalid === true ? " is-invalid " : ""
    }`;
    const conditionalProps = this.getPropsAndIcon(type, showPassword);

    return (
      <InputGroup className="mb-2">
        <InputGroupAddon addonType="prepend">
          <InputGroupText>
            <i className={conditionalProps.icon} />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          {...field}
          {...conditionalProps}
          invalid={invalid}
          disabled={isSubmitting}
        />
        {type === "password" && (
          <InputGroupAddon addonType="append">
            <Button
              type="button"
              color="white"
              className={btnClass}
              onClick={this.toggleShowPassword}
              disabled={isSubmitting}
            >
              <i className="fas fa-eye" />
            </Button>
          </InputGroupAddon>
        )}
        <ValidationMessage name={field.name} invalid={invalid} />
      </InputGroup>
    );
  }
}

IconField.propTypes = {
  type: PropTypes.oneOf(["email", "password"]).isRequired,
  field: PropTypes.objectOf(Object).isRequired,
  form: PropTypes.objectOf(Object).isRequired
};

export default IconField;
