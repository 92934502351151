import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import MonthElement from "./MonthElement";

const stringToMoment = input => moment.utc(input);

const momentToString = input => input.format();

class MonthPicker extends React.Component {
  onMonthSelect = (month, selection) => {
    const { onChange } = this.props;
    const newMonth = month;
    newMonth.month(selection);

    onChange(momentToString(newMonth));
  };

  onYearSelect = (month, selection) => {
    const { onChange } = this.props;
    const newMonth = month;
    newMonth.year(selection);

    onChange(momentToString(newMonth));
  };

  render() {
    const { invalid, disabled, value } = this.props;

    return (
      <div className={invalid ? "is-invalid" : undefined}>
        <MonthElement
          month={stringToMoment(value)}
          onMonthSelect={this.onMonthSelect}
          onYearSelect={this.onYearSelect}
          disabled={disabled}
        />
      </div>
    );
  }
}

MonthPicker.propTypes = {
  invalid: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

MonthPicker.defaultProps = {
  invalid: false,
  disabled: false
};

export default MonthPicker;
