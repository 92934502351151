import app from "firebase/app";
import "firebase/auth";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    // Helper
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    // Firebase APIs
    this.auth = app.auth();

    // Set persistence
    this.auth.setPersistence(app.auth.Auth.Persistence.SESSION);
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password).then(() => {
      return this.doSendEmailVerification();
    });

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: process.env.REACT_APP_CONFIRMATION_EMAIL_REDIRECT
    });

  doSendPasswordResetEmail = (email, ActionCodeSettings = null) =>
    this.auth.sendPasswordResetEmail(email, ActionCodeSettings);

  addAuthListener = onAuthStateChanged =>
    this.auth.onAuthStateChanged(authUser => {
      // Set token persistence here?
      onAuthStateChanged(authUser);
    });
}

export default Firebase;
