import { combineReducers } from "redux";
import { createApiRequestState } from "_state/boilerplateKillers";

const duckName = "invite";

export const queryState = createApiRequestState(duckName, "query");

export const createState = createApiRequestState(duckName, "create");

export const resendState = createApiRequestState(duckName, "resend");

export const deleteState = createApiRequestState(duckName, "delete");

export const submitCodeState = createApiRequestState(duckName, "submitCode");

const reducer = combineReducers({
  [queryState.stateName]: queryState.reducer,
  [createState.stateName]: createState.reducer,
  [resendState.stateName]: resendState.reducer,
  [deleteState.stateName]: deleteState.reducer,
  [submitCodeState.stateName]: submitCodeState.reducer
});

export default reducer;
