import React from "react";
import PropTypes from "prop-types";

const withEditModeToggle = (Reading, Editing) => {
  const WrappedComponent = ({ editMode, ...rest }) => {
    if (editMode) {
      return <Editing {...rest} />;
    }
    return <Reading {...rest} />;
  };

  WrappedComponent.propTypes = {
    editMode: PropTypes.bool
  };

  WrappedComponent.defaultProps = {
    editMode: false
  };

  return WrappedComponent;
};

export default withEditModeToggle;
