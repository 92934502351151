import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import { apiRequestType, routerTypes } from "_types";
import { queriedCustomers } from "_state/ducks/customer/selectors";
import { queryCustomers } from "_state/ducks/customer/operations";
import { getActiveColumn } from "shared/Active";
import CustomerSelect from "shared/Selects/CustomerSelect";
import SmartTable from "shared/SmartTable";

class CustomersContainer extends Component {
  renderNameFilter = ({ filter, onChange }) => {
    const { match } = this.props;
    return (
      <CustomerSelect
        companyId={match.params.companyId}
        onChange={option => {
          onChange(option ? option.FullyQualifiedName : "");
        }}
        isClearable
      />
    );
  };

  render() {
    const { queriedCustomers, queryCustomers, match } = this.props;

    return (
      <SmartTable
        error={queriedCustomers.error}
        queriedEntities={queriedCustomers}
        queryEntities={queryCustomers}
        companyId={match.params.companyId}
        columns={[
          {
            Header: "Fully Qualified Name",
            accessor: "FullyQualifiedName",
            Filter: this.renderNameFilter
          },
          getActiveColumn({ isQbo: true })
        ]}
        defaultSorted={[
          {
            id: "lowercaseName",
            desc: false
          }
        ]}
      />
    );
  }
}

CustomersContainer.propTypes = {
  queriedCustomers: apiRequestType.isRequired,
  queryCustomers: PropTypes.func.isRequired,
  match: routerTypes.match.isRequired
};

const mapStateToProps = makeMapStateToProps({
  queriedCustomers
});

const mapDispatchToProps = {
  queryCustomers
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomersContainer);
