import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Card,
  CardDeck,
  CardHeader,
  CardBody,
  CardText,
  CardFooter,
  Button
} from "reactstrap";
import { apiRequestType, routerTypes } from "_types";
import withSpinner from "shared/withSpinner/withSpinner";
import withError from "shared/withError/withError";
import CompanyCard from "./CompanyCard";
import InviteCompanyCard from "./InviteCompanyCard";

import privateRoutes from "_constants/privateRoutes";

const NewCompanyCard = () => (
  <Card className="cardInDeck">
    <CardHeader>New Company</CardHeader>
    <CardBody>
      <CardText>
        Click the button below to create a new company. Once you do this, you
        can connect it to Quickbooks Online and invite users.
      </CardText>
    </CardBody>
    <CardFooter>
      <Link to={privateRoutes.addCompany.path} className="float-right">
        <Button color="success" data-testid="addCompanyButton">
          Create
        </Button>
      </Link>
    </CardFooter>
  </Card>
);

const MyCompanies = ({
  myCompanies,
  currentCompanyId,
  switchCompany,
  submitCode,
  fetchStartupData,
  history
}) => (
  <CardDeck>
    {myCompanies.data.map(company => (
      <CompanyCard
        key={company.id}
        company={company}
        currentCompanyId={currentCompanyId}
        switchCompany={switchCompany}
        history={history}
      />
    ))}
    <InviteCompanyCard
      fetchStartupData={fetchStartupData}
      submitCode={submitCode}
    />
    <NewCompanyCard />
  </CardDeck>
);

MyCompanies.propTypes = {
  myCompanies: apiRequestType.isRequired,
  currentCompanyId: PropTypes.string,
  switchCompany: PropTypes.func.isRequired,
  fetchStartupData: PropTypes.func.isRequired,
  submitCode: PropTypes.func.isRequired,
  history: routerTypes.history.isRequired
};

MyCompanies.defaultProps = {
  currentCompanyId: null
};

export default withSpinner(withError(MyCompanies));
