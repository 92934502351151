import React from "react";
import PropTypes from "prop-types";
import ValidationMessage from "../ValidationMessage";
import isInvalid from "../isInvalid";
import MonthPicker from "./MonthPicker";

const MonthField = ({
  field,
  form: { touched, errors, isSubmitting, setFieldValue }
}) => {
  const invalid = isInvalid(touched, errors, field);
  return (
    <React.Fragment>
      <MonthPicker
        value={field.value}
        onChange={month => setFieldValue(field.name, month)}
        disabled={isSubmitting}
        invalid={invalid}
      />
      <ValidationMessage name={field.name} invalid={invalid} />
    </React.Fragment>
  );
};

MonthField.propTypes = {
  field: PropTypes.objectOf(Object).isRequired,
  form: PropTypes.objectOf(Object).isRequired
};

export default MonthField;
