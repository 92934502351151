import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Row } from "reactstrap";
import { Formik, Field, Form } from "formik";
import { string, object } from "yup";
import { AppNavbarBrand } from "@coreui/react";
import FormStatus from "shared/Formik/FormStatus";

import logo from "assets/img/brand/logo.png";

import publicRoutes from "_constants/publicRoutes";
import IconField from "shared/Formik/IconField";

const registerSchema = object().shape({
  email: string()
    .email("Invalid email")
    .required("Email is required"),
  password: string()
    .min(6, "Password must be at least 6 characters")
    .required("Password is required")
});

const RegisterForm = ({ status, isSubmitting }) => (
  <Form>
    <AppNavbarBrand
      full={{
        src: logo,
        width: 155,
        height: 45,
        alt: "P3A Logo"
      }}
    />
    <h1>Register</h1>
    <p className="text-muted">Create your account</p>
    <Field
      name="email"
      render={formikProps => <IconField {...formikProps} type="email" />}
    />
    <Field
      name="password"
      render={formikProps => <IconField {...formikProps} type="password" />}
    />
    <Button
      data-testid="submitButton"
      color="primary"
      className="px-4"
      type="submit"
      disabled={isSubmitting}
    >
      Register
    </Button>
    <FormStatus status={status} />
  </Form>
);

RegisterForm.propTypes = {
  status: PropTypes.shape({ msg: PropTypes.string }).isRequired,
  isSubmitting: PropTypes.bool.isRequired
};

const Register = ({ createUser }) => (
  <div className="app flex-row align-items-center">
    <Container>
      <Row className="justify-content-center">
        <Col sm="8" md="6" lg="5" xl="4">
          <Card>
            <CardBody className="p-4">
              <Formik
                initialValues={{ email: "", password: "" }}
                onSubmit={({ email, password }, actions) => {
                  createUser(email, password).catch(error => {
                    actions.setSubmitting(false);
                    actions.setStatus({ msg: error.message });
                  });
                }}
                isInitialValid={false}
                validationSchema={registerSchema}
                render={RegisterForm}
              />
              <hr />
              <Link data-testid="loginLink" to={publicRoutes.login.path}>
                Already have an account? Sign in!
              </Link>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  </div>
);

Register.propTypes = {
  createUser: PropTypes.func.isRequired
};

export default Register;
