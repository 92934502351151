// creates the types, actions and reducers for this duck. It exports the duck reducer.
import { combineReducers } from "redux";
import {
  createSimpleState,
  createApiRequestState
} from "_state/boilerplateKillers";

const duckName = "session";

const authUser = "authUser";
export const authUserState = createSimpleState(duckName, authUser);

const startupData = "startupData";
export const startupDataState = createApiRequestState(duckName, startupData);

export const antiForgeTokenState = createApiRequestState(
  duckName,
  "antiForgeToken"
);

const reducer = combineReducers({
  [authUserState.stateName]: authUserState.reducer,
  [startupDataState.stateName]: startupDataState.reducer,
  [antiForgeTokenState.stateName]: antiForgeTokenState.reducer
});

export default reducer;
