import needsCompanies from "shared/needsCompanies/needsCompanies";
import needsSubscription from "shared/needsSubscription/needsSubscription";
import AddCompany from "App/UserInApp/AddCompany/AddCompany";
import ClassesContainer from "App/UserInApp/Classes/ClassesContainer";
import CompanySettingsContainer from "App/UserInApp/CompanySettings/CompanySettingsContainer";
import CustomersContainer from "App/UserInApp/Customers/CustomersContainer";
import DashboardContainer from "App/UserInApp/Dashboard/DashboardContainer";
import Help from "App/UserInApp/Help/Help";
import LabourAccountsContainer from "App/UserInApp/LabourAccounts/LabourAccountsContainer";
import ManageUsersPage from "App/UserInApp/ManageUsers/ManageUsersPage";
import MyCompaniesContainer from "App/UserInApp/MyCompanies/MyCompaniesContainer";
import NameValuePairContainer from "App/UserInApp/NameValuePair/NameValuePairContainer";
import PaymentPageContainer from "App/UserInApp/PaymentPage/PaymentPageContainer";
import EditPlanContainer from "App/UserInApp/EditPlan/EditPlanContainer";
import PlansContainer from "App/UserInApp/Plans/PlansContainer";
import ProductsContainer from "App/UserInApp/Products/ProductsContainer";
import ProductSalesContainer from "App/UserInApp/ProductSales/ProductSalesContainer";
import PnlPageContainer from "App/UserInApp/PnlPage/PnlPageContainer";
import RegionsContainer from "App/UserInApp/Regions/RegionsContainer";
import ReportsContainer from "App/UserInApp/Reports/ReportsContainer";
import SubregionsContainer from "App/UserInApp/Subregions/SubregionsContainer";
import TagYourDataContainer from "App/UserInApp/TagYourData/TagYourDataContainer";

const company = "/company/:companyId";
const getCompanyUrl = companyId => `/company/${companyId}`;

const prependCompany = path => ({
  path: `${company}/${path}`,
  getUrl: companyId => `${getCompanyUrl(companyId)}/${path}`
});

const privateRoutes = {
  addCompany: {
    name: "Add Company",
    path: "/add-company",
    component: AddCompany,
    exact: true
  },
  classes: {
    ...prependCompany("classes"),
    name: "Classes",
    component: needsSubscription(ClassesContainer),
    needsSubscription: true,
    exact: true
  },
  companySettings: {
    ...prependCompany("company-settings"),
    name: "Company Settings",
    component: needsSubscription(CompanySettingsContainer),
    needsSubscription: true,
    exact: true
  },
  custom1: {
    ...prependCompany("custom1"),
    name: "Custom Field 1",
    singularName: "Item",
    component: needsSubscription(NameValuePairContainer),
    needsSubscription: true,
    passedProps: { type: "custom1" },
    exact: true
  },
  custom2: {
    ...prependCompany("custom2"),
    name: "Custom Field 2",
    singularName: "Item",
    component: needsSubscription(NameValuePairContainer),
    needsSubscription: true,
    passedProps: { type: "custom2" },
    exact: true
  },
  customers: {
    ...prependCompany("customers"),
    name: "Customers",
    component: needsSubscription(CustomersContainer),
    needsSubscription: true,
    exact: true
  },
  dashboard: {
    path: "/dashboard",
    name: "Dashboard",
    component: needsSubscription(DashboardContainer),
    needsSubscription: true,
    exact: true
  },
  editPlan: {
    path: `${company}/edit-plan/:planId/title/:planTitle`,
    getUrl: (companyId, planId, planTitle) =>
      `${getCompanyUrl(companyId)}/edit-plan/${planId}/title/${planTitle}`,
    name: "Edit Plan",
    singularName: "Monthly Target",
    component: needsSubscription(EditPlanContainer),
    needsSubscription: true,
    exact: true
  },
  help: { path: "/help", name: "Help", component: Help, exact: true },
  labourAccounts: {
    ...prependCompany("labour-accounts"),
    name: "Labour Accounts",
    singularName: "Account",
    component: needsSubscription(LabourAccountsContainer),
    needsSubscription: true,
    exact: true
  },
  manageUsers: {
    ...prependCompany("manage-users"),
    name: "Manage Users",
    singularName: "Invite User",
    component: needsSubscription(ManageUsersPage),
    needsSubscription: true,
    exact: true
  },
  myCompanies: {
    path: "/my-companies",
    name: "My Companies",
    component: MyCompaniesContainer,
    exact: true
  },
  payment: {
    ...prependCompany("payment"),
    name: "Payment Information",
    component: needsCompanies(PaymentPageContainer),
    needsSubscription: false,
    exact: true
  },
  plans: {
    ...prependCompany("plans"),
    name: "Plan for Each Product/Service",
    component: needsSubscription(PlansContainer),
    needsSubscription: true,
    exact: true
  },
  products: {
    ...prependCompany("products"),
    name: "Products",
    component: needsSubscription(ProductsContainer),
    needsSubscription: true,
    exact: true
  },
  productSales: {
    ...prependCompany("product-sales"),
    name: "Product Sales",
    component: needsSubscription(ProductSalesContainer),
    needsSubscription: true,
    exact: true
  },
  pnl: {
    ...prependCompany("profit-and-loss"),
    name: "Profit And Loss",
    component: needsSubscription(PnlPageContainer),
    needsSubscription: true,
    exact: true
  },
  regions: {
    ...prependCompany("regions"),
    name: "Regions",
    singularName: "Region",
    component: needsSubscription(RegionsContainer),
    needsSubscription: true,
    exact: true
  },
  reports: {
    ...prependCompany("reports"),
    name: "Reports",
    component: needsSubscription(ReportsContainer),
    needsSubscription: true,
    exact: true
  },
  sectors: {
    ...prependCompany("sectors"),
    name: "Sectors",
    component: needsSubscription(NameValuePairContainer),
    singularName: "Sector",
    needsSubscription: true,
    passedProps: { type: "sector" },
    exact: true
  },
  segments: {
    ...prependCompany("segments"),
    name: "Segments",
    component: needsSubscription(NameValuePairContainer),
    singularName: "Segment",
    needsSubscription: true,
    passedProps: { type: "segment" },
    exact: true
  },
  subregions: {
    ...prependCompany("subregions"),
    name: "Regions/Subregions",
    singularName: "Region/Subregion",
    component: needsSubscription(SubregionsContainer),
    needsSubscription: true,
    exact: true
  },
  tagYourData: {
    ...prependCompany("tagging"),
    name: "Tag Your Data",
    singularName: "Class",
    component: needsSubscription(TagYourDataContainer),
    needsSubscription: true,
    exact: true
  },
  valueStreams: {
    ...prependCompany("value-streams"),
    name: "Value Streams",
    singularName: "Value Stream",
    component: needsSubscription(NameValuePairContainer),
    needsSubscription: true,
    passedProps: { type: "valueStream" },
    exact: true
  }
};

export default privateRoutes;
