import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import { apiRequestType, routerTypes } from "_types";
import { queriedProducts } from "_state/ducks/product/selectors";
import { queryProducts } from "_state/ducks/product/operations";
import { getActiveColumn } from "shared/Active";
import ProductSelect from "shared/Selects/ProductSelect";
import SmartTable from "shared/SmartTable";

class ProductsContainer extends Component {
  renderNameFilter = ({ filter, onChange }) => {
    const { match } = this.props;
    return (
      <ProductSelect
        companyId={match.params.companyId}
        onChange={option => {
          onChange(option ? option.FullyQualifiedName : "");
        }}
        isClearable
      />
    );
  };

  render() {
    const { queriedProducts, queryProducts, match } = this.props;

    return (
      <SmartTable
        error={queriedProducts.error}
        queriedEntities={queriedProducts}
        queryEntities={queryProducts}
        companyId={match.params.companyId}
        columns={[
          {
            Header: "Fully Qualified Name",
            accessor: "FullyQualifiedName",
            Filter: this.renderNameFilter
          },
          getActiveColumn({ isQbo: true })
        ]}
        defaultSorted={[
          {
            id: "lowercaseName",
            desc: false
          }
        ]}
      />
    );
  }
}

ProductsContainer.propTypes = {
  queriedProducts: apiRequestType.isRequired,
  queryProducts: PropTypes.func.isRequired,
  match: routerTypes.match.isRequired
};

const mapStateToProps = makeMapStateToProps({
  queriedProducts
});

const mapDispatchToProps = {
  queryProducts
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductsContainer);
