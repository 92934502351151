export default [
  "Today",
  "Yesterday",
  "This Week",
  "Last Week",
  "This Week-to-date",
  "Last Week-to-date",
  "Next Week",
  "Next 4 Weeks",
  "This Month",
  "Last Month",
  "This Month-to-date",
  "Last Month-to-date",
  "Next Month",
  "This Fiscal Quarter",
  "Last Fiscal Quarter",
  "This Fiscal Quarter-to-date",
  "Last Fiscal Quarter-to-date",
  "Next Fiscal Quarter",
  "This Fiscal Year",
  "Last Fiscal Year",
  "This Fiscal Year-to-date",
  "Last Fiscal Year-to-date",
  "Next Fiscal Year"
];
