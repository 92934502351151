import {
  queryState,
  createState,
  updateState,
  sectorsState,
  segmentsState,
  valueStreamsState,
  custom1State,
  custom2State
} from "./creator";

export const queriedNameValuePairs = queryState.selector;

export const createNameValuePairObj = createState.selector;

export const updateNameValuePairObj = updateState.selector;

export const fetchedSectors = sectorsState.selector;
export const fetchedSegments = segmentsState.selector;
export const fetchedValueStreams = valueStreamsState.selector;
export const fetchedCustom1 = custom1State.selector;
export const fetchedCustom2 = custom2State.selector;
