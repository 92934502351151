import React from "react";

const Footer = () => (
  <React.Fragment>
    <span className="ml-auto">
      2019 &copy; <a href="_blank">P3A</a>
    </span>
  </React.Fragment>
);

export default Footer;
