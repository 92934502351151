import React from "react";
import PropTypes from "prop-types";
import { Input, InputGroup, InputGroupAddon } from "reactstrap";

const PercentInput = ({ value, ...rest }) => (
  <div>
    <InputGroup>
      <Input {...rest} min="0" max="100" type="number" value={value} />
      <InputGroupAddon addonType="append">%</InputGroupAddon>
    </InputGroup>
  </div>
);

PercentInput.propTypes = {
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onChange: PropTypes.func
};

PercentInput.defaultProps = {
  onChange: undefined
};

export default PercentInput;
