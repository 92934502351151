import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import { apiRequestType, routerTypes } from "_types";
import { fetchedPnl } from "_state/ducks/profitAndLoss/selectors";
import { fetchPnl } from "_state/ducks/profitAndLoss/operations";
import PnlPage from "./PnlPage";

class PnlPageContainer extends Component {
  componentDidMount() {}

  render() {
    const { fetchedPnl, fetchPnl, match } = this.props;
    return (
      <PnlPage
        fetchedPnl={fetchedPnl}
        fetchPnl={fetchPnl}
        companyId={match.params.companyId}
      />
    );
  }
}

PnlPageContainer.propTypes = {
  fetchedPnl: apiRequestType.isRequired,
  fetchPnl: PropTypes.func.isRequired,
  match: routerTypes.match.isRequired
};

const mapStateToProps = makeMapStateToProps({
  fetchedPnl
});

const mapDispatchToProps = {
  fetchPnl
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PnlPageContainer);
