import React, { Component } from "react";
import _ from "lodash";
import ReactTable from "react-table";
import PropTypes from "prop-types";
import { Card, CardBody, CardFooter } from "reactstrap";
import "react-table/react-table.css";
import { apiRequestType } from "_types";
import withError from "shared/withError/withError";

const getCursor = queriedEntities => {
  if (!_.isEmpty(queriedEntities.data)) {
    return queriedEntities.data.nextPageCursor;
  }
  return null;
};

const fixFilter = ({ id, value }) => {
  // Needed in order to filter a column on a field that is different
  // from column.id
  const isNameFilter = id === "name" || id === "FullyQualifiedName";

  // Pull value[id] in order for a filter to select an entity,
  // but to filter on a property
  const newId = isNameFilter ? "lowercaseName" : id;
  const newValue = isNameFilter ? value[newId] : value;

  return {
    value: newValue,
    id: newId
  };
};

class SmartTable extends Component {
  constructor(props) {
    super();
    this.state = {
      previousPage: 0,
      pageBeforePrevious: 0
    };
  }

  fetchData = (state, instance) => {
    const { queryEntities, queriedEntities, companyId } = this.props;
    let { previousPage, pageBeforePrevious } = this.state;
    const { pageSize, page, sorted, filtered } = state; // React table state

    let cursor = getCursor(queriedEntities);

    const equalityFilters = filtered.map(filter => fixFilter(filter));

    if (page === 0) {
      // When we reverse back to page one we won't get a nextPageCursor because
      // there are no more records going back. To avoid this problem we just pretend we're
      // at the start again
      previousPage = 0;
      pageBeforePrevious = 0;
      cursor = null;
    }

    const directionChanged =
      page === 0 && pageBeforePrevious === 0
        ? false // No direction change when we start
        : page === pageBeforePrevious;

    queryEntities({
      companyId,
      pageSize,
      cursor,
      directionChanged,
      direction: page - previousPage,
      order: _.isEmpty(sorted) ? null : sorted[0],
      equalityFilters
    });

    this.setState({
      pageBeforePrevious: previousPage,
      previousPage: page
    });
  };

  render() {
    const {
      queriedEntities,
      columns,
      defaultSorted,
      renderHeader,
      renderFooter
    } = this.props;
    const { previousPage } = this.state;

    // We always have one more page than our current page if we have a cursor
    // and pages index starts at 0, so we add 2
    let cursor = getCursor(queriedEntities);
    const pages = cursor ? previousPage + 2 : previousPage;

    return (
      <Card>
        {renderHeader()}
        <CardBody>
          <ReactTable
            columns={columns}
            manual
            data={queriedEntities.data.entities}
            loading={queriedEntities.loading}
            onFetchData={this.fetchData}
            filterable
            className="-striped -highlight"
            // Sorting
            multiSort={false}
            defaultSorted={defaultSorted}
            // Pagination
            pages={pages}
            showPageSizeOptions={false}
            defaultPageSize={15}
            showPageJump={false}
            renderTotalPagesCount={pages => null}
            ofText=""
            getPaginationProps={(state, rowInfo, column, instance) => ({
              canNext: !!queriedEntities.data.nextPageCursor
            })}
          />
        </CardBody>
        <CardFooter>{renderFooter()}</CardFooter>
      </Card>
    );
  }
}

SmartTable.propTypes = {
  queriedEntities: apiRequestType.isRequired,
  queryEntities: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  defaultSorted: PropTypes.arrayOf(PropTypes.object),
  renderHeader: PropTypes.func,
  renderFooter: PropTypes.func
};

SmartTable.defaultProps = {
  renderHeader: () => null,
  renderFooter: () => null,
  defaultSorted: []
};

export default withError(SmartTable);
