import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { componentType } from "_types";
import withEditModeToggle from "./withEditModeToggle";

const Editing = ({
  editingVal,
  onChange,
  component,
  disabled,
  passedProps: { readAccessor, ...restPassedProps }
}) => {
  const Component = component; // React needs uppercase components, but lowercase props.
  return (
    <Component
      value={editingVal}
      onChange={onChange}
      disabled={disabled}
      {...restPassedProps}
    />
  );
};

Editing.propTypes = {
  // eslint-disable-next-line
  editingVal: PropTypes.any,
  onChange: PropTypes.func.isRequired,
  component: PropTypes.oneOfType([componentType]).isRequired,
  passedProps: PropTypes.objectOf(Object),
  disabled: PropTypes.bool.isRequired
};

Editing.defaultProps = {
  passedProps: {},
  editingVal: null
};

const Reading = ({ readingVal, passedProps: { readAccessor } }) => {
  let text;
  if (readAccessor && readingVal) {
    if (_.isFunction(readAccessor)) {
      text = readAccessor(readingVal);
    } else {
      text = readingVal[readAccessor];
    }
  } else {
    text = readingVal;
  }

  return <span title={text}>{text}</span>;
};

Reading.propTypes = {
  readingVal: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number
  ]),
  passedProps: PropTypes.objectOf(Object)
};

Reading.defaultProps = {
  passedProps: {},
  readingVal: null
};

export default withEditModeToggle(Reading, Editing);
