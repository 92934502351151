// Separate file so when we mapStateToProps we can use shorthand object notation
import {
  fetchDashboardState,
  queryState,
  createState,
  updateState
} from "./creator";

export const dashboard = fetchDashboardState.selector;

export const queriedPlans = queryState.selector;

export const createPlanObj = createState.selector;

export const updatePlanObj = updateState.selector;
