import React from "react";

const ReportHelpText = () => (
  <div>
    <br />
    When running reports, you may see a column labelled{" "}
    <strong>"Not Specified"</strong>. Items may appear in this column if:
    <br />
    <ol>
      <li>They Do not have a class</li>
      <li>
        They have a class but the class has not been tagged with a category
        corresponding to the selected grouping.
      </li>
    </ol>
    For example, if you group by region and one of your classes is missing a
    region, its data will show up in the "Not Specified" column.
  </div>
);

export default ReportHelpText;
