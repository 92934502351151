import React, { Component } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { apiRequestType, routerTypes, componentType } from "_types";
import getRouteFromUrl from "_constants/getRouteFromUrl";
import { getActiveColumn } from "shared/Active";
import ErrorModal from "shared/ErrorModal/ErrorModal";
import SmartTable from "./SmartTable";
import TableHeader from "./TableHeader";
import RowActions from "./RowActions";
import Cell from "./Cell";

class EditTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      key: 1, // Incrementing key remounts the table
      editedRows: {},
      updateError: null,
      currentFilter: null
    };
  }

  handleChange = (id, field, value) => {
    this.setState(prevState => ({
      editedRows: {
        ...prevState.editedRows,
        [id]: {
          ...prevState.editedRows[id],
          [field]: value
        }
      }
    }));
  };

  refreshTable = () => {
    this.setState(prevState => ({
      key: prevState.key + 1
    }));
  };

  update = entity => {
    const { update, match } = this.props;

    return update({
      ...entity,
      companyId: match.params.companyId
    });
  };

  create = entity => {
    const { create, match } = this.props;

    return create({
      ...entity,
      companyId: match.params.companyId
    });
  };

  getEditModeOfRow = id => {
    const { editedRows } = this.state;

    return !!editedRows[id];
  };

  getEditingVal = (id, field) => {
    const { editedRows } = this.state;
    return editedRows[id] ? editedRows[id][field] : null;
  };

  editRow = id => {
    const { queriedEntities } = this.props;

    let entity = _.find(queriedEntities.data.entities, { id });

    this.setState(prevState => ({
      editedRows: {
        ...prevState.editedRows,
        [id]: entity
      }
    }));
  };

  cancelRow = id => {
    this.setState(prevState => ({
      editedRows: {
        ...prevState.editedRows,
        [id]: null
      }
    }));
  };

  saveRow = id => {
    const { set, queriedEntities } = this.props;
    const { editedRows } = this.state;

    this.update(editedRows[id])
      .then(resp => {
        const entity = resp.data;

        const data = _.cloneDeep(queriedEntities.data);

        const index = data.entities.findIndex(item => item.id === id);
        data.entities[index] = entity;

        set(data);
        this.cancelRow(id);
      })
      .catch(error => {
        const { updateObj } = this.props;
        this.setState({
          updateError: updateObj.error
        });
      });
  };

  renderActions = props => {
    const editMode = this.getEditModeOfRow(props.original.id);
    const { updateObj } = this.props;

    return (
      <RowActions
        onEdit={() => {
          this.editRow(props.original.id);
        }}
        onCancel={() => {
          this.cancelRow(props.original.id);
        }}
        onSave={() => {
          this.saveRow(props.original.id);
        }}
        editMode={editMode}
        saving={updateObj.loading}
      />
    );
  };

  renderCell = props => {
    const { updateObj } = this.props;

    const id = props.original.id;
    let editMode = this.getEditModeOfRow(id);
    const field = props.column.id;

    if (field === "active" && props.original.hasOwnProperty("Active")) {
      // Qbo active status is read only
      editMode = false;
    }

    return (
      <Cell
        editingVal={this.getEditingVal(id, field)}
        readingVal={props.value}
        editMode={editMode}
        onChange={eventOrOption => {
          // A react-select passes the selectedOption to onChange
          // Other inputs pass the actual event
          const value = eventOrOption.target
            ? eventOrOption.target.value
            : eventOrOption;

          this.handleChange(id, field, value);
        }}
        component={props.column.MyCellComponent}
        passedProps={props.column.myCellPassedProps}
        disabled={updateObj.loading}
        refreshTable={this.refreshTable}
      />
    );
  };

  getFilterStatus = field => {
    const { oneFilterAtATime } = this.props;
    const { currentFilter } = this.state;

    if (oneFilterAtATime) {
      if (currentFilter === null) {
        return false;
      } else {
        return field !== currentFilter;
      }
    }

    return false;
  };

  renderFilter = ({ onChange, column }) => {
    const { match } = this.props;
    const field = column.id;

    const disabled = this.getFilterStatus(field);

    return (
      <column.MyFilterComponent
        companyId={match.params.companyId}
        onChange={option => {
          console.log(option);
          onChange(option ? option : "");
          setTimeout(() => {
            // Needed so we don't remount before onChange happens
            this.setState({
              currentFilter: option ? field : null
            });
          }, 0);
        }}
        isClearable
        isFilter
        {...column.myFilterPassedProps}
        disabled={disabled}
      />
    );
  };

  render() {
    const {
      queriedEntities,
      query,
      match,
      defaultSortId,
      columns,
      FormComponent,
      renderFooter,
      readOnly,
      create,
      isQbo,
      defaultSortDesc,
      title,
      initialFormValues
    } = this.props;

    const { key, updateError } = this.state;

    const route = getRouteFromUrl(match.url);

    const processedColumns = [
      ...columns.map(column => ({
        ...column,
        Cell: this.renderCell,
        Filter: this.renderFilter
      })),
      getActiveColumn({ Cell: this.renderCell, isQbo })
    ];

    if (!readOnly) {
      processedColumns.push({
        Header: "Actions",
        accessor: "id",
        width: 120,
        Cell: this.renderActions,
        filterable: false,
        sortable: false
      });
    }

    return (
      <div>
        <ErrorModal
          error={updateError}
          onConfirm={() => {
            this.setState({ updateError: null });
          }}
        />
        <SmartTable
          key={key}
          renderHeader={() => (
            <TableHeader
              readOnly={readOnly || !create || !FormComponent}
              pluralName={title ? title : route.name}
              singularName={route.singularName}
              create={this.create}
              onSubmitComplete={this.refreshTable}
              FormComponent={FormComponent}
              companyId={match.params.companyId}
              initialFormValues={initialFormValues}
            />
          )}
          error={queriedEntities.error}
          queriedEntities={queriedEntities}
          queryEntities={query}
          companyId={match.params.companyId}
          columns={processedColumns}
          defaultSorted={
            defaultSortId
              ? [
                  {
                    id: defaultSortId,
                    desc: defaultSortDesc
                  }
                ]
              : []
          }
          renderFooter={renderFooter}
        />
      </div>
    );
  }
}

EditTable.propTypes = {
  defaultSortId: PropTypes.string,
  match: routerTypes.match.isRequired,
  query: PropTypes.func.isRequired,
  queriedEntities: apiRequestType.isRequired,
  updateObj: apiRequestType.isRequired,
  update: PropTypes.func.isRequired,
  create: PropTypes.func,
  set: PropTypes.func.isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  FormComponent: PropTypes.oneOfType([componentType]),
  renderFooter: PropTypes.func,
  readOnly: PropTypes.bool,
  oneFilterAtATime: PropTypes.bool,
  isQbo: PropTypes.bool,
  defaultSortDesc: PropTypes.bool,
  title: PropTypes.string,
  initialFormValues: PropTypes.objectOf(Object)
};

EditTable.defaultProps = {
  renderFooter: () => null,
  defaultSortId: null,
  readOnly: false,
  create: null,
  FormComponent: null,
  oneFilterAtATime: false,
  isQbo: false,
  defaultSortDesc: false,
  title: null,
  initialFormValues: undefined
};

export default EditTable;
