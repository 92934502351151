import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import moment from "moment";
import { connect } from "react-redux";
import { Input } from "reactstrap";
import { format } from "_utils";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import { apiRequestType, routerTypes } from "_types";
import { startupData } from "_state/ducks/session/selectors";
import { queriedTargets, updateTargetObj } from "_state/ducks/target/selectors";
import {
  queryTargets,
  createTarget,
  updateTarget,
  setTargets
} from "_state/ducks/target/operations";
import MonthlyTargetForm from "shared/Forms/MonthlyTargetForm";
import PercentInput from "shared/PercentInput/PercentInput";
import MonthPicker from "shared/Formik/Dates/MonthPicker";
import EditTable from "shared/SmartTable/EditTable";

class EditPlanContainer extends React.Component {
  query = options => {
    const { queryTargets, match } = this.props;

    queryTargets({
      ...options,
      equalityFilters: [
        ...options.equalityFilters,
        { id: "plan", value: { id: match.params.planId } }
      ]
    });
  };

  create = entity => {
    const { createTarget, match } = this.props;

    return createTarget({
      ...entity,
      companyId: match.params.companyId,
      plan: { id: match.params.planId }
    });
  };

  update = entity => {
    const { updateTarget, match } = this.props;

    return updateTarget({
      ...entity,
      companyId: match.params.companyId,
      plan: { id: match.params.planId }
    });
  };

  render() {
    const {
      match,
      queriedTargets,
      updateTargetObj,
      setTargets,
      startupData
    } = this.props;

    // Initialize the form with the most newest target
    let initialFormValues = undefined;
    if (!_.isEmpty(queriedTargets.data.entities)) {
      const recentTarget = queriedTargets.data.entities[0];

      const nextMonthString = moment
        .utc(recentTarget.month)
        .add(1, "months")
        .format();

      initialFormValues = {
        month: nextMonthString,
        revenue: recentTarget.revenue,
        cogs: recentTarget.cogs,
        directLabour: recentTarget.directLabour
      };
    }

    return (
      <EditTable
        title={`Monthly Targets for: ${match.params.planTitle}`}
        readOnly={
          startupData.data.currentCompany.myRole !== "level1" &&
          startupData.data.currentCompany.myRole !== "level2"
        }
        defaultSortId="month"
        defaultSortDesc
        match={match}
        query={this.query}
        queriedEntities={queriedTargets}
        updateObj={updateTargetObj}
        update={this.update}
        set={setTargets}
        create={this.create}
        FormComponent={MonthlyTargetForm}
        initialFormValues={initialFormValues}
        columns={[
          {
            Header: "Month",
            accessor: "month",
            MyCellComponent: MonthPicker,
            filterable: false,
            myCellPassedProps: {
              readAccessor: input => {
                return moment.utc(input).format("MMM YYYY");
              }
            },
            minWidth: 190
          },
          {
            Header: "Revenue",
            accessor: "revenue",
            filterable: false,
            sortable: false,
            MyCellComponent: Input,
            myCellPassedProps: {
              readAccessor: input => format.toDollarString(input, true, 0),
              type: "number"
            }
          },
          {
            Header: "COGS",
            accessor: "cogs",
            filterable: false,
            sortable: false,
            MyCellComponent: PercentInput,
            myCellPassedProps: {
              readAccessor: format.toPercentString
            }
          },
          {
            Header: "Direct Labour",
            accessor: "directLabour",
            filterable: false,
            sortable: false,
            MyCellComponent: PercentInput,
            myCellPassedProps: {
              readAccessor: format.toPercentString
            }
          }
        ]}
      />
    );
  }
}

EditPlanContainer.propTypes = {
  queriedTargets: apiRequestType.isRequired,
  queryTargets: PropTypes.func.isRequired,
  createTarget: PropTypes.func.isRequired,
  updateTargetObj: apiRequestType.isRequired,
  updateTarget: PropTypes.func.isRequired,
  setTargets: PropTypes.func.isRequired,
  startupData: apiRequestType.isRequired,
  match: routerTypes.match.isRequired
};

const mapStateToProps = makeMapStateToProps({
  queriedTargets,
  updateTargetObj,
  startupData
});

const mapDispatchToProps = {
  queryTargets,
  createTarget,
  updateTarget,
  setTargets
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPlanContainer);
