import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import { apiRequestType, routerTypes } from "_types";
import { queriedClasses } from "_state/ducks/class/selectors";
import { queryClasses } from "_state/ducks/class/operations";
import { getActiveColumn } from "shared/Active";
import ClassSelect from "shared/Selects/ClassSelect";
import SmartTable from "shared/SmartTable";

class ClassesContainer extends Component {
  renderNameFilter = ({ filter, onChange }) => {
    const { match } = this.props;
    return (
      <ClassSelect
        companyId={match.params.companyId}
        onChange={option => {
          onChange(option ? option.FullyQualifiedName : "");
        }}
        isClearable
      />
    );
  };

  render() {
    const { queriedClasses, queryClasses, match } = this.props;

    return (
      <SmartTable
        error={queriedClasses.error}
        queriedEntities={queriedClasses}
        queryEntities={queryClasses}
        companyId={match.params.companyId}
        columns={[
          {
            Header: "Fully Qualified Name",
            accessor: "FullyQualifiedName",
            Filter: this.renderNameFilter
          },
          getActiveColumn({ isQbo: true })
        ]}
        defaultSorted={[
          {
            id: "lowercaseName",
            desc: false
          }
        ]}
      />
    );
  }
}

ClassesContainer.propTypes = {
  queriedClasses: apiRequestType.isRequired,
  queryClasses: PropTypes.func.isRequired,
  match: routerTypes.match.isRequired
};

const mapStateToProps = makeMapStateToProps({
  queriedClasses
});

const mapDispatchToProps = {
  queryClasses
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClassesContainer);
