import { combineReducers } from "redux";
import { createApiRequestState } from "_state/boilerplateKillers";

const duckName = "roleInCompany";

export const queryState = createApiRequestState(duckName, "query");

export const updateState = createApiRequestState(duckName, "update");

const reducer = combineReducers({
  [queryState.stateName]: queryState.reducer,
  [updateState.stateName]: updateState.reducer
});

export default reducer;
