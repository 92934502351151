import React from "react";
import { Alert } from "reactstrap";
import { apiErrorType } from "_types";

const withError = InputComponent => {
  const WithError = ({ error, ...rest }) => {
    if (error) {
      return (
        <Alert color="danger">{error.data ? error.data : error.message}</Alert>
      );
    }
    return <InputComponent {...rest} />;
  };

  WithError.propTypes = {
    error: apiErrorType
  };

  WithError.defaultProps = {
    error: null
  };

  return WithError;
};

export default withError;
