import React from "react";
import { selectClasses } from "_state/ducks/class/operations";
import AsyncSelect from "./AsyncSelect";

const ClassSelect = props => (
  <AsyncSelect
    callApiForOptions={selectClasses}
    labelProperty="FullyQualifiedName"
    filterProperty="FullyQualifiedName"
    {...props}
  />
);

export default ClassSelect;
