import React from "react";
import { HashRouter, Route, Switch } from "react-router-dom";
import "./App.scss";
import { getNextId } from "_utils";
import publicRoutes from "_constants/publicRoutes";
import UserInAppContainer from "./UserInApp/UserInAppContainer";
import withSpinner from "shared/withSpinner/withSpinner";

const App = () => (
  <HashRouter>
    <Switch>
      {Object.values(publicRoutes).map(route => {
        return route.component ? (
          <Route
            key={getNextId("publicRoutes")}
            path={route.path}
            exact={route.exact}
            name={route.name}
            render={props => <route.component {...props} />}
          />
        ) : null;
      })}
      <Route path="/" name="Home" component={UserInAppContainer} />
    </Switch>
  </HashRouter>
);

export default withSpinner(App);
