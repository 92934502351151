import { combineReducers } from "redux";
import { createApiRequestState } from "_state/boilerplateKillers";

const duckName = "plan";

export const fetchDashboardState = createApiRequestState(
  duckName,
  "fetchDashboard"
);

export const queryState = createApiRequestState(duckName, "query");

export const createState = createApiRequestState(duckName, "create");

export const updateState = createApiRequestState(duckName, "update");

const reducer = combineReducers({
  [fetchDashboardState.stateName]: fetchDashboardState.reducer,
  [queryState.stateName]: queryState.reducer,
  [createState.stateName]: createState.reducer,
  [updateState.stateName]: updateState.reducer
});

export default reducer;
