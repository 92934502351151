import React from "react";
import PropTypes from "prop-types";
import { ModalHeader, ModalBody, FormGroup, Label } from "reactstrap";
import { Formik, Field, Form } from "formik";
import { string, object, bool, number } from "yup";
import InputField from "shared/Formik/InputField";
import FormStatus from "shared/Formik/FormStatus";
import FormButtons from "./FormButtons";

const schema = object().shape({
  enableCustomFields: bool().required("Enable Custom Fields is required"),
  custom1Name: string().required("Custom 1 Name is required"),
  custom2Name: string().required("Custom 2 Name is required"),
  numberOfEmployees: number().integer("Number of Employees must be an integer")
});

const CompanySettingsModal = ({
  submit,
  toggle,
  onSubmitComplete,
  initialFormValues
}) => (
  <Formik
    initialValues={initialFormValues}
    onSubmit={(entity, actions) => {
      submit(entity)
        .then(resp => {
          onSubmitComplete(resp.data);
        })
        .catch(error => {
          actions.setSubmitting(false);
          actions.setStatus({ msg: error.message });
        });
    }}
    isInitialValid={false}
    validationSchema={schema}
    render={({ status, isSubmitting }) => (
      <Form>
        <ModalHeader>Company Settings</ModalHeader>
        <ModalBody>
          <FormGroup check>
            <Label check>
              <Field
                name="enableCustomFields"
                render={formikProps => (
                  <InputField
                    {...formikProps}
                    type="checkbox"
                    checkboxLabel="Enable Custom Fields"
                  />
                )}
              />
            </Label>
          </FormGroup>
          <FormGroup>
            <Label>Custom 1 Name: </Label>
            <Field
              name="custom1Name"
              render={formikProps => <InputField {...formikProps} />}
            />
          </FormGroup>
          <FormGroup>
            <Label>Custom 2 Name: </Label>
            <Field
              name="custom2Name"
              render={formikProps => <InputField {...formikProps} />}
            />
          </FormGroup>
          <FormGroup>
            <Label>Number of Employees: </Label>
            <Field
              name="numberOfEmployees"
              render={formikProps => (
                <InputField {...formikProps} type="number" step="1" />
              )}
            />
          </FormGroup>
          <FormStatus status={status} />
        </ModalBody>
        <FormButtons toggle={toggle} isSubmitting={isSubmitting} />
      </Form>
    )}
  />
);

CompanySettingsModal.propTypes = {
  submit: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  onSubmitComplete: PropTypes.func.isRequired,
  initialFormValues: PropTypes.objectOf(Object).isRequired
};

export default CompanySettingsModal;
