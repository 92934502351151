import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { apiRequestType } from "_types";
import AddNewPlanButton from "./AddNewPlanButton";
import withSpinner from "shared/withSpinner/withSpinner";
import withError from "shared/withError/withError";
import "./PlanSummary.css";
import PlanSummary from "./PlanSummary";
import ToggleActive from "./ToggleActive";
import ForceRefreshButton from "shared/ForceRefreshButton/ForceRefreshButton";

class Plans extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showActive: true
    };
  }

  toggle = () => {
    this.setState(prevState => ({
      showActive: !prevState.showActive
    }));
  };

  render() {
    const {
      companyId,
      addNewPlan,
      fetchPlans,
      queriedPlans,
      updatePlan,
      forceRefreshPlans
    } = this.props;

    const { showActive } = this.state;

    if (_.isEmpty(queriedPlans.data)) {
      return null;
    }

    const filteredPlans = queriedPlans.data.plans.filter(
      item => item.active === showActive
    );

    const sortedPlans = _.sortBy(filteredPlans, [
      // Sort by name, prepend EntireOrganization, append EverythingElse
      item => {
        if (item.product) {
          return item.product.FullyQualifiedName;
        }

        if (item.id === "everythingElse") {
          return "~~~~~~~~~~~~~";
        }
        if (item.id === "entireOrganization") {
          return "                   ";
        }
      }
    ]);

    return (
      <div className="maxW900">
        Missing one? Click{" "}
        <AddNewPlanButton
          submit={addNewPlan}
          onSubmitComplete={fetchPlans}
          companyId={companyId}
        />{" "}
        to add tracking to another product/service.
        <ToggleActive showActive={showActive} toggle={this.toggle} />
        <br />
        <br />
        <ForceRefreshButton
          doRefresh={forceRefreshPlans}
          calculatedAt={queriedPlans.data.calculatedAt}
        />
        {sortedPlans.map((plan, index) => {
          let groupHeader = null;
          if (showActive) {
            if (index === 0) {
              groupHeader = "Entire Organization";
            } else if (index === 1) {
              groupHeader = "Tracked Products";
            } else if (index === sortedPlans.length - 1) {
              groupHeader = "Remainder";
            }
          }
          return (
            <div key={plan.id}>
              {groupHeader && <h4>{groupHeader}</h4>}
              <PlanSummary
                companyId={companyId}
                plan={plan}
                fetchPlans={fetchPlans}
                deletePlan={() => {
                  return updatePlan({
                    companyId,
                    id: plan.id,
                    active: false
                  });
                }}
                restorePlan={() => {
                  return updatePlan({
                    companyId,
                    id: plan.id,
                    active: true
                  });
                }}
              />
            </div>
          );
        })}
      </div>
    );
  }
}

Plans.propTypes = {
  companyId: PropTypes.string.isRequired,
  addNewPlan: PropTypes.func.isRequired,
  fetchPlans: PropTypes.func.isRequired,
  queriedPlans: apiRequestType.isRequired,
  updatePlan: PropTypes.func.isRequired,
  forceRefreshPlans: PropTypes.func.isRequired
};

export default withSpinner(withError(Plans));
