import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import _ from "lodash";
import { Card, CardSubtitle, CardBody } from "reactstrap";
import { MultiGrid, AutoSizer } from "react-virtualized";
import withSpinner from "shared/withSpinner/withSpinner";
import "./Report.css";
import cn from "classnames";
import ReportHeader from "./ReportHeader";
import ReportToolbar from "./ReportToolbar";
import { format } from "_utils";

const getNumberFormat = (input, showDollarSign = true) => {
  if (input === "") {
    // No formatting needed for empty string
    return input;
  }
  if (input === "0.00") {
    return "";
  }
  if (input.includes("%")) {
    return format.toPercentString(input, 2, true);
  }
  return format.toDollarString(input, showDollarSign);
};

const getCellValue = ({ cellType, expanded, values, summary }, columnIndex) => {
  if (columnIndex === 0) {
    // It's a title, no formatting required
    return values[columnIndex];
  }
  if (cellType === "Header") {
    if (expanded) {
      // Don't show section totals when expanded
      return getNumberFormat(values[columnIndex]);
    } else {
      return getNumberFormat(summary[columnIndex]);
    }
  }
  if (cellType === "Summary") {
    return getNumberFormat(values[columnIndex]);
  }
  return getNumberFormat(values[columnIndex], false);
};

const getIconAndIndent = (columnIndex, row) => {
  if (columnIndex === 0) {
    const iconStyle = { width: "9px" }; // Handle different widths of carets
    const baseMargin = row.nestLevel * 0.4;
    if (row.expanded === false) {
      return {
        icon: <i style={iconStyle} className="fas fa-caret-right" />,
        indent: {
          marginLeft: `${baseMargin}rem`
        }
      };
    }
    if (row.expanded === true) {
      return {
        icon: <i style={iconStyle} className="fas fa-caret-down" />,
        indent: {
          marginLeft: `${baseMargin}rem`
        }
      };
    }
    return {
      icon: null,
      indent: { marginLeft: `${baseMargin + 0.8}rem` }
    };
  }
  return {
    icon: null,
    indent: null
  };
};

const setExpanded = (rows, expanded) =>
  rows.map(item => {
    const newItem = { ...item };
    if (item.expanded === false || item.expanded === true) {
      newItem.expanded = expanded;
    }
    return newItem;
  });

class ProfitAndLossReport extends Component {
  constructor(props) {
    super(props);
    // Component remounts when new pnl is fetched because of withSpinner
    // so we can instantiate state from pnl in constructor, without worrying
    this.state = {
      hoveredRowIndex: 0,
      expanded: false,
      _columns: !_.isEmpty(props.report) ? [...props.report.cols] : null,
      _rows: !_.isEmpty(props.report)
        ? setExpanded(props.report.rows, false)
        : null,
      reportWidth: 600
    };
  }

  mouseOver = (event, columnIndex, rowIndex) => {
    this.setState({
      hoveredRowIndex: rowIndex
    });
  };

  getCellClassString = (columnIndex, rowIndex, cellType) => {
    const { hoveredRowIndex } = this.state;

    let classes = [];

    // Add a class depending on if it's a summary, header or normal row.
    switch (cellType) {
      case "Header":
        classes.push("rowHeaderCell");
        break;
      case "Rows":
        classes.push("normalRowCell");
        break;
      case "Summary":
        classes.push("rowSummaryCell");
        break;
      default:
        break;
    }

    classes.push();
    if (rowIndex === hoveredRowIndex) {
      classes.push("hoveredItem");
    }
    if (columnIndex === 0) {
      // If it's in the first column we want to add a class
      classes.push("primaryColumnCell");
    } else {
      classes.push("bodyCell");
    }

    return cn(classes);
  };

  _cellRenderer = ({
    columnIndex,
    isScrolling,
    isVisible,
    parent,
    key,
    rowIndex,
    style
  }) => {
    // Manage the classes added to each cell
    const { visibleRows } = parent.props;
    const { reportType } = this.props;

    // Manage cell data
    let cell;
    let icon = null;
    let row = null;
    let onClick = null;
    let classString = "";
    let indent = null;

    const numHeaderRows = this.getNumHeaderRows();

    if (rowIndex < numHeaderRows) {
      // Table Header that has the group labels
      cell = this.getColumnValue(rowIndex, columnIndex);

      classString = "headerCell";
    } else {
      // Actual row data
      row = visibleRows[rowIndex - numHeaderRows];
      classString = this.getCellClassString(
        columnIndex,
        rowIndex,
        row.cellType
      );
      cell = getCellValue(row, columnIndex);
      ({ icon, indent } = getIconAndIndent(columnIndex, row));
      onClick = event => this.toggleCollapsible(row);
    }

    if (reportType === "productSales") {
      if (columnIndex % 4 === 0 && columnIndex > 0) {
        if (rowIndex <= 1) {
          classString = `${classString} border-right-bold`;
        } else {
          classString = `${classString} border-right-light`;
        }
      }
    }

    return (
      // USE nest level and other things to decide how far to indent (summary are slightly less indented than others)
      <div
        className={classString}
        key={key}
        style={{ ...style, ...indent }}
        onMouseOver={event => this.mouseOver(event, columnIndex, rowIndex)}
        onClick={onClick}
        title={rowIndex === 0 || columnIndex === 0 ? cell : undefined}
      >
        {icon} {cell}
      </div>
    );
  };

  _getColumnWidth = ({ index }) => {
    return index ? 120 : 200;
  };

  getNumHeaderRows = () => {
    const { reportType } = this.props;
    return reportType === "productSales" ? 2 : 1;
  };

  getNumColumns = () => {
    const { reportType } = this.props;
    const { _columns } = this.state;
    return reportType === "productSales" ? _columns[0].length : _columns.length;
  };

  getColumnValue = (rowIndex, columnIndex) => {
    const { reportType } = this.props;
    const { _columns } = this.state;
    return reportType === "productSales"
      ? _columns[rowIndex][columnIndex].name
      : _columns[columnIndex].name;
  };

  toggleCollapsible = row => {
    if (row.cellType === "Header") {
      this.setState(
        prevState => {
          prevState._rows.find(
            x => x.id === row.id
          ).expanded = !prevState._rows.find(x => x.id === row.id).expanded;
          return prevState;
        },
        () => {
          this.myGridRef.forceUpdate();
          this.myGridRef.recomputeGridSize();
        }
      );
    }
  };

  getVisibleRows = () => {
    const { _rows } = this.state;
    const isAnyParentCollapsed = item => {
      if (!item.parentId) {
        return false;
      }
      const parent = _rows.find(x => x.id === item.parentId);
      if (!parent) {
        return false;
      }
      if (!parent.expanded) {
        return true;
      }

      return isAnyParentCollapsed(parent);
    };

    const isVisible = item => {
      return !isAnyParentCollapsed(item);
    };
    return _rows.filter(isVisible);
  };

  toggleAll = () => {
    this.setState(prevState => ({
      expanded: !prevState.expanded,
      _rows: setExpanded(prevState._rows, !prevState.expanded)
    }));
  };

  getTableWidth = (autosizedWidth, numCols) => {
    const { reportWidth } = this.state;

    let fullWidth = 10000; // High number to default to using autosizedWidth
    if (numCols > 1) {
      // Makes this work with react-virtualized grid function
      fullWidth =
        this._getColumnWidth({ index: 0 }) +
        (numCols - 1) * this._getColumnWidth({ index: 1 }) +
        17; // Scrollbar width
    }

    const minWidth = Math.min(autosizedWidth, fullWidth);
    if (reportWidth !== minWidth) {
      this.setState({ reportWidth: minWidth });
    }

    return minWidth;
  };

  render() {
    const { report } = this.props;

    if (!report || _.isEmpty(report)) {
      return null;
    }

    const { hoveredRowIndex, expanded, reportWidth } = this.state;

    const { ReportBasis, Time, Currency } = report.Header;

    const visibleRows = this.getVisibleRows();
    const rowHeight = 30;
    const rowCount = visibleRows.length + this.getNumHeaderRows();
    const height = Math.min(
      document.documentElement.clientHeight * 0.85,
      rowCount * rowHeight + rowHeight
    );

    return (
      <Card style={{ minHeight: "99vh" }}>
        <ReportToolbar
          report={report}
          toggleAll={this.toggleAll}
          expanded={expanded}
        />
        <CardBody>
          <ReportHeader report={report} width={reportWidth} />
          <div style={{ display: "flex" }}>
            <div style={{ flex: "1 1 auto", height }}>
              <AutoSizer>
                {({ width }) => (
                  <MultiGrid
                    width={this.getTableWidth(width, this.getNumColumns())}
                    height={height}
                    ref={c => (this.myGridRef = c)}
                    cellRenderer={this._cellRenderer}
                    columnCount={this.getNumColumns()}
                    columnWidth={this._getColumnWidth}
                    fixedColumnCount={1}
                    fixedRowCount={this.getNumHeaderRows()}
                    estimatedColumnSize={1000}
                    rowCount={rowCount}
                    rowHeight={rowHeight}
                    hoveredRowIndex={hoveredRowIndex}
                    visibleRows={visibleRows}
                  />
                )}
              </AutoSizer>
            </div>
          </div>
          <br />
          <CardSubtitle className="text-center" style={{ width: reportWidth }}>
            <p>{`${ReportBasis} ${moment(Time).format(
              "dddd, MMMM D, YYYY HH:mm A Z"
            )} (${Currency})`}</p>
          </CardSubtitle>
        </CardBody>
      </Card>
    );
  }
}

ProfitAndLossReport.propTypes = {
  report: PropTypes.oneOfType([PropTypes.objectOf(Object), PropTypes.array])
    .isRequired,
  reportType: PropTypes.oneOf(["pnl", "productSales"]).isRequired
};

export default withSpinner(ProfitAndLossReport);
