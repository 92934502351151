import { combineReducers } from "redux";
import { createApiRequestState } from "_state/boilerplateKillers";

const duckName = "profitAndLoss";

export const fetchState = createApiRequestState(duckName, "fetch");

const reducer = combineReducers({
  [fetchState.stateName]: fetchState.reducer
});

export default reducer;
