import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Label, FormGroup } from "reactstrap";
import { Field, Form } from "formik";
import FormStatus from "shared/Formik/FormStatus";
import SimpleSelectField from "shared/Formik/SimpleSelectField";
import ReportPeriodSelect from "shared/Selects/ReportPeriodSelect";
import GroupBySelect from "shared/Selects/GroupBySelect";
import DateRangeField from "shared/Formik/Dates/DateRangeField";

class ReportFormContents extends Component {
  toggle = () => {
    const {
      setFieldValue,
      values: { useDateRange }
    } = this.props;

    setFieldValue("reportPeriod", null);
    setFieldValue("dateRange", {});
    setFieldValue("useDateRange", !useDateRange);
  };

  render() {
    const {
      status,
      isSubmitting,
      values: { useDateRange }
    } = this.props;

    return (
      <Form>
        {useDateRange ? (
          <FormGroup>
            <Label>Date Range: </Label>
            <br />
            <Field
              name="dateRange"
              render={formikProps => <DateRangeField {...formikProps} />}
            />
            <Button onClick={this.toggle} color="link">
              Use report period instead
            </Button>
          </FormGroup>
        ) : (
          <FormGroup>
            <Label>Report Period: </Label>
            <Field
              name="reportPeriod"
              render={formikProps => (
                <SimpleSelectField
                  {...formikProps}
                  SelectComponent={ReportPeriodSelect}
                />
              )}
            />
            <Button onClick={this.toggle} color="link">
              Use date range instead
            </Button>
          </FormGroup>
        )}

        <FormGroup>
          <Label>Group By: </Label>
          <Field
            name="groupBy"
            render={formikProps => (
              <SimpleSelectField
                {...formikProps}
                SelectComponent={GroupBySelect}
              />
            )}
          />
        </FormGroup>
        <Button
          data-testid="submitButton"
          color="primary"
          className="px-4 ml-auto"
          type="submit"
          disabled={isSubmitting}
        >
          Run Report
        </Button>
        <FormStatus status={status} />
      </Form>
    );
  }
}

ReportFormContents.propTypes = {
  status: PropTypes.shape({ msg: PropTypes.string }),
  isSubmitting: PropTypes.bool.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.shape({ useDateRange: PropTypes.bool }).isRequired
};

ReportFormContents.defaultProps = {
  status: null
};

export default ReportFormContents;
