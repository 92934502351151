import { makeApiCall, makeSelectCall } from "_state/api";
import { queryState, createState, updateState, fetchAllState } from "./creator";

const base = "/region";

const queryRegions = makeApiCall("post", `${base}/query`, queryState);

const selectRegions = makeSelectCall(`${base}/query`);

const setRegions = queryState.actions.success;

const onSubmitComplete = dataOrParams => {
  return fetchAllRegions({ companyId: dataOrParams.companyId });
};

const createRegion = makeApiCall(
  "post",
  `${base}/create`,
  createState,
  true,
  onSubmitComplete
);

const updateRegion = makeApiCall(
  "post",
  `${base}/update`,
  updateState,
  true,
  onSubmitComplete
);

const fetchAllQuery = makeApiCall("post", `${base}/query`, fetchAllState);

const fetchAllRegions = ({ companyId }) =>
  fetchAllQuery({
    companyId,
    cursor: null,
    pageSize: 1000,
    direction: 1
  });

export {
  queryRegions,
  setRegions,
  createRegion,
  updateRegion,
  selectRegions,
  fetchAllRegions
};
