import React from "react";
import PropTypes from "prop-types";
import ProductSelectionForm from "shared/Forms/ProductSelectionForm";
import MyModal from "shared/MyModal/MyModal";

const AddNewPlanButton = ({ submit, onSubmitComplete, companyId }) => (
  <React.Fragment>
    <MyModal
      renderButton={toggle => (
        // eslint-disable-next-line
        <a href="javascript:void(0)" color="link" onClick={toggle}>
          here
        </a>
      )}
      renderBody={toggle => (
        <ProductSelectionForm
          toggle={toggle}
          submit={submit}
          onSubmitComplete={onSubmitComplete}
          companyId={companyId}
        />
      )}
    />
  </React.Fragment>
);

AddNewPlanButton.propTypes = {
  companyId: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
  onSubmitComplete: PropTypes.func.isRequired
};

export default AddNewPlanButton;
