import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { isExpired } from "_utils";
import {
  fetchSectors,
  fetchSegments,
  fetchValueStreams,
  fetchCustom1,
  fetchCustom2
} from "_state/ducks/nameValuePair/operations";
import { fetchAllRegions } from "_state/ducks/region/operations";
import { fetchAllSubregions } from "_state/ducks/subregion/operations";
import withSpinner from "shared/withSpinner/withSpinner";
import withError from "shared/withError/withError";
import UserInApp from "./UserInApp";

export class ListDataLoader extends Component {
  componentDidMount() {
    this.loadNameValuePairs();
  }

  componentDidUpdate(prevProps) {
    const { currentCompany } = this.props;
    if (currentCompany.id !== prevProps.currentCompany.id) {
      this.loadNameValuePairs();
    }
  }

  componentWillUnmount() {
    // Clear timers in case user logs out
    clearTimeout(this.timer);
  }

  loadNameValuePairs = () => {
    const {
      currentCompany,
      fetchSectors,
      fetchSegments,
      fetchValueStreams,
      fetchCustom1,
      fetchCustom2,
      fetchAllRegions,
      fetchAllSubregions
    } = this.props;

    const companyId = currentCompany ? currentCompany.id : null;

    if (companyId) {
      if (!isExpired(currentCompany.accessExpiresAt)) {
        fetchSectors({ companyId });
        fetchSegments({ companyId });
        fetchValueStreams({ companyId });
        fetchCustom1({ companyId });
        fetchCustom2({ companyId });
        fetchAllRegions({ companyId });
        fetchAllSubregions({ companyId });

        const duration = 12 * 60 * 1000; // ms

        this.timer = setTimeout(() => {
          this.loadNameValuePairs();
        }, duration);
      }
    }
  };

  render() {
    return <UserInApp {...this.props} />;
  }
}

ListDataLoader.propTypes = {
  currentCompany: PropTypes.objectOf(Object),
  fetchSectors: PropTypes.func.isRequired,
  fetchSegments: PropTypes.func.isRequired,
  fetchValueStreams: PropTypes.func.isRequired,
  fetchCustom1: PropTypes.func.isRequired,
  fetchCustom2: PropTypes.func.isRequired,
  fetchAllRegions: PropTypes.func.isRequired,
  fetchAllSubregions: PropTypes.func.isRequired
};

ListDataLoader.defaultProps = {
  currentCompany: null
};

const mapDispatchToProps = {
  fetchSectors,
  fetchSegments,
  fetchValueStreams,
  fetchCustom1,
  fetchCustom2,
  fetchAllRegions,
  fetchAllSubregions
};

export default withSpinner(
  withError(
    // Needed for routes to change when url changes
    withRouter(
      connect(
        null,
        mapDispatchToProps
      )(ListDataLoader)
    )
  )
);
