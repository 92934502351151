import { makeApiCall } from "_state/api";
import {
  fetchDashboardState,
  queryState,
  createState,
  updateState
} from "./creator";

const base = "/plan";

const fetchDashboard = makeApiCall(
  "post",
  `${base}/dashboard`,
  fetchDashboardState
);

const queryPlans = makeApiCall("post", `${base}/query`, queryState);

const createPlan = makeApiCall("post", `${base}/create`, createState, true);

const updatePlan = makeApiCall("post", `${base}/update`, updateState, true);

export { fetchDashboard, queryPlans, createPlan, updatePlan };
