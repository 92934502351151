import React from "react";
import PropTypes from "prop-types";
import ExportButton from "shared/ExportButton/ExportButton";

const ExportClasses = ({ companyId }) => (
  <ExportButton
    exportParams={["/class/export", { companyId }, "classes.xlsx"]}
  />
);

ExportClasses.propTypes = {
  companyId: PropTypes.string.isRequired
};

export default ExportClasses;
