import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import { authUserType } from "_types";
import { authUser } from "_state/ducks/session/selectors";

// Redirects to path if condition on currentUser fails
const withRedirect = (condition, to) => InputComponent => {
  const WithRedirect = ({ authUser, ...rest }) => {
    if (condition(authUser)) {
      return <InputComponent {...rest} />;
    }
    return <Redirect data-testid="redirect" to={to} />;
  };

  WithRedirect.propTypes = { authUser: authUserType };

  WithRedirect.defaultProps = {
    authUser: null
  };

  return WithRedirect;
};

const mapStateToProps = makeMapStateToProps({
  authUser
});

// This lets us use it like this "export default withAuthorization(condition, to)(MyCoolComponent)"
// example to redirect if not authed: "const condition = authUser => !!authUser;""
const withAuthorization = (condition, to) => InputComponent => {
  return connect(mapStateToProps)(withRedirect(condition, to)(InputComponent));
};

const isAuthed = authUser => !!authUser;

export { isAuthed, withRedirect };

export default withAuthorization;
