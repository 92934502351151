import PropTypes from "prop-types";
import apiErrorType from "./apiErrorType";

const apiRequestType = PropTypes.shape({
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  loading: PropTypes.bool,
  error: apiErrorType
});

export default apiRequestType;
