import { makeApiCall, makeSelectCall } from "_state/api";
import { queryState, createState, updateState } from "./creator";

const base = "/labour-account";

const queryLabourAccounts = makeApiCall("post", `${base}/query`, queryState);

const selectLabourAccounts = makeSelectCall(`${base}/query`);

const setLabourAccounts = queryState.actions.success;

const createLabourAccount = makeApiCall(
  "post",
  `${base}/create`,
  createState,
  true
);

const updateLabourAccount = makeApiCall(
  "post",
  `${base}/update`,
  updateState,
  true
);

export {
  queryLabourAccounts,
  setLabourAccounts,
  createLabourAccount,
  updateLabourAccount,
  selectLabourAccounts
};
