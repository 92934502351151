import React from "react";
import PropTypes from "prop-types";
import { Badge, Popover, PopoverBody } from "reactstrap";
import { getNextId } from "_utils";

class InfoPopover extends React.Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      isOpen: false,
      id: getNextId("InfoPopover")
    };
  }

  toggle = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  render() {
    const { children } = this.props;
    const { isOpen, id } = this.state;

    return (
      <React.Fragment>
        <Badge
          pill
          id={id}
          href=""
          color="secondary"
          style={{ cursor: "pointer" }}
        >
          ?
        </Badge>
        <Popover
          trigger="hover"
          isOpen={isOpen}
          target={id}
          toggle={this.toggle}
        >
          <PopoverBody>{children}</PopoverBody>
        </Popover>
      </React.Fragment>
    );
  }
}

InfoPopover.propTypes = {
  children: PropTypes.node.isRequired
};

export default InfoPopover;
