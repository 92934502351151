import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Firebase, { withFirebase } from "_auth/Firebase";
import { setAuthUser } from "_state/ducks/session/operations";
import api from "_state/api";
import App from "./App";

class AppContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      authTimeout: null
    };
  }

  componentDidMount() {
    const { firebase, setAuthUser } = this.props;
    // Need to set this listener in AppContainer because firebase doesn't have access to redux
    this.listener = firebase.addAuthListener(authUser => {
      if (authUser) {
        // User signed in
        // show spinner instead of login screen while we fetch token
        this.setLoadingTrue(); // Using a callback so it acts synchronously

        // Set the user, fetch and set the token
        this.setAuthTokenAndRefresh(authUser, setAuthUser);
      } else {
        // User logged out
        delete api.defaults.headers.common["Authorization"];
        setAuthUser(null);
        this.clearAuthTimeout();
        this.setLoadingFalse();
      }
    });
  }

  componentWillUnmount() {
    this.listener();
  }

  setAuthTokenAndRefresh = authUser => {
    const { setAuthUser } = this.props;

    authUser.getIdToken(true).then(idToken => {
      console.debug(`Auth state change: setting token for ${authUser.email}`);
      api.defaults.headers.common["Authorization"] = `Bearer ${idToken}`;

      setAuthUser(authUser); // Must be after token set so we can check current user before using api functions
      this.setLoadingFalse();

      const time = 58 * 60 * 1000; // ms
      const timeout = setTimeout(() => {
        this.setAuthTokenAndRefresh(authUser, setAuthUser);
      }, time);

      this.setAuthTimeout(timeout);
    });
  };

  setLoadingTrue = () => {
    this.setState(() => ({ loading: true }));
  };

  setLoadingFalse = () => {
    this.setState(() => ({ loading: false }));
  };

  setAuthTimeout = authTimeout => {
    this.setState(() => ({ authTimeout }));
  };

  clearAuthTimeout = () => {
    const { authTimeout } = this.state;
    clearTimeout(authTimeout);
  };

  render() {
    const { loading } = this.state;
    return <App loading={loading} />;
  }
}

AppContainer.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired,
  setAuthUser: PropTypes.func.isRequired
};

const mapDispatchToProps = {
  setAuthUser
};

export default withFirebase(
  connect(
    null,
    mapDispatchToProps
  )(AppContainer)
);
