import "react-app-polyfill/ie9"; // For IE 9-11 support
import "react-app-polyfill/ie11"; // For IE 11 support
import "./polyfill";
import "./index.css";
import "react-virtualized/styles.css";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import configureStore from "./_state/configureStore";
import AppContainer from "./App/AppContainer";
import Firebase, { FirebaseContext } from "_auth/Firebase";

const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <FirebaseContext.Provider value={new Firebase()}>
      <AppContainer />
    </FirebaseContext.Provider>
  </Provider>,
  document.getElementById("root")
);
