import { makeApiCall } from "_state/api";
import { fetchState } from "./creator";

const fetchPnl = makeApiCall(
  "post",
  "/profit-and-loss/fetch",
  fetchState,
  true
);

export { fetchPnl };
