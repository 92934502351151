import React from "react";
import InfoPopover from "shared/InfoPopover/InfoPopover";

const ExportInfoBlock = () => (
  <InfoPopover>
    Clicking on the <strong>"Export to Excel"</strong> button will create an
    excel sheet with all of your data, along with validation that lets you tag
    your data in bulk.
    <br />
    <br />
    After you're finished making changes in the Excel file, you can upload it by
    clicking on <strong>"Import from Excel"</strong>. This will apply your
    changes to our database.
  </InfoPopover>
);

export default ExportInfoBlock;
