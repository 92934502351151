import { combineReducers } from "redux";
import { createApiRequestState } from "_state/boilerplateKillers";

const duckName = "company";

export const myCompaniesState = createApiRequestState(duckName, "myCompanies");

export const switchCompanyState = createApiRequestState(
  duckName,
  "switchCompany"
);

export const syncCompanyState = createApiRequestState(duckName, "syncCompany");

export const companySettingsState = createApiRequestState(
  duckName,
  "companySettings"
);

export const updateCompanySettingsState = createApiRequestState(
  duckName,
  "updateCompanySettings"
);

export const disconnectFromQboState = createApiRequestState(
  duckName,
  "disconnectFromQbo"
);

const reducer = combineReducers({
  [myCompaniesState.stateName]: myCompaniesState.reducer,
  [switchCompanyState.stateName]: switchCompanyState.reducer,
  [syncCompanyState.stateName]: syncCompanyState.reducer,
  [companySettingsState.stateName]: companySettingsState.reducer,
  [updateCompanySettingsState.stateName]: updateCompanySettingsState.reducer,
  [disconnectFromQboState.stateName]: disconnectFromQboState.reducer
});

export default reducer;
