import moment from "moment";

const getMonthNow = () => {
  const local = moment();
  const month = moment.utc(
    `${local.year()}-${local.month() + 1}-1`,
    "YYYY-M-D"
  );

  return month.format();
};

export default getMonthNow;
