import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Col, Container, Row, Alert } from "reactstrap";
import { Formik, Field, Form } from "formik";
import { string, object } from "yup";
import { AppNavbarBrand } from "@coreui/react";
import FormStatus from "shared/Formik/FormStatus";

import logo from "assets/img/brand/logo.png";

import publicRoutes from "_constants/publicRoutes";
import IconField from "shared/Formik/IconField";

const schema = object().shape({
  email: string()
    .email("Invalid email")
    .required("Email is required")
});

const ForgotPasswordForm = ({ status, isSubmitting }) => (
  <Form>
    <AppNavbarBrand
      full={{
        src: logo,
        width: 155,
        height: 45,
        alt: "P3A Logo"
      }}
    />
    <h1>Password Reset</h1>
    <p className="text-muted">
      Please enter your email address. We will send you an email to reset your
      password.
    </p>
    <Field
      name="email"
      render={formikProps => <IconField {...formikProps} type="email" />}
    />
    <Button
      data-testid="submitButton"
      color="primary"
      className="px-4 ml-auto"
      type="submit"
      disabled={isSubmitting}
    >
      Send Email
    </Button>
    <FormStatus status={status} />
  </Form>
);

ForgotPasswordForm.propTypes = {
  status: PropTypes.shape({ msg: PropTypes.string }).isRequired,
  isSubmitting: PropTypes.bool.isRequired
};

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailWasSent: false
    };
  }

  render() {
    const { sendPasswordResetEmail } = this.props;
    const { emailWasSent } = this.state;

    return (
      <div className="app flex-row align-items-center">
        <Container>
          <Row className="justify-content-center">
            <Col sm="8" md="6" lg="5" xl="4">
              <Card>
                <CardBody className="p-4">
                  {emailWasSent ? (
                    <Alert color="success">
                      <h4 className="alert-heading">
                        A password reset email has been sent!
                      </h4>
                      <p>
                        Please{" "}
                        <Link
                          className="alert-link"
                          to={publicRoutes.login.path}
                        >
                          login to the app
                        </Link>
                        , after resetting your password.
                      </p>
                      <hr />
                      <p>
                        Please check you inbox (Spam folder included) for the
                        reset email.
                      </p>
                      <hr />
                    </Alert>
                  ) : (
                    <React.Fragment>
                      <Formik
                        initialValues={{ email: "" }}
                        onSubmit={({ email }, actions) => {
                          sendPasswordResetEmail(email)
                            .then(() => {
                              this.setState({ emailWasSent: true });
                            })
                            .catch(error => {
                              this.setState({
                                emailWasSent: true
                              });
                              // Dont show error to user so a script can't see who has registered in the app
                              // actions.setSubmitting(false);
                              // actions.setStatus({ msg: error.message });
                            });
                        }}
                        isInitialValid={false}
                        validationSchema={schema}
                        render={ForgotPasswordForm}
                      />
                      <hr />
                      <Link to={publicRoutes.login.path}>Back to Login</Link>
                    </React.Fragment>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

ForgotPassword.propTypes = {
  sendPasswordResetEmail: PropTypes.func.isRequired
};

export default ForgotPassword;
