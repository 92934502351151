import React from "react";
import { Card, CardBody } from "reactstrap";
import { apiRequestType } from "_types";
import { isExpired } from "_utils";
import withSpinner from "shared/withSpinner/withSpinner";

const PaymentInformationPage = ({ startupData }) => (
  <Card>
    <CardBody>
      {isExpired(startupData.data.currentCompany.accessExpiresAt)
        ? "Your subscription has expired, please renew it."
        : "Your subscription is valid"}
    </CardBody>
  </Card>
);

PaymentInformationPage.propTypes = {
  startupData: apiRequestType.isRequired
};

export default withSpinner(PaymentInformationPage);
