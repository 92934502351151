import React from "react";
import PropTypes from "prop-types";
import { ModalHeader, ModalBody, FormGroup, Label } from "reactstrap";
import { Formik, Field, Form } from "formik";
import { object, mixed } from "yup";
import ProductField from "shared/Formik/ProductField";
import FormStatus from "shared/Formik/FormStatus";
import FormButtons from "./FormButtons";

const schema = object().shape({
  product: mixed().test("required", "Product is required", function(value) {
    return !!value;
  })
});

const ProductSelectionForm = ({
  submit,
  toggle,
  onSubmitComplete,
  companyId
}) => (
  <Formik
    initialValues={{ product: null }}
    onSubmit={(entity, actions) => {
      submit(entity)
        .then(() => {
          onSubmitComplete();
        })
        .catch(error => {
          actions.setSubmitting(false);
          actions.setStatus({ msg: error.message });
        });
    }}
    isInitialValid={false}
    validationSchema={schema}
    render={({ status, isSubmitting }) => (
      <Form>
        <ModalHeader>
          Select a product/service you would like to track against a plan.
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Product/Service: </Label>
            <Field
              name="product"
              render={formikProps => (
                <ProductField companyId={companyId} {...formikProps} />
              )}
            />
          </FormGroup>
          <FormStatus status={status} />
        </ModalBody>
        <FormButtons toggle={toggle} isSubmitting={isSubmitting} />
      </Form>
    )}
  />
);

ProductSelectionForm.propTypes = {
  submit: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  onSubmitComplete: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired
};

export default ProductSelectionForm;
