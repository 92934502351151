import LoginContainer from "App/Login/LoginContainer";
import RegisterContainer from "App/Register/RegisterContainer";
import ForgotPasswordContainer from "App/ForgotPassword/ForgotPasswordContainer";
import Page404 from "App/Page404/Page404";
import Page500 from "App/Page500/Page500";
import withAuthorization from "shared/withAuthorization/withAuthorization";
import privateRoutes from "./privateRoutes";

const condition = authUser => !authUser;

const publicRoutes = {
  error404: { path: "/404", exact: true, name: "Page 404", component: Page404 },
  error500: { path: "/500", exact: true, name: "Page 500", component: Page500 },
  login: {
    path: "/login",
    exact: true,
    name: "Login Page",
    component: withAuthorization(condition, privateRoutes.dashboard.path)(
      LoginContainer
    )
  },
  register: {
    path: "/register",
    exact: true,
    name: "Register Page",
    component: withAuthorization(condition, privateRoutes.dashboard.path)(
      RegisterContainer
    )
  },
  forgotPassword: {
    path: "/forgot-password",
    exact: true,
    name: "Forgot Password",
    component: withAuthorization(condition, privateRoutes.dashboard.path)(
      ForgotPasswordContainer
    )
  }
};

export default publicRoutes;
