import { combineReducers } from "redux";
import { createApiRequestState } from "_state/boilerplateKillers";

const duckName = "subregion";

export const queryState = createApiRequestState(duckName, "query");

export const createState = createApiRequestState(duckName, "create");

export const updateState = createApiRequestState(duckName, "update");

export const fetchAllState = createApiRequestState(duckName, "fetchAll");

const reducer = combineReducers({
  [queryState.stateName]: queryState.reducer,
  [createState.stateName]: createState.reducer,
  [updateState.stateName]: updateState.reducer,
  [fetchAllState.stateName]: fetchAllState.reducer
});

export default reducer;
