import { makeApiCall } from "_state/api";
import { fetchState } from "./creator";

const fetchProductSales = makeApiCall(
  "post",
  "/product-sales/fetch",
  fetchState,
  true
);

export { fetchProductSales };
