import React from "react";
import PropTypes from "prop-types";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem
} from "reactstrap";
import {
  AppHeaderDropdown,
  AppNavbarBrand,
  AppSidebarToggler
} from "@coreui/react";
import SyncDisplayContainer from "./SyncDisplay/SyncDisplayContainer";

import logo from "assets/img/brand/logo.png";
import avatar from "assets/img/avatar.png";
import squareLogo from "assets/img/brand/square-logo.svg";

const Header = ({ signOut }) => (
  <React.Fragment>
    <AppSidebarToggler className="d-lg-none" display="md" mobile />
    <AppNavbarBrand
      full={{
        src: logo,
        width: 155,
        height: 45,
        alt: "P3A Logo"
      }}
      minimized={{
        src: squareLogo,
        width: 30,
        height: 30,
        alt: "P3A Logo"
      }}
    />
    <AppSidebarToggler className="d-md-down-none" display="lg" />
    <Nav className="ml-auto" navbar>
      <NavItem>
        <SyncDisplayContainer />
      </NavItem>
      <AppHeaderDropdown direction="down">
        <DropdownToggle nav>
          <img src={avatar} className="img-avatar" alt="avatar" />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem header tag="div" className="text-center">
            <strong> Account </strong>
          </DropdownItem>
          <DropdownItem
            onClick={() => {
              signOut();
            }}
          >
            <i className="fa fa-lock" /> Logout
          </DropdownItem>
        </DropdownMenu>
      </AppHeaderDropdown>
    </Nav>
  </React.Fragment>
);

Header.propTypes = {
  signOut: PropTypes.func.isRequired
};

export default Header;
