import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import { apiRequestType } from "_types";
import { startupData } from "_state/ducks/session/selectors";
import { syncCompanyObj } from "_state/ducks/company/selectors";
import { syncCompany } from "_state/ducks/company/operations";
import SyncDisplay from "./SyncDisplay";

class SyncDisplayContainer extends Component {
  componentDidMount() {
    const { syncCompany, startupData } = this.props;
    if (startupData.data.currentCompany) {
      syncCompany({ companyId: startupData.data.currentCompany.id });
    }
  }

  render() {
    const { syncCompanyObj, startupData } = this.props;
    if (!startupData.data.currentCompany) {
      return null;
    }
    return (
      <SyncDisplay
        syncCompanyObj={syncCompanyObj}
        companyId={startupData.data.currentCompany.id}
      />
    );
  }
}

SyncDisplayContainer.propTypes = {
  syncCompanyObj: apiRequestType.isRequired,
  syncCompany: PropTypes.func.isRequired,
  startupData: apiRequestType.isRequired
};

const mapStateToProps = makeMapStateToProps({
  syncCompanyObj,
  startupData
});

const mapDispatchToProps = {
  syncCompany
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SyncDisplayContainer);
