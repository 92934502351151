import React, { Component } from "react";
import PropTypes from "prop-types";
import AsyncPaginate from "react-select-async-paginate";

class AsyncSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cursors: {},
      error: null,
      stateVal: null
    };
  }

  loadOptions = (searchString, prevOptions) => {
    const {
      companyId,
      callApiForOptions,
      labelProperty,
      onClickAddNew,
      filterProperty
    } = this.props;
    const { cursors } = this.state;

    const cursor = cursors[searchString] ? cursors[searchString] : null;

    return new Promise((resolve, reject) => {
      callApiForOptions({
        companyId,
        property: filterProperty,
        searchString: searchString.toLowerCase(),
        cursor
      })
        .then(resp => {
          const { entities, nextPageCursor } = resp.data;

          this.setState(prevState => ({
            cursors: {
              ...prevState.cursors,
              [searchString]: nextPageCursor
            }
          }));

          // Prepend "Add New" to options if we have a onClickAddNew handler
          if (onClickAddNew) {
            const index = prevOptions.findIndex(item => item.id === -1);
            if (index === -1) {
              entities.unshift({ [labelProperty]: "+ Add New", id: -1 });
            }
          }

          resolve({
            options: entities,
            hasMore: !!nextPageCursor
          });
        })
        .catch(error => {
          this.setState({
            error
          });
        });
    });
  };

  getOptionLabel = option => {
    const { labelProperty } = this.props;
    return option[labelProperty];
  };

  getOptionValue = option => option;

  getLoadingMessage = () => {
    const { error } = this.state;
    return error ? `Error: ${error.message}` : "Loading...";
  };

  onChange = option => {
    const { onChange, onClickAddNew } = this.props;

    if (option && option.id === -1) {
      onClickAddNew();
    } else {
      this.setState({
        stateVal: option
      });

      onChange(option);
    }
  };

  render() {
    const { disabled, value, invalid, isClearable, isMulti } = this.props;
    const { stateVal } = this.state;

    return (
      <AsyncPaginate
        getOptionLabel={this.getOptionLabel}
        getOptionValue={this.getOptionValue}
        cacheOptions
        debounceTimeout={300}
        loadOptions={this.loadOptions}
        menuPortalTarget={document.body}
        isDisabled={disabled}
        loadingMessage={this.getLoadingMessage}
        onChange={this.onChange}
        value={value ? value : stateVal}
        isClearable={isClearable}
        classNamePrefix="react-select"
        className={invalid && "is-invalid"}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        isMulti={isMulti}
        backspaceRemovesValue={false}
      />
    );
  }
}

AsyncSelect.propTypes = {
  companyId: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  callApiForOptions: PropTypes.func.isRequired,
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  labelProperty: PropTypes.string.isRequired,
  filterProperty: PropTypes.string.isRequired,
  invalid: PropTypes.bool,
  isClearable: PropTypes.bool,
  onClickAddNew: PropTypes.func,
  isMulti: PropTypes.bool
};

AsyncSelect.defaultProps = {
  disabled: false,
  value: null,
  invalid: false,
  isClearable: false,
  onClickAddNew: null,
  isMulti: false
};

export default AsyncSelect;
