// Redux builder for a simple state with a setter, updater and selector
import {
  createReducer,
  makeActionCreator,
  getTypePrefix,
  makeApiSelector
} from "./baseHelpers";

const initialState = null;

const getApiTypes = (duckName, stateName) => {
  const typePrefix = getTypePrefix(duckName, stateName);

  return {
    set: `${typePrefix}SET`,
    update: `${typePrefix}UPDATE`,
    reset: `${typePrefix}RESET`
  };
};

const makeApiActionCreators = types => ({
  set: makeActionCreator(types.set, "payload"),
  update: makeActionCreator(types.update, "payload"),
  reset: makeActionCreator(types.reset)
});

const createApiReducer = types =>
  createReducer(initialState, {
    [types.set]: (state, action) => {
      if (action.payload === null) {
        return null;
      }
      return action.payload;
    },
    [types.update]: (state, action) => ({
      ...state,
      ...action.payload
    }),
    [types.reset]: (state, action) => initialState
  });

export const createState = (duckName, stateName) => {
  const types = getApiTypes(duckName, stateName);
  const actions = makeApiActionCreators(types);
  const reducer = createApiReducer(types);
  const selector = makeApiSelector(duckName, stateName);
  return {
    actions,
    reducer,
    selector,
    stateName
  };
};
