import React from "react";
import PropTypes from "prop-types";
import { apiRequestType } from "_types";
import Report from "shared/Report/Report";
import ReportForm from "shared/Forms/ReportForm/ReportForm";

const PnlPage = ({ fetchPnl, fetchedPnl, companyId }) => (
  <div>
    <ReportForm companyId={companyId} fetch={fetchPnl} />
    <Report
      report={fetchedPnl.data}
      loading={fetchedPnl.loading}
      reportType="pnl"
    />
  </div>
);

PnlPage.propTypes = {
  fetchedPnl: apiRequestType.isRequired,
  fetchPnl: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired
};

export default PnlPage;
