import React from "react";
import { Card, CardBody } from "reactstrap";

const Help = () => (
  <Card>
    <CardBody>
      Please visit{" "}
      <a href="https://www.p3a.com" target="_blank" rel="noopener noreferrer">
        p3a.com
      </a>{" "}
      for support and documentation.
    </CardBody>
  </Card>
);

export default Help;
