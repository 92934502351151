import React, { Component } from "react";
import PropTypes from "prop-types";
import Header from "./Header";
import Firebase, { withFirebase } from "_auth/Firebase";

class HeaderContainer extends Component {
  componentDidMount() {}

  render() {
    const { firebase } = this.props;
    return <Header signOut={() => firebase.doSignOut()} />;
  }
}

HeaderContainer.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired
};

export default withFirebase(HeaderContainer);
