// Takes an object of selectors and passes back a mapStateToProps function

const makeMapStateToProps = selectors => state => {
  // Replace each selector in the input object with a call selector(state)
  return Object.assign(
    ...Object.entries(selectors).map(([key, selector]) => ({
      [key]: selector(state)
    }))
  );
};

export default makeMapStateToProps;
