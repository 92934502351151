import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import privateRoutes from "_constants/privateRoutes";

// Redirects to my companies if user has no companies
const needsCompanies = InputComponent => {
  const NeedsCompanies = ({ noCompanies, ...rest }) => {
    if (noCompanies) {
      return <Redirect to={privateRoutes.myCompanies.path} />;
    }
    return <InputComponent {...rest} />;
  };

  NeedsCompanies.propTypes = { noCompanies: PropTypes.bool.isRequired };

  return NeedsCompanies;
};

export default needsCompanies;
