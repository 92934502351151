import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle, Table } from "reactstrap";
import { format } from "_utils";
import withError from "shared/withError/withError";
import PlanHelpPopover from "./PlanHelpPopover";
import PlanSummaryButtons from "./PlanSummaryButtons";
import "./PlanSummary.css";

const toDollarString = input => format.toDollarString(input, true, 0);

const isNullOrUndefined = input => input === null || input === undefined;

const getDisplayStrings = (row, deltaFormat) => {
  return {
    ytdPlan: toDollarString(row.ytdPlan),
    ytdPlanOfRevenue:
      !isNullOrUndefined(row.ytdPlanOfRevenue) &&
      `(${format.toPercentString(row.ytdPlanOfRevenue, 0, false)})`,
    ytd: toDollarString(row.ytd),
    ytdOfRevenue:
      !isNullOrUndefined(row.ytdOfRevenue) &&
      `(${format.toPercentString(row.ytdOfRevenue, 0, false)})`,
    ytdDelta:
      deltaFormat === "dollars"
        ? toDollarString(row.ytdDelta)
        : format.toPercentString(row.ytdDelta, 0, false),
    mtdPlan: toDollarString(row.mtdPlan),
    mtdPlanOfRevenue:
      !isNullOrUndefined(row.mtdPlanOfRevenue) &&
      `(${format.toPercentString(row.mtdPlanOfRevenue, 0, false)})`,
    mtd: toDollarString(row.mtd),
    mtdOfRevenue:
      !isNullOrUndefined(row.mtdOfRevenue) &&
      `(${format.toPercentString(row.mtdOfRevenue, 0, false)})`,
    mtdDelta:
      deltaFormat === "dollars"
        ? toDollarString(row.mtdDelta)
        : format.toPercentString(row.mtdDelta, 0, false)
  };
};

const doNothing = () => {};

const getColor = isGood => (isGood ? "table-success" : "table-danger");

// eslint-disable-next-line react/prop-types
const PlanSummaryRow = ({ title, positiveDeltaIsRed, deltaFormat, row }) => {
  const ytdColor = positiveDeltaIsRed
    ? getColor(row.ytdDelta <= 0)
    : getColor(row.ytdDelta >= 0);
  const mtdColor = positiveDeltaIsRed
    ? getColor(row.mtdDelta <= 0)
    : getColor(row.mtdDelta >= 0);

  const display = getDisplayStrings(row, deltaFormat);

  return (
    <tr>
      <th scope="row">{title}</th>
      <td className={`${mtdColor} pr-0`}>{display.mtd}</td>
      <td className={`${mtdColor} pl-1 text-left`}>{display.mtdOfRevenue}</td>
      <td className={`${mtdColor} pr-0`}>{display.mtdPlan}</td>
      <td className={`${mtdColor} pl-1 text-left`}>
        {display.mtdPlanOfRevenue}
      </td>
      <td className={mtdColor}>{display.mtdDelta}</td>
      <td className={`${ytdColor} pr-0`}>{display.ytd}</td>
      <td className={`${ytdColor} pl-1 text-left`}>{display.ytdOfRevenue}</td>
      <td className={`${ytdColor} pr-0`}>{display.ytdPlan}</td>
      <td className={`${ytdColor} pl-1 text-left`}>
        {display.ytdPlanOfRevenue}
      </td>
      <td className={ytdColor}>{display.ytdDelta}</td>
    </tr>
  );
};

PlanSummaryRow.defaultProps = {
  positiveDeltaIsRed: false
};

// eslint-disable-next-line react/prop-types
const LerRow = ({ row }) => (
  <tr>
    <th scope="row">LER</th>
    <td>{row.mtdLer}</td>
    <td />
    <td />
    <td />
    <td />
    <td>{row.ytdLer}</td>
    <td />
    <td />
    <td />
    <td />
  </tr>
);

const PlanSummary = ({
  plan,
  companyId,
  isDashboard,
  restorePlan,
  deletePlan,
  fetchPlans
}) => {
  let title = "";
  let showDeleteButton = true;
  if (isDashboard) {
    title = "Entire Organization";
  } else if (plan.product) {
    title = plan.product.FullyQualifiedName;
  } else if (plan.id === "everythingElse") {
    title = "Everything Else";
    showDeleteButton = false;
  } else if (plan.id === "entireOrganization") {
    title = "Entire Organization";
    showDeleteButton = false;
  }

  return (
    <Card>
      <CardBody>
        <CardTitle>
          <h4 className="d-inline-block">{title}</h4>
          <div className="float-right">
            <PlanHelpPopover />
          </div>
        </CardTitle>
        <Table hover borderless responsive size="sm" className="planTable">
          <thead>
            <tr>
              <th />
              <th className="text-center" colSpan="5">
                <u>Last Month</u>
              </th>
              <th className="text-center" colSpan="5">
                <u>YTD</u>
              </th>
            </tr>
            <tr className="text-left">
              <th />
              <th colSpan="2">Actual</th>
              <th colSpan="2">Plan</th>
              <th>Plan Delta</th>
              <th colSpan="2">Actual</th>
              <th colSpan="2">Plan</th>
              <th>Delta</th>
            </tr>
          </thead>
          <tbody>
            <PlanSummaryRow
              title="Revenue"
              row={plan.summary.revenue}
              deltaFormat="dollars"
            />
            <PlanSummaryRow
              title="COGS"
              row={plan.summary.cogs}
              positiveDeltaIsRed
              deltaFormat="percent"
            />
            <PlanSummaryRow
              title="Direct Labour"
              row={plan.summary.directLabour}
              positiveDeltaIsRed
              deltaFormat="percent"
            />
            <PlanSummaryRow
              title="Gross Profit"
              row={plan.summary.grossProfit}
              deltaFormat="dollars"
            />
            <LerRow row={plan.summary.ler} />
          </tbody>
        </Table>
        <PlanSummaryButtons
          isDashboard={isDashboard}
          companyId={companyId}
          planId={plan.id}
          active={plan.active}
          showDeleteButton={showDeleteButton}
          deletePlan={deletePlan}
          restorePlan={restorePlan}
          fetchPlans={fetchPlans}
          planTitle={title}
        />
      </CardBody>
    </Card>
  );
};

PlanSummary.propTypes = {
  plan: PropTypes.objectOf(Object),
  companyId: PropTypes.string.isRequired,
  isDashboard: PropTypes.bool,
  restorePlan: PropTypes.func,
  deletePlan: PropTypes.func,
  fetchPlans: PropTypes.func
};

const emptyPlan = {
  ytdPlan: 0,
  ytd: 0,
  ytdDelta: 0,
  mtdPlan: 0,
  mtd: 0,
  mtdDelta: 0
};

PlanSummary.defaultProps = {
  plan: {
    revenue: emptyPlan,
    cogs: emptyPlan,
    directLabour: emptyPlan,
    grossProfit: emptyPlan
  },
  isDashboard: false,
  restorePlan: doNothing,
  deletePlan: doNothing,
  fetchPlans: doNothing
};

export default withError(PlanSummary);
