import React, { Component } from "react";
import PropTypes from "prop-types";
import { DateRangePicker } from "react-dates";
import ValidationMessage from "../ValidationMessage";
import isInvalid from "../isInvalid";
import MonthElement from "./MonthElement";

const returnFalse = () => false;

class DateRangeField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null
    };
  }

  render() {
    const {
      field,
      form: { touched, errors, isSubmitting, setFieldValue }
    } = this.props;

    const { focusedInput } = this.state;

    const invalid = isInvalid(touched, errors, field);
    return (
      <React.Fragment>
        <div className={invalid ? "is-invalid" : undefined}>
          <DateRangePicker
            startDate={field.value.startDate}
            startDateId="startDate"
            endDate={field.value.endDate}
            endDateId="endDate"
            onDatesChange={({ startDate, endDate }) =>
              setFieldValue(field.name, { startDate, endDate })
            }
            isOutsideRange={returnFalse}
            focusedInput={focusedInput}
            onFocusChange={focusedInput => this.setState({ focusedInput })}
            disabled={isSubmitting}
            renderMonthElement={MonthElement}
          />
        </div>

        <ValidationMessage name={field.name} invalid={invalid} />
      </React.Fragment>
    );
  }
}

DateRangeField.propTypes = {
  field: PropTypes.objectOf(Object).isRequired,
  form: PropTypes.objectOf(Object).isRequired
};

export default DateRangeField;
