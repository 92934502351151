import React, { Component } from "react";
import PropTypes from "prop-types";
import hoverImg from "./img/C2QB_green_btn_lg_hover.png";
import defaultImg from "./img/C2QB_green_btn_lg_default.png";
import styles from "./QboButton.module.scss";
import withError from "../withError/withError";
import withSpinner from "../withSpinner/withSpinner";

class QboButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hovered: false
    };
  }

  hoverOff = () => {
    this.setState({
      hovered: false
    });
  };

  hoverOn = () => {
    this.setState({
      hovered: true
    });
  };

  onClick = () => {
    const { handleClick } = this.props;
    handleClick();
  };

  render() {
    const { hovered } = this.state;

    return (
      <img
        className={`float-right ${styles.qboButton}`}
        alt="Connect to Quickbooks"
        onMouseOver={this.hoverOn}
        onMouseOut={this.hoverOff}
        onClick={this.onClick}
        src={hovered ? hoverImg : defaultImg}
        data-testid="qboButton"
      />
    );
  }
}

QboButton.propTypes = {
  handleClick: PropTypes.func.isRequired
};

export default withError(withSpinner(QboButton));
