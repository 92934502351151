import _ from "lodash";

const toDollarString = (input, showDollarSign = true, decimalPlaces = 2) => {
  const currencyFormatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  });

  let output = currencyFormatter.format(input);
  if (showDollarSign) {
    return output;
  }
  return _.replace(output, "$", "");
};

const toPercentString = (
  input,
  decimalPlaces = 1,
  convertToDecimalFirst = true
) => {
  const percentFormatter = new Intl.NumberFormat("en-US", {
    style: "percent",
    minimumFractionDigits: decimalPlaces,
    maximumFractionDigits: decimalPlaces
  });

  let num;
  if (!_.isFinite(input)) {
    num = parseFloat(input);
  } else {
    num = input;
  }

  if (convertToDecimalFirst) {
    num = num / 100;
  }

  return percentFormatter.format(num);
};

export default {
  toPercentString,
  toDollarString
};
