import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Input } from "reactstrap";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import { apiRequestType, routerTypes } from "_types";
import { startupData } from "_state/ducks/session/selectors";
import { queriedRegions, updateRegionObj } from "_state/ducks/region/selectors";
import {
  queryRegions,
  createRegion,
  updateRegion,
  setRegions
} from "_state/ducks/region/operations";
import NameForm from "shared/Forms/NameForm";
import RegionSelect from "shared/Selects/RegionSelect";
import EditTable from "shared/SmartTable/EditTable";

const RegionsContainer = ({
  match,
  queryRegions,
  queriedRegions,
  updateRegionObj,
  updateRegion,
  setRegions,
  createRegion,
  startupData
}) => (
  <EditTable
    readOnly={
      startupData.data.currentCompany.myRole !== "level1" &&
      startupData.data.currentCompany.myRole !== "level2"
    }
    defaultSortId="lowercaseName"
    match={match}
    query={queryRegions}
    queriedEntities={queriedRegions}
    updateObj={updateRegionObj}
    update={updateRegion}
    set={setRegions}
    create={createRegion}
    FormComponent={NameForm}
    columns={[
      {
        Header: "Name",
        accessor: "name",
        MyCellComponent: Input,
        myCellPassedProps: { type: "text" },
        MyFilterComponent: RegionSelect
      }
    ]}
  />
);

RegionsContainer.propTypes = {
  queriedRegions: apiRequestType.isRequired,
  queryRegions: PropTypes.func.isRequired,
  createRegion: PropTypes.func.isRequired,
  updateRegionObj: apiRequestType.isRequired,
  updateRegion: PropTypes.func.isRequired,
  setRegions: PropTypes.func.isRequired,
  startupData: apiRequestType.isRequired,
  match: routerTypes.match.isRequired
};

const mapStateToProps = makeMapStateToProps({
  queriedRegions,
  updateRegionObj,
  startupData
});

const mapDispatchToProps = {
  queryRegions,
  createRegion,
  updateRegion,
  setRegions
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegionsContainer);
