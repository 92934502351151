import { makeApiCall } from "_state/api";
import { queryState, createState, updateState } from "./creator";

const base = "/target";

const queryTargets = makeApiCall("post", `${base}/query`, queryState);

const setTargets = queryState.actions.success;

const createTarget = makeApiCall("post", `${base}/create`, createState, true);

const updateTarget = makeApiCall("post", `${base}/update`, updateState, true);

export { queryTargets, setTargets, createTarget, updateTarget };
