import React from "react";
import { connect } from "react-redux";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import { apiRequestType } from "_types";
import { fetchedSubregions } from "_state/ducks/subregion/selectors";
import MySelect from "./MySelect";

const SubregionSelect = ({ fetchedSubregions, ...rest }) => (
  <MySelect
    options={fetchedSubregions.data.entities}
    error={fetchedSubregions.error}
    {...rest}
  />
);

SubregionSelect.propTypes = {
  fetchedSubregions: apiRequestType.isRequired
};

const mapStateToProps = makeMapStateToProps({
  fetchedSubregions
});

export default connect(
  mapStateToProps,
  null
)(SubregionSelect);
