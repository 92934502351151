import React from "react";
import { selectCustomers } from "_state/ducks/customer/operations";
import AsyncSelect from "./AsyncSelect";

const CustomerSelect = props => (
  <AsyncSelect
    callApiForOptions={selectCustomers}
    labelProperty="FullyQualifiedName"
    filterProperty="FullyQualifiedName"
    {...props}
  />
);

export default CustomerSelect;
