import ActiveFilter from "./ActiveFilter";
import ActiveSelect from "./ActiveSelect";

const getActiveString = entity => {
  if (entity.active || entity.Active) {
    return "Active";
  }
  return "Inactive";
};

const getActiveColumn = ({ Cell = undefined, isQbo = false }) => ({
  // Qbo uses upper case variables, we use lowercase
  Header: "Active",
  id: isQbo ? "Active" : "active",
  accessor: d => getActiveString(d),
  Filter: ActiveFilter,
  Cell,
  MyCellComponent: ActiveSelect,
  sortable: false
});

export default getActiveColumn;
