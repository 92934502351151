import React from "react";
import PropTypes from "prop-types";
import { CardHeader, Button } from "reactstrap";
import { componentType } from "_types";
import MyModal from "shared/MyModal/MyModal";

const TableHeader = ({
  pluralName,
  singularName,
  create,
  onSubmitComplete,
  FormComponent,
  companyId,
  readOnly,
  initialFormValues
}) => (
  <CardHeader>
    {pluralName}
    {!readOnly && (
      <MyModal
        renderButton={toggle => (
          <Button color="primary" className="float-right" onClick={toggle}>
            <i className="fa fa-plus" /> {singularName}
          </Button>
        )}
        renderBody={toggle => (
          <FormComponent
            toggle={toggle}
            singularName={singularName}
            submit={create}
            onSubmitComplete={onSubmitComplete}
            companyId={companyId}
            initialFormValues={initialFormValues}
          />
        )}
      />
    )}
  </CardHeader>
);

TableHeader.propTypes = {
  pluralName: PropTypes.string.isRequired,
  singularName: PropTypes.string.isRequired,
  create: PropTypes.func.isRequired,
  onSubmitComplete: PropTypes.func.isRequired,
  FormComponent: PropTypes.oneOfType([componentType]),
  companyId: PropTypes.string,
  readOnly: PropTypes.bool,
  initialFormValues: PropTypes.objectOf(Object)
};

TableHeader.defaultProps = {
  companyId: null,
  readOnly: false,
  FormComponent: null,
  initialFormValues: undefined
};

export default TableHeader;
