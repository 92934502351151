import React from "react";
import { selectAccounts } from "_state/ducks/account/operations";
import AsyncSelect from "./AsyncSelect";

const AccountSelect = props => (
  <AsyncSelect
    callApiForOptions={selectAccounts}
    labelProperty="FullyQualifiedName"
    filterProperty="FullyQualifiedName"
    {...props}
  />
);

export default AccountSelect;
