import React from "react";
import PropTypes from "prop-types";
import { ModalBody, ModalHeader, FormGroup, Label } from "reactstrap";
import { Formik, Form, Field } from "formik";
import { object, mixed } from "yup";
import FormStatus from "shared/Formik/FormStatus";
import UploadFileField from "shared/Formik/UploadFileField";
import FormButtons from "./FormButtons";

const schema = object().shape({
  files: mixed().required("An import file is required.")
});

const ImportForm = ({ submit, toggle, onSubmitComplete }) => (
  <Formik
    validationSchema={schema}
    initialValues={{ files: "" }}
    onSubmit={(data, actions) => {
      submit(data.files[0])
        .then(resp => {
          onSubmitComplete();
          actions.setSubmitting(false);
          actions.setStatus({});
          toggle();
        })
        .catch(error => {
          actions.setSubmitting(false);
          actions.setStatus({ msg: error.message });
        });
    }}
    isInitialValid
    render={({ status, isSubmitting }) => (
      <Form>
        <ModalHeader>Import Excel File</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>File: </Label>
            <Field
              name="files"
              render={formikProps => <UploadFileField {...formikProps} />}
            />
          </FormGroup>
          <FormStatus status={status} />
        </ModalBody>
        <FormButtons
          toggle={toggle}
          isSubmitting={isSubmitting}
          buttonText="Import"
        />
      </Form>
    )}
  />
);

ImportForm.propTypes = {
  submit: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  onSubmitComplete: PropTypes.func.isRequired
};

export default ImportForm;
