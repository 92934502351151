import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import BlankForm from "shared/Forms/BlankForm";
import MyModal from "shared/MyModal/MyModal";

const DeletePlanButton = ({ submit, onSubmitComplete }) => (
  <React.Fragment>
    <MyModal
      renderButton={toggle => (
        <Button className="float-right" color="danger" onClick={toggle}>
          <i className="fas fa-times" /> Delete
        </Button>
      )}
      renderBody={toggle => (
        <BlankForm
          toggle={toggle}
          title={`Are you sure you want to delete this plan?`}
          submit={submit}
          onSubmitComplete={onSubmitComplete}
          buttonText="Delete"
          buttonColor="danger"
        />
      )}
    />
  </React.Fragment>
);

DeletePlanButton.propTypes = {
  submit: PropTypes.func.isRequired,
  onSubmitComplete: PropTypes.func.isRequired
};

export default DeletePlanButton;
