import React from "react";
import PropTypes from "prop-types";
import { ModalHeader, ModalBody, FormGroup, Label } from "reactstrap";
import { Formik, Field, Form } from "formik";
import { object, number, date } from "yup";
import { getMonthNow } from "_utils";
import InputField from "shared/Formik/InputField";
import PercentageField from "shared/Formik/PercentageField";
import MonthField from "shared/Formik/Dates/MonthField";
import FormStatus from "shared/Formik/FormStatus";
import FormButtons from "./FormButtons";

const monthRequired = "Month is required";

const schema = object().shape({
  month: date()
    .required(monthRequired)
    .typeError(monthRequired),
  revenue: number().required("Revenue is required"),
  cogs: number()
    .required("COGS is required")
    .min(0.0)
    .max(100),
  directLabour: number()
    .required("directLabour is required")
    .min(0.0)
    .max(100)
});

const MonthlyTargetForm = ({
  submit,
  toggle,
  singularName,
  onSubmitComplete,
  initialFormValues
}) => (
  <Formik
    initialValues={
      initialFormValues
        ? initialFormValues
        : {
            month: getMonthNow(),
            revenue: 0,
            cogs: 0.0,
            directLabour: 0.0
          }
    }
    onSubmit={(entity, actions) => {
      submit(entity)
        .then(resp => {
          onSubmitComplete(resp.data);
        })
        .catch(error => {
          actions.setSubmitting(false);
          actions.setStatus({ msg: error.message });
        });
    }}
    isInitialValid={false}
    validationSchema={schema}
    render={({ status, isSubmitting }) => (
      <Form>
        <ModalHeader>{singularName}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Month: </Label>
            <br />
            <Field
              name="month"
              render={formikProps => <MonthField {...formikProps} />}
            />
          </FormGroup>
          <FormGroup>
            <Label>Revenue: </Label>
            <Field
              name="revenue"
              render={formikProps => (
                <InputField {...formikProps} type="number" />
              )}
            />
          </FormGroup>
          <FormGroup>
            <Label>COGS: </Label>
            <Field
              name="cogs"
              render={formikProps => <PercentageField {...formikProps} />}
            />
          </FormGroup>
          <FormGroup>
            <Label>Direct Labour: </Label>
            <Field
              name="directLabour"
              render={formikProps => <PercentageField {...formikProps} />}
            />
          </FormGroup>
          <FormStatus status={status} />
        </ModalBody>
        <FormButtons toggle={toggle} isSubmitting={isSubmitting} />
      </Form>
    )}
  />
);

MonthlyTargetForm.propTypes = {
  singularName: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  onSubmitComplete: PropTypes.func.isRequired,
  initialFormValues: PropTypes.objectOf(Object)
};

MonthlyTargetForm.defaultProps = {
  initialFormValues: undefined
};

export default MonthlyTargetForm;
