import React from "react";
import PropTypes from "prop-types";
import {
  CardBody,
  CardText,
  ListGroup,
  ListGroupItem,
  Row,
  Col
} from "reactstrap";
import { getNextId } from "_utils";

const CardTable = ({ rows, columnWidth }) => (
  <CardBody>
    <CardText tag="div">
      <ListGroup>
        {rows.map(item => (
          <ListGroupItem key={getNextId("CardTableRow")}>
            <Row>
              <Col xs={columnWidth}>{item.name}</Col>
              <Col xs={12 - columnWidth}>{item.value}</Col>
            </Row>
          </ListGroupItem>
        ))}
      </ListGroup>
    </CardText>
  </CardBody>
);

CardTable.propTypes = {
  rows: PropTypes.arrayOf(PropTypes.object).isRequired,
  columnWidth: PropTypes.number
};

CardTable.defaultProps = {
  columnWidth: 5
};

export default CardTable;
