import React from "react";
import PropTypes from "prop-types";
import { ModalHeader, ModalBody, FormGroup, Label } from "reactstrap";
import { Formik, Field, Form } from "formik";
import { string, object } from "yup";
import InputField from "shared/Formik/InputField";
import FormStatus from "shared/Formik/FormStatus";
import FormButtons from "./FormButtons";

const schema = object().shape({
  code: string().required("An invite code is required")
});

// TODO: Abstract all these form modals so we only have to set the fields...
const InviteCodeModal = ({
  submit,
  toggle,
  title,
  onSubmitComplete,
  buttonText
}) => (
  <Formik
    initialValues={{ code: "" }}
    onSubmit={(entity, actions) => {
      submit(entity)
        .then(resp => {
          onSubmitComplete(resp.data);
        })
        .catch(error => {
          actions.setSubmitting(false);
          actions.setStatus({ msg: error.message });
        });
    }}
    isInitialValid={false}
    validationSchema={schema}
    render={({ status, isSubmitting }) => (
      <Form>
        <ModalHeader>{title}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Invitation Code: </Label>
            <Field
              name="code"
              render={formikProps => (
                <InputField {...formikProps} autoComplete="off" />
              )}
            />
          </FormGroup>
          <FormStatus status={status} />
        </ModalBody>
        <FormButtons
          toggle={toggle}
          isSubmitting={isSubmitting}
          buttonText={buttonText}
        />
      </Form>
    )}
  />
);

InviteCodeModal.propTypes = {
  title: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  onSubmitComplete: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired
};

export default InviteCodeModal;
