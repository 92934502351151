import React from "react";
import ManageUsersContainer from "./ManageUsersContainer";
import PendingInvitesContainer from "./PendingInvitesContainer";

const ManageUsersPage = props => (
  <div>
    <ManageUsersContainer {...props} />
    <PendingInvitesContainer {...props} />
  </div>
);

export default ManageUsersPage;
