import { combineReducers } from "redux";
import { createApiRequestState } from "_state/boilerplateKillers";

const duckName = "product";

const query = "query";
export const queryState = createApiRequestState(duckName, query);

const reducer = combineReducers({
  [queryState.stateName]: queryState.reducer
});

export default reducer;
