import React from "react";
import PropTypes from "prop-types";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert,
  Button
} from "reactstrap";
import { apiErrorType } from "_types";

const ErrorModal = ({ error, onConfirm }) => {
  if (error) {
    return (
      <Modal isOpen={!!error} backdrop="static">
        <ModalHeader>Error</ModalHeader>
        <ModalBody>
          <Alert color="danger">
            {error.data ? error.data : error.message}
          </Alert>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={onConfirm}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  }

  return null;
};

ErrorModal.propTypes = {
  error: apiErrorType,
  onConfirm: PropTypes.func.isRequired
};

ErrorModal.defaultProps = {
  error: null
};

export default ErrorModal;
