import React from "react";
import PropTypes from "prop-types";
import { Modal } from "reactstrap";

class MyModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
  }

  toggle = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  render() {
    const { renderBody, renderButton } = this.props;
    const { isOpen } = this.state;

    return (
      <React.Fragment>
        {renderButton(this.toggle)}
        <Modal isOpen={isOpen} toggle={this.toggle} backdrop="static">
          {renderBody(this.toggle)}
        </Modal>
      </React.Fragment>
    );
  }
}

MyModal.propTypes = {
  renderBody: PropTypes.func.isRequired,
  renderButton: PropTypes.func.isRequired
};

export default MyModal;
