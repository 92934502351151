import React from "react";
import {
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText
} from "reactstrap";
import { connect } from "react-redux";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import { apiRequestType } from "_types";
import { startupData } from "_state/ducks/session/selectors";
import InfoPopover from "shared/InfoPopover/InfoPopover";

const RoleDescriptions = ({ startupData }) => (
  <InfoPopover>
    <ListGroup flush>
      {startupData.data.roles.map(role => (
        <ListGroupItem key={role.name}>
          <ListGroupItemHeading>{role.name}</ListGroupItemHeading>
          <ListGroupItemText>{role.description}</ListGroupItemText>
        </ListGroupItem>
      ))}
    </ListGroup>
  </InfoPopover>
);

RoleDescriptions.propTypes = {
  startupData: apiRequestType.isRequired
};

const mapStateToProps = makeMapStateToProps({
  startupData
});

export default connect(
  mapStateToProps,
  null
)(RoleDescriptions);
