import React, { Component } from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import { apiErrorType } from "_types";

class MySelect extends Component {
  getOptionLabel = option => {
    const { labelProperty } = this.props;
    return option[labelProperty];
  };

  getOptionValue = option => option;

  getNoOptionsMessage = () => {
    const { error } = this.props;

    return error ? `Error: ${error.message}` : "No options";
  };

  onChange = option => {
    const { onChange, onClickAddNew } = this.props;

    if (option && option.id === -1) {
      onClickAddNew();
    } else {
      onChange(option);
    }
  };

  render() {
    const {
      disabled,
      value,
      isClearable,
      options,
      onClickAddNew,
      labelProperty
    } = this.props;

    const newOptions = options.slice();
    if (onClickAddNew) {
      newOptions.unshift({ [labelProperty]: "+ Add New", id: -1 });
    }

    return (
      <Select
        getOptionLabel={this.getOptionLabel}
        getOptionValue={this.getOptionValue}
        value={value}
        onChange={this.onChange}
        options={newOptions}
        menuPortalTarget={document.body}
        isDisabled={disabled}
        isClearable={isClearable}
        styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
        noOptionsMessage={this.getNoOptionsMessage}
        backspaceRemovesValue={false}
      />
    );
  }
}

MySelect.propTypes = {
  value: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  options: PropTypes.oneOfType([PropTypes.array]),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isClearable: PropTypes.bool,
  onClickAddNew: PropTypes.func,
  labelProperty: PropTypes.string,
  error: apiErrorType
};

MySelect.defaultProps = {
  disabled: false,
  value: undefined,
  isClearable: true,
  onClickAddNew: null,
  labelProperty: "name",
  options: [],
  error: null
};

export default MySelect;
