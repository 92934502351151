import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import { apiRequestType, routerTypes } from "_types";
import { fetchedProductSales } from "_state/ducks/productSales/selectors";
import { fetchProductSales } from "_state/ducks/productSales/operations";
import ProductSales from "./ProductSales";

class ProductSalesContainer extends Component {
  componentDidMount() {}

  render() {
    const { fetchedProductSales, fetchProductSales, match } = this.props;
    return (
      <ProductSales
        fetchedProductSales={fetchedProductSales}
        fetchProductSales={fetchProductSales}
        companyId={match.params.companyId}
      />
    );
  }
}

ProductSalesContainer.propTypes = {
  fetchedProductSales: apiRequestType.isRequired,
  fetchProductSales: PropTypes.func.isRequired,
  match: routerTypes.match.isRequired
};

const mapStateToProps = makeMapStateToProps({
  fetchedProductSales
});

const mapDispatchToProps = {
  fetchProductSales
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductSalesContainer);
