import PropTypes from "prop-types";

// TODO: update this for koa error shape
export const apiErrorType = PropTypes.shape({
  data: PropTypes.string,
  status: PropTypes.number,
  statusText: PropTypes.string,
  headers: PropTypes.object,
  config: PropTypes.object,
  request: PropTypes.object,
  message: PropTypes.string
});

export default apiErrorType;
