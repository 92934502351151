import React from "react";
import PropTypes from "prop-types";
import { apiRequestType } from "_types";
import withSpinner from "shared/withSpinner/withSpinner";
import withError from "shared/withError/withError";
import "../Plans/PlanSummary.css";

import PlanSummary from "../Plans/PlanSummary";
import ForceRefreshButton from "shared/ForceRefreshButton/ForceRefreshButton";

const Dashboard = ({ email, companyId, dashboard, forceRefreshDashboard }) => (
  <div>
    <PlanSummary
      error={dashboard.error}
      plan={dashboard.data.plans[0]}
      companyId={companyId}
      isDashboard
    />
    <ForceRefreshButton
      doRefresh={forceRefreshDashboard}
      calculatedAt={dashboard.data.calculatedAt}
    />
  </div>
);

Dashboard.propTypes = {
  companyId: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  dashboard: apiRequestType.isRequired,
  forceRefreshDashboard: PropTypes.func.isRequired
};

export default withSpinner(withError(Dashboard));
