import React from "react";
import PropTypes from "prop-types";
import { Modal } from "reactstrap";
import MySelect from "./MySelect";

class SelectWithAddNew extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      key: 1
    };
  }

  refreshSelect = () => {
    this.setState(prevState => ({
      key: prevState.key + 1,
      isOpen: false
    }));
  };

  toggle = () => {
    this.setState(prevState => ({
      isOpen: !prevState.isOpen
    }));
  };

  render() {
    const { renderForm, ...rest } = this.props;
    const { isOpen, key } = this.state;

    return (
      <React.Fragment key={key}>
        <Modal isOpen={isOpen} toggle={this.toggle} backdrop="static">
          {renderForm(this.toggle, this.refreshSelect)}
        </Modal>
        <MySelect
          {...rest}
          onClickAddNew={() => {
            this.toggle();
          }}
        />
      </React.Fragment>
    );
  }
}

SelectWithAddNew.propTypes = {
  renderForm: PropTypes.func.isRequired
};

export default SelectWithAddNew;
