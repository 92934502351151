import { makeApiCall } from "_state/api";
import {
  queryState,
  createState,
  resendState,
  deleteState,
  submitCodeState
} from "./creator";

const base = "/invite";

const queryInvites = makeApiCall("post", `${base}/query`, queryState);

const createInvite = makeApiCall("post", `${base}/create`, createState, true);

const resendInvite = makeApiCall("post", `${base}/resend`, resendState, true);

const deleteInvite = makeApiCall("post", `${base}/delete`, deleteState, true);

const submitCode = makeApiCall(
  "post",
  `${base}/submit-code`,
  submitCodeState,
  true
);

export { queryInvites, createInvite, resendInvite, deleteInvite, submitCode };
