import React from "react";
import { Route, Link } from "react-router-dom";
import { Breadcrumb, BreadcrumbItem } from "reactstrap";
import PropTypes from "prop-types";
import classNames from "classnames";
import { componentType } from "_types";
import getRouteFromUrl from "_constants/getRouteFromUrl";

const BreadcrumbsItem = ({ match }) => {
  const route = getRouteFromUrl(match.url);
  const routeName = route.name ? route.name : null;

  if (routeName) {
    return match.isExact ? (
      <BreadcrumbItem active> {routeName} </BreadcrumbItem>
    ) : (
      <BreadcrumbItem>
        <Link to={match.url || ""}> {routeName} </Link>{" "}
      </BreadcrumbItem>
    );
  }
  return null;
};

BreadcrumbsItem.defaultProps = {
  match: {
    url: ""
  }
};

BreadcrumbsItem.propTypes = {
  match: PropTypes.shape({
    url: PropTypes.string
  })
};

const Breadcrumbs = args => {
  const paths = [args.location.pathname];
  const items = paths.map((path, i) => (
    <Route key={i.toString()} path={path} component={BreadcrumbsItem} />
  ));
  return (
    <Breadcrumb>
      {items}

      {args.companyName && (
        <div className="ml-auto">
          Connected to:{" "}
          <span className="font-weight-bold">{args.companyName}</span>
        </div>
      )}
    </Breadcrumb>
  );
};

const TitleBar = ({ className, tag: Tag, companyName, ...attributes }) => {
  delete attributes.appRoutes;

  const classes = classNames(className);

  return (
    <Tag className={classes}>
      <Route
        path="/:path"
        render={routeProps => (
          <Breadcrumbs {...routeProps} companyName={companyName} />
        )}
        {...attributes}
      />
    </Tag>
  );
};

TitleBar.propTypes = {
  className: PropTypes.string,
  appRoutes: PropTypes.objectOf(
    PropTypes.shape({
      path: PropTypes.string,
      exact: PropTypes.bool,
      name: PropTypes.string,
      component: componentType
    })
  ),
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  companyName: PropTypes.string
};
TitleBar.defaultProps = {
  companyName: "",
  tag: "div",
  className: "",
  appRoutes: [
    {
      path: "/",
      exact: true,
      name: "Home",
      component: null
    }
  ]
};

export default TitleBar;
