import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import BlankForm from "shared/Forms/BlankForm";
import MyModal from "shared/MyModal/MyModal";

const RestorePlanButton = ({ submit, onSubmitComplete }) => (
  <React.Fragment>
    <MyModal
      renderButton={toggle => (
        <Button className="float-right" color="success" onClick={toggle}>
          Restore
        </Button>
      )}
      renderBody={toggle => (
        <BlankForm
          toggle={toggle}
          title={`Are you sure you want to restore this plan?`}
          submit={submit}
          onSubmitComplete={onSubmitComplete}
          buttonText="Restore"
          buttonColor="success"
        />
      )}
    />
  </React.Fragment>
);

RestorePlanButton.propTypes = {
  submit: PropTypes.func.isRequired,
  onSubmitComplete: PropTypes.func.isRequired
};

export default RestorePlanButton;
