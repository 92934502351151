import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardText,
  CardFooter,
  Button,
  ListGroup,
  ListGroupItem,
  Row,
  Col
} from "reactstrap";
import { routerTypes } from "_types";
import { isExpired } from "_utils";
import privateRoutes from "_constants/privateRoutes";
import ConnectionStatus from "shared/ConnectionStatus/ConnectionStatus";

const CompanyCard = ({ company, currentCompanyId, switchCompany, history }) => {
  const switchButton = (
    <Button
      color="primary"
      className="btn-block"
      onClick={() => {
        switchCompany(company.id);
      }}
    >
      Switch
    </Button>
  );

  const currentCompany = (
    <Button color="primary" className="btn-block" disabled>
      Currently Using This Company
    </Button>
  );

  const columnWidth = 5;

  return (
    <Card className="cardInDeck">
      <CardHeader>{company.name}</CardHeader>
      <CardBody>
        <CardTitle>
          {currentCompanyId === company.id ? currentCompany : switchButton}
        </CardTitle>
        <CardText tag="div">
          <ListGroup>
            <ListGroupItem>
              <Row>
                <Col xs={columnWidth}>Id:</Col>
                <Col xs={12 - columnWidth}>{company.id}</Col>
              </Row>
            </ListGroupItem>
            <ListGroupItem>
              <Row>
                <Col xs={columnWidth}>Company #:</Col>
                <Col xs={12 - columnWidth}>{company.realmId}</Col>
              </Row>
            </ListGroupItem>
            <ListGroupItem>
              <Row>
                <Col xs={columnWidth}>QBO Link:</Col>
                <Col xs={12 - columnWidth}>
                  <ConnectionStatus
                    connected={company.qboLink}
                    companyId={company.id}
                  />
                </Col>
              </Row>
            </ListGroupItem>
            <ListGroupItem>
              <Row>
                <Col xs={columnWidth}>Subscription:</Col>
                <Col xs={12 - columnWidth}>
                  {isExpired(company.accessExpiresAt) ? "Inactive" : "Active"}
                </Col>
              </Row>
            </ListGroupItem>
          </ListGroup>
        </CardText>
      </CardBody>
      {company.myRole === "level1" && (
        <CardFooter>
          <Button
            color="primary"
            disabled={isExpired(company.accessExpiresAt)}
            onClick={() => {
              switchCompany(company.id).then(() => {
                history.push(privateRoutes.manageUsers.getUrl(company.id));
              });
            }}
          >
            Manage Users
          </Button>
          <Button
            color="primary"
            disabled={isExpired(company.accessExpiresAt)}
            className="float-right"
            onClick={() => {
              switchCompany(company.id).then(() => {
                history.push(privateRoutes.companySettings.getUrl(company.id));
              });
            }}
          >
            Settings
          </Button>
        </CardFooter>
      )}
    </Card>
  );
};

CompanyCard.propTypes = {
  company: PropTypes.shape({
    name: PropTypes.string,
    externalId: PropTypes.string,
    qboLink: PropTypes.bool
  }).isRequired,
  currentCompanyId: PropTypes.string.isRequired,
  switchCompany: PropTypes.func.isRequired,
  history: routerTypes.history.isRequired
};

export default CompanyCard;
