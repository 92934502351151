import React from "react";
import PropTypes from "prop-types";
import { Button, ModalFooter } from "reactstrap";

const FormButtons = ({ toggle, isSubmitting, buttonText, buttonColor }) => (
  <ModalFooter>
    <Button
      className="mr-auto"
      type="button"
      color="secondary"
      onClick={toggle}
      disabled={isSubmitting}
    >
      Cancel
    </Button>
    <Button
      data-testid="submitButton"
      color={buttonColor}
      className="px-4"
      type="submit"
      disabled={isSubmitting}
    >
      {isSubmitting && (
        <span
          className="spinner-border spinner-border-sm"
          role="status"
          aria-hidden="true"
        />
      )}{" "}
      {buttonText}
    </Button>
  </ModalFooter>
);

FormButtons.propTypes = {
  toggle: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  buttonText: PropTypes.string,
  buttonColor: PropTypes.oneOf(["primary", "danger", "success"])
};

FormButtons.defaultProps = {
  buttonText: "Save",
  buttonColor: "primary"
};

export default FormButtons;
