import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Redirect, Route, Switch } from "react-router-dom";
import { Container, UncontrolledAlert } from "reactstrap";
import {
  AppFooter,
  AppHeader,
  AppSidebar,
  AppSidebarFooter,
  AppSidebarForm,
  AppSidebarHeader,
  AppSidebarMinimizer,
  AppSidebarNav
} from "@coreui/react";
import { Link } from "react-router-dom";
import withError from "shared/withError/withError";
import TitleBar from "./TitleBar/TitleBar";
import Footer from "./Footer/Footer";
import HeaderContainer from "./Header/HeaderContainer";
import { getNextId, isExpired } from "_utils";

// sidebar nav config
import getNav from "_constants/_nav";
// routes config
import privateRoutes from "_constants/privateRoutes";

// Pass location to AppSidebarNav by using withRouter HOC
const SidebarNavWithLocation = withRouter(AppSidebarNav);

export const UserInApp = ({ currentCompany }) => {
  const companyId = currentCompany ? currentCompany.id : null;
  const companyName = currentCompany ? currentCompany.name : "";
  const noCompanies = currentCompany === null;
  const subscriptionExpired = currentCompany
    ? isExpired(currentCompany.accessExpiresAt)
    : false;

  if (currentCompany) {
    privateRoutes.custom1.name = currentCompany.custom1Name;
    privateRoutes.custom2.name = currentCompany.custom2Name;
  }

  return (
    <div data-testid="UserInApp" className="app">
      <AppHeader fixed>
        <HeaderContainer />
      </AppHeader>
      <div className="app-body">
        <AppSidebar fixed display="lg">
          <AppSidebarHeader />
          <AppSidebarForm />
          <SidebarNavWithLocation
            navConfig={getNav(
              noCompanies,
              companyId,
              currentCompany ? currentCompany.enableCustomFields : false,
              subscriptionExpired
            )}
          />
          <AppSidebarFooter />
          <AppSidebarMinimizer />
        </AppSidebar>
        <main className="main" data-testid="main">
          <TitleBar appRoutes={privateRoutes} companyName={companyName} />
          {subscriptionExpired && (
            <UncontrolledAlert color="danger">
              Your subscription has expired, visit the{" "}
              <Link
                className="alert-link"
                to={privateRoutes.payment.getUrl(currentCompany.id)}
              >
                payment information page{" "}
              </Link>
              to renew.
            </UncontrolledAlert>
          )}
          <Container fluid className="px-0 px-sm-4">
            <Switch>
              {Object.values(privateRoutes).map(route => {
                return route.component ? (
                  <Route
                    key={getNextId("privateRoutes")}
                    path={route.path}
                    exact={route.exact}
                    name={route.name}
                    render={props => (
                      <route.component
                        {...props}
                        {...route.passedProps}
                        noCompanies={noCompanies}
                        companyId={companyId}
                        subscriptionExpired={subscriptionExpired}
                      />
                    )}
                  />
                ) : null;
              })}
              <Redirect from="/" to={privateRoutes.dashboard.path} />
            </Switch>
          </Container>
        </main>
      </div>
      <AppFooter>
        <Footer />
      </AppFooter>
    </div>
  );
};

UserInApp.propTypes = {
  currentCompany: PropTypes.objectOf(Object)
};

UserInApp.defaultProps = {
  currentCompany: null
};

export default withError(UserInApp);
