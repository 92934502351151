import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Spinner, UncontrolledTooltip } from "reactstrap";
import { apiRequestType } from "_types";
import privateRoutes from "_constants/privateRoutes";

const SyncDisplay = ({ syncCompanyObj, companyId }) => {
  const { loading, error, data } = syncCompanyObj;
  let tooltipMessage = "";
  let icon = null;

  if (loading) {
    tooltipMessage = "Syncing items...";
    icon = <Spinner color="primary" />;
  } else if (error) {
    tooltipMessage = "There was a problem syncing.";
    icon = <i className="fas fa-exclamation-circle fa-2x text-danger" />;
  } else {
    const date = new Date(data.lastUpdated);
    tooltipMessage = `Synced as of ${date.toString()}`;
    icon = <i className="fas fa-check-circle fa-2x text-success" />;
  }

  return (
    <div>
      <Link
        className="nav-link"
        data-testid="syncDisplay"
        id="syncDisplay"
        to={privateRoutes.classes.getUrl(companyId)}
      >
        {icon}
      </Link>
      <UncontrolledTooltip target="syncDisplay">
        {tooltipMessage}
      </UncontrolledTooltip>
    </div>
  );
};

SyncDisplay.propTypes = {
  syncCompanyObj: apiRequestType.isRequired,
  companyId: PropTypes.string.isRequired
};

export default SyncDisplay;
