import React from "react";
import PropTypes from "prop-types";
import { Redirect } from "react-router-dom";
import privateRoutes from "_constants/privateRoutes";
import needsCompanies from "../needsCompanies/needsCompanies";

// Redirects to payment page if user has no active subscription
const needsSubscription = InputComponent => {
  const NeedsSubscription = ({ subscriptionExpired, companyId, ...rest }) => {
    if (subscriptionExpired) {
      return <Redirect to={privateRoutes.payment.getUrl(companyId)} />;
    }

    return <InputComponent {...rest} />;
  };

  NeedsSubscription.propTypes = {
    subscriptionExpired: PropTypes.bool.isRequired,
    companyId: PropTypes.string
  };

  NeedsSubscription.defaultProps = {
    companyId: null
  };

  return needsCompanies(NeedsSubscription);
};

export default needsSubscription;
