import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { FormFeedback } from "reactstrap";
import { ErrorMessage } from "formik";

const ValidationMessage = ({ name, invalid }) => (
  <React.Fragment>
    <ErrorMessage
      name={name}
      render={msg => {
        let display = null;

        if (_.isString(msg)) {
          display = msg;
        } else if (_.isObject(msg)) {
          display = Object.values(msg);
        }

        return <FormFeedback className="d-block">{display}</FormFeedback>;
      }}
      data-testid="errorMessage"
    />
    {!invalid && <div className="invalid-feedback d-block">&nbsp;</div>}
  </React.Fragment>
);

ValidationMessage.propTypes = {
  name: PropTypes.string.isRequired,
  invalid: PropTypes.bool.isRequired
};

export default ValidationMessage;
