import React from "react";
import _ from "lodash";
import { connect } from "react-redux";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import { apiRequestType } from "_types";
import { startupData } from "_state/ducks/session/selectors";
import { switchCompanyObj } from "_state/ducks/company/selectors";
import { fetchStartupData } from "_state/ducks/session/operations";
import PaymentInformationPage from "./PaymentInformationPage";

const PaymentPageContainer = ({ startupData }) => (
  <PaymentInformationPage
    loading={startupData.loading || _.isEmpty(startupData.data)}
    error={startupData.error}
    startupData={startupData}
  />
);

PaymentPageContainer.propTypes = {
  startupData: apiRequestType.isRequired
};

const mapStateToProps = makeMapStateToProps({
  startupData,
  switchCompanyObj
});

const mapDispatchToProps = {
  fetchStartupData
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentPageContainer);
