import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button } from "reactstrap";
import "./PlanSummary.css";

import privateRoutes from "_constants/privateRoutes";
import DeletePlanButton from "./DeletePlanButton";
import RestorePlanButton from "./RestorePlanButton";

const PlanSummaryButtons = ({
  isDashboard,
  companyId,
  planId,
  deletePlan,
  restorePlan,
  fetchPlans,
  active,
  showDeleteButton,
  planTitle
}) => {
  if (isDashboard) {
    return (
      <div>
        <Link to={privateRoutes.plans.getUrl(companyId)}>
          <Button>View Products</Button>
        </Link>
        <Link
          to={privateRoutes.labourAccounts.getUrl(companyId)}
          className="float-right"
        >
          <Button>Choose Labour Accounts</Button>
        </Link>
      </div>
    );
  }

  return (
    <div>
      <Link to={privateRoutes.editPlan.getUrl(companyId, planId, planTitle)}>
        <Button>Edit Plan</Button>
      </Link>
      {showDeleteButton &&
        (active ? (
          <DeletePlanButton submit={deletePlan} onSubmitComplete={fetchPlans} />
        ) : (
          <RestorePlanButton
            submit={restorePlan}
            onSubmitComplete={fetchPlans}
          />
        ))}
    </div>
  );
};

PlanSummaryButtons.propTypes = {
  isDashboard: PropTypes.bool.isRequired,
  companyId: PropTypes.string.isRequired,
  planId: PropTypes.string,
  deletePlan: PropTypes.func.isRequired,
  restorePlan: PropTypes.func.isRequired,
  fetchPlans: PropTypes.func.isRequired,
  active: PropTypes.bool,
  showDeleteButton: PropTypes.bool.isRequired,
  planTitle: PropTypes.string.isRequired
};

PlanSummaryButtons.defaultProps = {
  planId: null,
  active: true
};

export default PlanSummaryButtons;
