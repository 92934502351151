import React from "react";
import { connect } from "react-redux";
import { nameValuePairType } from "_types";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import { apiRequestType } from "_types";
import {
  fetchedSectors,
  fetchedSegments,
  fetchedValueStreams,
  fetchedCustom1,
  fetchedCustom2
} from "_state/ducks/nameValuePair/selectors";
import MySelect from "./MySelect";

const getObject = apiRequestData => ({
  options: apiRequestData ? apiRequestData.data.entities : [],
  error: apiRequestData ? apiRequestData.error : null
});

class NameValuePairSelect extends React.Component {
  getOptions = () => {
    const {
      type,
      fetchedSectors,
      fetchedSegments,
      fetchedValueStreams,
      fetchedCustom1,
      fetchedCustom2
    } = this.props;

    switch (type) {
      case "sector":
        return getObject(fetchedSectors);
      case "segment":
        return getObject(fetchedSegments);
      case "valueStream":
        return getObject(fetchedValueStreams);
      case "custom1":
        return getObject(fetchedCustom1);
      case "custom2":
        return getObject(fetchedCustom2);
      default:
        return getObject(null);
    }
  };

  render() {
    const {
      type,
      fetchedSectors,
      fetchedSegments,
      fetchedValueStreams,
      fetchedCustom1,
      fetchedCustom2,
      ...rest
    } = this.props;

    const optionsAndErrors = this.getOptions();

    return <MySelect {...optionsAndErrors} {...rest} />;
  }
}

NameValuePairSelect.propTypes = {
  type: nameValuePairType.isRequired,
  fetchedSectors: apiRequestType.isRequired,
  fetchedSegments: apiRequestType.isRequired,
  fetchedValueStreams: apiRequestType.isRequired,
  fetchedCustom1: apiRequestType.isRequired,
  fetchedCustom2: apiRequestType.isRequired
};

const mapStateToProps = makeMapStateToProps({
  fetchedSectors,
  fetchedSegments,
  fetchedValueStreams,
  fetchedCustom1,
  fetchedCustom2
});

export default connect(
  mapStateToProps,
  null
)(NameValuePairSelect);
