import React from "react";
import PropTypes from "prop-types";
import { ModalHeader, ModalBody, FormGroup, Label } from "reactstrap";
import { Formik, Field, Form } from "formik";
import { string, object } from "yup";
import InputField from "shared/Formik/InputField";
import FormStatus from "shared/Formik/FormStatus";
import FormButtons from "./FormButtons";

const schema = object().shape({
  name: string().required("Name is required")
});

const NameValuePairModal = ({
  submit,
  toggle,
  singularName,
  onSubmitComplete
}) => (
  <Formik
    initialValues={{ name: "" }}
    onSubmit={(entity, actions) => {
      submit(entity)
        .then(resp => {
          console.log(resp);
          onSubmitComplete(resp.data);
        })
        .catch(error => {
          actions.setSubmitting(false);
          actions.setStatus({ msg: error.message });
        });
    }}
    isInitialValid={false}
    validationSchema={schema}
    render={({ status, isSubmitting }) => (
      <Form>
        <ModalHeader>{singularName}</ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label>Name: </Label>
            <Field
              name="name"
              render={formikProps => <InputField {...formikProps} />}
            />
          </FormGroup>
          <FormStatus status={status} />
        </ModalBody>
        <FormButtons toggle={toggle} isSubmitting={isSubmitting} />
      </Form>
    )}
  />
);

NameValuePairModal.propTypes = {
  singularName: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  onSubmitComplete: PropTypes.func.isRequired
};

export default NameValuePairModal;
