import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import { fetchExcelDownload } from "_state/api";

class ExportButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: null
    };
  }

  setError = error => {
    this.setState({ loading: false, error });
  };

  render() {
    const { exportParams } = this.props;
    const { loading, error } = this.state;

    return (
      <React.Fragment>
        <Button
          disabled={loading}
          onClick={() => {
            this.setState({ loading: true, error: null });
            fetchExcelDownload(...exportParams)
              .then(response => {
                this.setState({ loading: false });
              })
              .catch(error => {
                this.setState({ loading: false, error });
                console.log(error);
              });
          }}
        >
          {loading && (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          )}{" "}
          <i className="fas fa-file-download" /> Export to Excel
        </Button>
        <div className="text-danger">{error && error.message}</div>
      </React.Fragment>
    );
  }
}

ExportButton.propTypes = {
  /* eslint-disable react/forbid-prop-types*/
  exportParams: PropTypes.array.isRequired
};

export default ExportButton;
