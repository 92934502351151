import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Input } from "reactstrap";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import { apiRequestType, routerTypes, nameValuePairType } from "_types";
import { startupData } from "_state/ducks/session/selectors";
import {
  queriedNameValuePairs,
  updateNameValuePairObj
} from "_state/ducks/nameValuePair/selectors";
import {
  queryNameValuePairs,
  createNameValuePair,
  updateNameValuePair,
  setNameValuePairs
} from "_state/ducks/nameValuePair/operations";
import NameForm from "shared/Forms/NameForm";
import NameValuePairSelect from "shared/Selects/NameValuePairSelect";
import EditTable from "shared/SmartTable/EditTable";

class NameValuePairsContainer extends Component {
  query = options => {
    const { type, queryNameValuePairs } = this.props;

    queryNameValuePairs({
      ...options,
      equalityFilters: [...options.equalityFilters, { id: "type", value: type }]
    });
  };

  create = entity => {
    const { type, createNameValuePair, match } = this.props;

    return createNameValuePair({
      ...entity,
      companyId: match.params.companyId,
      type
    });
  };

  update = entity => {
    const { type, updateNameValuePair, match } = this.props;

    return updateNameValuePair({
      ...entity,
      companyId: match.params.companyId,
      type
    });
  };

  render() {
    const {
      match,
      queriedNameValuePairs,
      updateNameValuePairObj,
      setNameValuePairs,
      startupData,
      type
    } = this.props;

    const myRole = startupData.data.currentCompany
      ? startupData.data.currentCompany.myRole
      : null;

    return (
      <EditTable
        readOnly={myRole !== "level1" && myRole !== "level2"}
        defaultSortId="lowercaseName"
        match={match}
        query={this.query}
        queriedEntities={queriedNameValuePairs}
        updateObj={updateNameValuePairObj}
        update={this.update}
        set={setNameValuePairs}
        create={this.create}
        FormComponent={NameForm}
        columns={[
          {
            Header: "Name",
            accessor: "name",
            MyCellComponent: Input,
            myCellPassedProps: { type: "text" },
            MyFilterComponent: NameValuePairSelect,
            myFilterPassedProps: { type }
          }
        ]}
      />
    );
  }
}

NameValuePairsContainer.propTypes = {
  type: nameValuePairType.isRequired,
  queriedNameValuePairs: apiRequestType.isRequired,
  queryNameValuePairs: PropTypes.func.isRequired,
  createNameValuePair: PropTypes.func.isRequired,
  updateNameValuePairObj: apiRequestType.isRequired,
  updateNameValuePair: PropTypes.func.isRequired,
  setNameValuePairs: PropTypes.func.isRequired,
  startupData: apiRequestType.isRequired,
  match: routerTypes.match.isRequired
};

const mapStateToProps = makeMapStateToProps({
  queriedNameValuePairs,
  updateNameValuePairObj,
  startupData
});

const mapDispatchToProps = {
  queryNameValuePairs,
  createNameValuePair,
  updateNameValuePair,
  setNameValuePairs
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NameValuePairsContainer);
