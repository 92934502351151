import axios from "axios";

var api = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

const logCall = (path, resp) => {
  console.log(`Called api "${path}" and received`, resp);
};

const logError = (path, err) => {
  const message = `Api call for path "${path}" failed with ${
    err.response.status
  } ${err.response.statusText}.`;

  console.error(message, err.response);

  return err.response.data && !(err.response.data instanceof Blob)
    ? err.response.data
    : message;
};

export const makeApiCall = (
  method,
  path,
  state,
  propagateError = false,
  onSubmitComplete = null
) => {
  return (dataOrParams = {}) => dispatch => {
    dispatch(state.actions.begin());
    console.log(`Calling api "${path}" with `, dataOrParams);

    let fileData;
    if (method === "upload") {
      fileData = new FormData();
      Object.keys(dataOrParams).forEach(key => {
        fileData.append(key, dataOrParams[key]);
      });
    }

    return api
      .request({
        url: path,
        ...(method === "post" && { data: dataOrParams, method }),
        ...(method === "get" && { params: dataOrParams, method }),
        ...(method === "upload" && {
          method: "post",
          data: fileData
          // headers: {
          //   "Content-Type": `multipart/form-data;boundary=${fileData._boundary}`
          // }
        })
      })
      .then(resp => {
        logCall(path, resp);
        dispatch(state.actions.success(resp.data));

        if (onSubmitComplete) {
          const thunk = onSubmitComplete(dataOrParams);
          dispatch(thunk);
        }

        // Return response to be able handle errors later, in component.
        return resp;
      })
      .catch(err => {
        err.response.message = logError(path, err);
        dispatch(state.actions.failure(err.response));

        // Throw an error, to be able handle errors later, in component.
        if (propagateError) {
          throw new Error(err.response.message);
        }
      });
  };
};

export const makeSelectCall = path => dataOrParams => {
  console.log(`Calling api "${path}" with `, dataOrParams);

  const {
    companyId,
    property,
    searchString,
    cursor,
    equalityFilters
  } = dataOrParams;

  return api
    .request({
      url: path,
      method: "post",
      data: {
        companyId,
        pageSize: 10,
        cursor,
        substrFilter: {
          id: property,
          value: searchString
        },
        order: {
          id: property,
          desc: false
        },
        directionChanged: false,
        direction: 1,
        equalityFilters
      }
    })
    .then(resp => {
      logCall(path, resp);
      return resp;
    })
    .catch(err => {
      throw new Error(logError(path, err));
    });
};

export const fetchExcelDownload = (path, data, filename) =>
  api
    .request({
      url: path,
      method: "POST",
      responseType: "blob",
      data
    })
    .then(resp => {
      logCall(path, resp);

      // Download it
      const url = window.URL.createObjectURL(new Blob([resp.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "export.xlsx");
      document.body.appendChild(link);
      link.click();

      return resp;
    })
    .catch(err => {
      throw new Error(logError(path, err));
    });

export default api;
