import { combineReducers } from "redux";
import { createApiRequestState } from "_state/boilerplateKillers";

const duckName = "class";

export const queryState = createApiRequestState(duckName, "query");

export const updateState = createApiRequestState(duckName, "update");

export const importState = createApiRequestState(duckName, "import");

const reducer = combineReducers({
  [queryState.stateName]: queryState.reducer,
  [updateState.stateName]: updateState.reducer,
  [importState.stateName]: importState.reducer
});

export default reducer;
