import React from "react";
import PropTypes from "prop-types";
import ActiveSelect from "./ActiveSelect";

const ActiveFilter = ({ onChange }) => (
  <ActiveSelect
    isClearable
    onChange={value => {
      onChange(value === true || value === false ? value : "");
    }}
  />
);

ActiveFilter.propTypes = {
  onChange: PropTypes.func.isRequired
};

export default ActiveFilter;
