import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import { apiRequestType, routerTypes } from "_types";
import { submitCode } from "_state/ducks/invite/operations";
import { myCompanies, switchCompanyObj } from "_state/ducks/company/selectors";
import { startupData } from "_state/ducks/session/selectors";
import { fetchStartupData } from "_state/ducks/session/operations";
import {
  fetchMyCompanies,
  switchCompany
} from "_state/ducks/company/operations";
import MyCompanies from "./MyCompanies";

export class MyCompaniesContainer extends Component {
  componentDidMount() {
    const { fetchMyCompanies } = this.props;
    fetchMyCompanies();
  }

  render() {
    const {
      myCompanies,
      startupData,
      fetchStartupData,
      switchCompany,
      switchCompanyObj,
      submitCode,
      history
    } = this.props;

    const { currentCompany } = startupData.data;
    const currentCompanyId = currentCompany ? currentCompany.id : null;

    return (
      <MyCompanies
        loading={myCompanies.loading || switchCompanyObj.loading}
        error={myCompanies.error || switchCompanyObj.error}
        myCompanies={myCompanies}
        currentCompanyId={currentCompanyId}
        switchCompany={switchCompany}
        submitCode={submitCode}
        fetchStartupData={fetchStartupData}
        history={history}
      />
    );
  }
}

MyCompaniesContainer.propTypes = {
  myCompanies: apiRequestType.isRequired,
  fetchMyCompanies: PropTypes.func.isRequired,
  startupData: apiRequestType.isRequired,
  fetchStartupData: PropTypes.func.isRequired,
  switchCompany: PropTypes.func.isRequired,
  switchCompanyObj: apiRequestType.isRequired,
  submitCode: PropTypes.func.isRequired,
  history: routerTypes.history.isRequired
};

const mapStateToProps = makeMapStateToProps({
  myCompanies,
  startupData,
  switchCompanyObj
});

const mapDispatchToProps = {
  fetchMyCompanies,
  fetchStartupData,
  switchCompany,
  submitCode
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyCompaniesContainer);
