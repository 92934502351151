import { makeApiCall, makeSelectCall } from "_state/api";
import { queryState, createState, updateState, fetchAllState } from "./creator";

const base = "/subregion";

const querySubregions = makeApiCall("post", `${base}/query`, queryState);

const selectSubregions = makeSelectCall(`${base}/query`);

const setSubregions = queryState.actions.success;

const onSubmitComplete = dataOrParams => {
  return fetchAllSubregions({ companyId: dataOrParams.companyId });
};

const createSubregion = makeApiCall(
  "post",
  `${base}/create`,
  createState,
  true,
  onSubmitComplete
);

const updateSubregion = makeApiCall(
  "post",
  `${base}/update`,
  updateState,
  true,
  onSubmitComplete
);

const fetchAllQuery = makeApiCall("post", `${base}/query`, fetchAllState);

const fetchAllSubregions = ({ companyId }) =>
  fetchAllQuery({
    companyId,
    cursor: null,
    pageSize: 1000,
    direction: 1
  });

export {
  querySubregions,
  setSubregions,
  createSubregion,
  updateSubregion,
  selectSubregions,
  fetchAllSubregions
};
