// Redux builder for api requests with begin, success and failure
import {
  createReducer,
  makeActionCreator,
  getTypePrefix,
  makeApiSelector
} from "./baseHelpers";

export const initialState = {
  data: [],
  loading: false,
  error: null
};

export const getApiTypes = (duckName, stateName) => {
  const typePrefix = getTypePrefix(duckName, stateName);

  return {
    begin: `${typePrefix}BEGIN`,
    success: `${typePrefix}SUCCESS`,
    failure: `${typePrefix}FAILURE`
  };
};

export const makeApiActionCreators = types => ({
  begin: makeActionCreator(types.begin),
  success: makeActionCreator(types.success, "data"),
  failure: makeActionCreator(types.failure, "error")
});

export const createApiReducer = types =>
  createReducer(initialState, {
    [types.begin]: (state, action) => ({
      ...state,
      loading: true,
      error: null
    }),
    [types.success]: (state, action) => ({
      loading: false,
      data: action.data,
      error: null
    }),
    [types.failure]: (state, action) => ({
      loading: false,
      data: [],
      error: action.error
    })
  });

export const createState = (duckName, stateName) => {
  const types = getApiTypes(duckName, stateName);
  const actions = makeApiActionCreators(types);
  const reducer = createApiReducer(types);
  const selector = makeApiSelector(duckName, stateName);
  return {
    actions,
    reducer,
    selector,
    stateName
  };
};
