import React from "react";
import PropTypes from "prop-types";
import { Button } from "reactstrap";
import withEditModeToggle from "./withEditModeToggle";

const Editing = ({ onCancel, onSave, saving }) => (
  <React.Fragment>
    <Button color="success" onClick={onSave} disabled={saving}>
      {saving ? (
        <span className="spinner-border spinner-border-sm" />
      ) : (
        <i className="fas fa-save" />
      )}
    </Button>{" "}
    <Button color="secondary" onClick={onCancel} disabled={saving}>
      Cancel
    </Button>
  </React.Fragment>
);

Editing.propTypes = {
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  saving: PropTypes.bool.isRequired
};

const Reading = ({ onEdit }) => (
  <Button color="primary" onClick={onEdit}>
    <i className="fas fa-pencil-alt" />
  </Button>
);

Reading.propTypes = {
  onEdit: PropTypes.func.isRequired
};

export default withEditModeToggle(Reading, Editing);
