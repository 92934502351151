import React from "react";
import PropTypes from "prop-types";
import { CardTitle, CardSubtitle } from "reactstrap";

const ReportHeader = ({
  report: {
    Header: { companyName, reportTitle, StartPeriod, EndPeriod }
  },
  width
}) => (
  <div style={{ width: width }}>
    <CardTitle className="text-center">
      <h3 className="font-weight-light">{companyName}</h3>
    </CardTitle>
    <CardSubtitle className="text-center">
      <h5>{reportTitle}</h5>
      <h6 className="font-weight-light">{`${StartPeriod} to ${EndPeriod}`}</h6>
    </CardSubtitle>
  </div>
);

ReportHeader.propTypes = {
  report: PropTypes.oneOfType([PropTypes.objectOf(Object), PropTypes.array])
    .isRequired,
  width: PropTypes.number.isRequired
};

export default ReportHeader;
