import { matchPath } from "react-router-dom";
import privateRoutes from "./privateRoutes";

const getRouteFromUrl = url => {
  const aroute = Object.values(privateRoutes).find(route =>
    matchPath(url, {
      path: route.path,
      exact: route.exact
    })
  );
  return aroute ? aroute : null;
};

export default getRouteFromUrl;
