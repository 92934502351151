import {
  queryState,
  createState,
  resendState,
  deleteState,
  submitCodeState
} from "./creator";

export const queriedInvites = queryState.selector;

export const createInviteObj = createState.selector;

export const resendInviteObj = resendState.selector;

export const deleteInviteObj = deleteState.selector;

export const submitCodeObj = submitCodeState.selector;
