import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, Alert } from "reactstrap";

import privateRoutes from "_constants/privateRoutes";

const Reports = ({ qboLink, companyId }) => (
  <Card>
    {!qboLink && (
      <Alert color="danger">
        Your current Company is not connected to QuickBooks, so you won't be
        able to run reports. Click{" "}
        <Link to={privateRoutes.companySettings.getUrl(companyId)}>here</Link>{" "}
        to manage the connection.
      </Alert>
    )}
    <CardBody>
      <div>
        <Link to={privateRoutes.pnl.getUrl(companyId)}>
          <Button size="lg" disabled={!qboLink}>
            <i className="far fa-file" /> Profit and Loss
          </Button>
        </Link>
      </div>
      <br />
      {/* <div>
        <Link to={privateRoutes.productSales.getUrl(companyId)}>
          <Button size="lg" disabled={!qboLink}>
            <i className="fas fa-balance-scale" /> Product Sales
          </Button>
        </Link>
      </div> */}
    </CardBody>
  </Card>
);

Reports.propTypes = {
  qboLink: PropTypes.bool.isRequired,
  companyId: PropTypes.string.isRequired
};

export default Reports;
