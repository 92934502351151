import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import { makeUrl } from "_utils";
import { apiRequestType } from "_types";
import { antiForgeToken } from "_state/ducks/session/selectors";
import { fetchAntiForgeToken } from "_state/ducks/session/operations";

import QboButton from "./QboButton";

class QboButtonContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  qboConnectHandler = () => {
    const { fetchAntiForgeToken, method, companyId, realmId } = this.props;

    this.setState({
      loading: true
    });

    // Send a request to backend to get a state token to pass to qbo
    fetchAntiForgeToken()
      .then(resp => {
        const state = {
          antiForgeUuid: resp.data.antiForgeUuid,
          method,
          ...(realmId && { realmId }),
          ...(companyId && { companyId })
        };
        const stateStr = JSON.stringify(state);

        // Construct the url for QBO connect
        var base = process.env.REACT_APP_INTUIT_OAUTH_BASE_URL;
        var params = {
          client_id: process.env.REACT_APP_INTUIT_CLIENT_ID,
          scope: process.env.REACT_APP_INTUIT_SCOPE,
          redirect_uri: process.env.REACT_APP_INTUIT_REDIRECT_URI,
          response_type: "code",
          state: stateStr
        };

        const url = makeUrl(base, params);

        window.location = url;
      })
      .catch(err => {
        this.setState({ loading: false });
      });
  };

  render() {
    const { antiForgeToken } = this.props;
    const { loading } = this.state;

    return (
      <QboButton
        handleClick={this.qboConnectHandler}
        loading={loading}
        error={antiForgeToken.error}
      />
    );
  }
}

QboButtonContainer.propTypes = {
  antiForgeToken: apiRequestType.isRequired,
  fetchAntiForgeToken: PropTypes.func.isRequired,
  method: PropTypes.oneOf(["create", "update"]),
  companyId: PropTypes.string,
  realmId: PropTypes.string
};

QboButtonContainer.defaultProps = {
  method: "create",
  companyId: undefined,
  realmId: undefined
};

const mapStateToProps = makeMapStateToProps({
  antiForgeToken
});

const mapDispatchToProps = {
  fetchAntiForgeToken
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(QboButtonContainer);
