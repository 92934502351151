import React, { Component } from "react";
import PropTypes from "prop-types";
import Firebase, { withFirebase } from "_auth/Firebase";
import ForgotPassword from "./ForgotPassword";

class ForgotPasswordContainer extends Component {
  componentDidMount() {
    // Redirect to Dashboard if a user is logged in
  }

  render() {
    const { firebase } = this.props;
    const sendPasswordResetEmail = (email, password) =>
      firebase.doSendPasswordResetEmail(email, password);
    return <ForgotPassword sendPasswordResetEmail={sendPasswordResetEmail} />;
  }
}

ForgotPasswordContainer.propTypes = {
  firebase: PropTypes.instanceOf(Firebase).isRequired
};

export default withFirebase(ForgotPasswordContainer);
