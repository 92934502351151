import React from "react";
import PropTypes from "prop-types";
import { Input } from "reactstrap";
import ValidationMessage from "./ValidationMessage";
import isInvalid from "./isInvalid";

const UploadFileField = ({
  field,
  form: { setValues, values, touched, errors, isSubmitting }
}) => {
  const invalid = isInvalid(touched, errors, field);
  return (
    <React.Fragment>
      <Input
        name={field.name}
        onBlur={field.onBlur}
        onChange={event =>
          setValues({
            ...values,
            [event.currentTarget.name]: event.currentTarget.files
          })
        }
        invalid={invalid}
        disabled={isSubmitting}
        type="file"
        accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      />
      <ValidationMessage name={field.name} invalid={invalid} />
    </React.Fragment>
  );
};

UploadFileField.propTypes = {
  field: PropTypes.objectOf(Object).isRequired,
  form: PropTypes.objectOf(Object).isRequired
};

export default UploadFileField;
