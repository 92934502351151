import React from "react";
import PropTypes from "prop-types";
import { apiRequestType } from "_types";
import Report from "shared/Report/Report";
import ReportForm from "shared/Forms/ReportForm/ReportForm";

const ProductSales = ({
  fetchProductSales,
  fetchedProductSales,
  companyId
}) => (
  <div>
    <ReportForm companyId={companyId} fetch={fetchProductSales} />
    <Report
      report={fetchedProductSales.data}
      loading={fetchedProductSales.loading}
      reportType="productSales"
    />
  </div>
);

ProductSales.propTypes = {
  fetchedProductSales: apiRequestType.isRequired,
  fetchProductSales: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired
};

export default ProductSales;
