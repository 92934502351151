import PropTypes from "prop-types";

export const match = PropTypes.shape({
  isExact: PropTypes.bool,
  params: PropTypes.object.isRequired,
  path: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired
});

export const history = PropTypes.shape({
  push: PropTypes.func.isRequired
});

export const location = PropTypes.shape({
  search: PropTypes.string.isRequired
});

export default {
  match,
  history,
  location
};
