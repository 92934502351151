import React from "react";
import PropTypes from "prop-types";
import AccountSelect from "shared/Selects/AccountSelect";
import ValidationMessage from "./ValidationMessage";
import isInvalid from "./isInvalid";

const AccountField = ({
  field,
  form: { touched, errors, isSubmitting, setFieldValue },
  companyId
}) => {
  const invalid = isInvalid(touched, errors, field);
  return (
    <React.Fragment>
      <AccountSelect
        {...field}
        invalid={invalid}
        disabled={isSubmitting}
        companyId={companyId}
        onChange={option => setFieldValue(field.name, option)}
      />
      <ValidationMessage name={field.name} invalid={invalid} />
    </React.Fragment>
  );
};

AccountField.propTypes = {
  field: PropTypes.objectOf(Object).isRequired,
  form: PropTypes.objectOf(Object).isRequired,
  companyId: PropTypes.string.isRequired
};

export default AccountField;
