import React from "react";
import PropTypes from "prop-types";
import { ModalBody } from "reactstrap";
import { Formik, Form } from "formik";
import FormStatus from "shared/Formik/FormStatus";
import FormButtons from "./FormButtons";

const BlankForm = ({
  submit,
  toggle,
  title,
  onSubmitComplete,
  buttonText,
  buttonColor
}) => (
  <Formik
    onSubmit={(data, actions) => {
      submit()
        .then(resp => {
          onSubmitComplete(resp.data);
        })
        .catch(error => {
          actions.setSubmitting(false);
          actions.setStatus({ msg: error.message });
        });
    }}
    isInitialValid
    render={({ status, isSubmitting }) => (
      <Form>
        <ModalBody>
          <h4>{title}</h4>
          <FormStatus status={status} />
        </ModalBody>

        <FormButtons
          toggle={toggle}
          isSubmitting={isSubmitting}
          buttonText={buttonText}
          buttonColor={buttonColor}
        />
      </Form>
    )}
  />
);

BlankForm.propTypes = {
  title: PropTypes.string.isRequired,
  submit: PropTypes.func.isRequired,
  toggle: PropTypes.func.isRequired,
  onSubmitComplete: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  buttonColor: PropTypes.string
};

BlankForm.defaultProps = {
  buttonColor: undefined
};

export default BlankForm;
