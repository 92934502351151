import React from "react";
import { Card, CardBody, Alert, Row, Col } from "reactstrap";
import QboButtonContainer from "shared/QboButton/QboButtonContainer";
import styles from "./AddCompany.module.scss";

const AddCompany = () => (
  <Card className={`mx-auto ${styles.qboCard}`}>
    <CardBody>
      <Row>
        <Col xs="12">
          <Alert color="success">
            <h5 className="alert-heading">Connect to a Quickbooks company</h5>
            <hr />
            <p>
              By Clicking the "Connect to QuickBooks" button, you will be
              redirected to the QuickBooks Online Web Site to authorize the P3A
              Application. Kindly follow the instructions provided by QuickBooks
              Online to successfully connect the P3A Application.
            </p>
          </Alert>
        </Col>
      </Row>

      <QboButtonContainer method="create" />
    </CardBody>
  </Card>
);

export default AddCompany;
