import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import withAuthorization, {
  isAuthed
} from "shared/withAuthorization/withAuthorization";
import withEmailVerification from "shared/withEmailVerification/withEmailVerification";
import { startupData } from "_state/ducks/session/selectors";
import { fetchStartupData } from "_state/ducks/session/operations";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import { apiRequestType } from "_types";
import publicRoutes from "_constants/publicRoutes";
import ListDataLoader from "./ListDataLoader";
import withInactivityLogout from "shared/withInactivityLogout/withInactivityLogout";

export class UserInAppContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true // Don't render anything until we've fetched startup data
    };
  }

  componentDidMount() {
    const { fetchStartupData } = this.props;
    fetchStartupData().then(() => {
      this.setState({
        loading: false
      });
    });
  }

  render() {
    const { startupData, shallowRender } = this.props;
    const { loading } = this.state;
    const { currentCompany } = startupData.data;

    if (shallowRender) {
      // Every child that is connected to redux throws an error if they don't get their data
      // but we don't want to have to mock all of that just to test the container functionality
      // of this component, so we'll just do this.
      return <div />;
    }
    return (
      <ListDataLoader
        loading={loading || startupData.loading}
        error={startupData.error}
        currentCompany={currentCompany}
      />
    );
  }
}

UserInAppContainer.propTypes = {
  startupData: apiRequestType.isRequired,
  fetchStartupData: PropTypes.func.isRequired,
  shallowRender: PropTypes.bool
};

UserInAppContainer.defaultProps = {
  shallowRender: false
};

const mapStateToProps = makeMapStateToProps({
  startupData
});

const mapDispatchToProps = {
  fetchStartupData
};

export default withAuthorization(isAuthed, publicRoutes.login.path)(
  withEmailVerification(
    withInactivityLogout(
      connect(
        mapStateToProps,
        mapDispatchToProps
      )(UserInAppContainer)
    )
  )
);
