import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import { apiRequestType } from "_types";
import { createRegion, fetchAllRegions } from "_state/ducks/region/operations";
import { fetchedRegions } from "_state/ducks/region/selectors";
import privateRoutes from "_constants/privateRoutes";
import NameForm from "shared/Forms/NameForm";
import SelectWithAddNew from "./SelectWithAddNew";
import MySelect from "./MySelect";

class RegionSelect extends React.Component {
  create = entity => {
    const { createRegion, companyId } = this.props;

    return createRegion({
      ...entity,
      companyId: companyId
    });
  };

  render() {
    const {
      companyId,
      isFilter,
      onChange,
      fetchedRegions,
      fetchAllRegions
    } = this.props;

    if (isFilter) {
      return (
        <MySelect
          options={fetchedRegions.data.entities}
          error={fetchedRegions.error}
          {...this.props}
        />
      );
    }

    return (
      <React.Fragment>
        <SelectWithAddNew
          renderForm={(toggle, refreshSelect) => (
            <NameForm
              toggle={toggle}
              singularName={privateRoutes.regions.singularName}
              submit={this.create}
              onSubmitComplete={entity => {
                fetchAllRegions({ companyId });
                onChange(entity);
                refreshSelect();
              }}
              companyId={companyId}
            />
          )}
          options={fetchedRegions.data.entities}
          error={fetchedRegions.error}
          {...this.props}
        />
      </React.Fragment>
    );
  }
}

RegionSelect.propTypes = {
  isFilter: PropTypes.bool,
  createRegion: PropTypes.func.isRequired,
  fetchAllRegions: PropTypes.func.isRequired,
  fetchedRegions: apiRequestType.isRequired,
  onChange: PropTypes.func.isRequired,
  companyId: PropTypes.string.isRequired,
  isClearable: PropTypes.bool
};

RegionSelect.defaultProps = {
  isFilter: false,
  isClearable: true
};

const mapStateToProps = makeMapStateToProps({
  fetchedRegions
});

const mapDispatchToProps = {
  createRegion,
  fetchAllRegions
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegionSelect);
