import React from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { string, boolean, object, date } from "yup";
import { Card, CardBody, Row, Col } from "reactstrap";
import reportPeriods from "_constants/reportPeriods";
import { groupByValues } from "_constants/groupByOptions";
import ReportFormContents from "./ReportFormContents";
import ReportHelpText from "./ReportHelpText";

const startDateError = "Start date is required. ";
const endDateError = "End date is required.";
const reportPeriodError = "Report period is required.";

const schema = object().shape({
  useDateRange: boolean(),
  reportPeriod: string().when("useDateRange", {
    is: false,
    then: string()
      .oneOf(reportPeriods)
      .required(reportPeriodError)
      .typeError(reportPeriodError),
    otherwise: string().nullable()
  }),
  dateRange: object().when("useDateRange", {
    is: true,
    then: object().shape({
      startDate: date()
        .required(startDateError)
        .typeError(startDateError),
      endDate: date()
        .required(endDateError)
        .typeError(endDateError)
    })
  }),
  groupBy: string()
    .oneOf(groupByValues)
    .required("Group by is required.")
});

const ReportForm = ({ companyId, fetch }) => (
  <Card className="d-print-none">
    <CardBody className="p-4">
      <Row>
        <Col>
          <Formik
            initialValues={{
              reportPeriod: null,
              groupBy: undefined,
              dateRange: {},
              useDateRange: false
            }}
            onSubmit={(
              { useDateRange, reportPeriod, dateRange, groupBy },
              actions
            ) => {
              actions.setStatus(null);

              const options = { groupBy, companyId };

              if (useDateRange) {
                options.startDate = dateRange.startDate.format("YYYY-MM-DD");
                options.endDate = dateRange.endDate.format("YYYY-MM-DD");
              } else {
                options.reportPeriod = reportPeriod;
              }
              fetch(options)
                .then(() => {
                  actions.setSubmitting(false);
                })
                .catch(error => {
                  actions.setSubmitting(false);
                  actions.setStatus({ msg: error.message });
                });
            }}
            isInitialValid={false}
            validationSchema={schema}
            render={props => <ReportFormContents {...props} />}
          />
        </Col>
        <Col>
          <ReportHelpText />
        </Col>
      </Row>
    </CardBody>
  </Card>
);

ReportForm.propTypes = {
  companyId: PropTypes.string.isRequired,
  fetch: PropTypes.func.isRequired
};

export default ReportForm;
