import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "react-select";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import { apiRequestType } from "_types";
import { groupByOptions, groupByValues } from "_constants/groupByOptions";
import { startupData } from "_state/ducks/session/selectors";

const groupByOptionsNoCustom = groupByOptions.filter(
  item => item.value !== "custom1" && item.value !== "custom2"
);

const GroupBySelect = ({ onChange, value, disabled, invalid, startupData }) => {
  let options = [];

  if (startupData.data.currentCompany.enableCustomFields) {
    options = groupByOptions.map(item => {
      if (item.value === "custom1") {
        return {
          ...item,
          label: startupData.data.currentCompany.custom1Name
        };
      }
      if (item.value === "custom2") {
        return {
          ...item,
          label: startupData.data.currentCompany.custom2Name
        };
      }
      return item;
    });
  } else {
    options = groupByOptionsNoCustom;
  }

  return (
    <div style={{ maxWidth: "200px" }}>
      <Select
        value={options.find(item => item.value === value)}
        onChange={option => {
          onChange(option.value);
        }}
        options={options}
        isDisabled={disabled}
        className={invalid && "is-invalid"}
        classNamePrefix="react-select"
      />
    </div>
  );
};

GroupBySelect.propTypes = {
  value: PropTypes.oneOf(groupByValues),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool,
  startupData: apiRequestType.isRequired
};

GroupBySelect.defaultProps = {
  value: null,
  disabled: false,
  invalid: false
};

const mapStateToProps = makeMapStateToProps({
  startupData
});

export default connect(
  mapStateToProps,
  null
)(GroupBySelect);
