import React from "react";
import PropTypes from "prop-types";

import ValidationMessage from "./ValidationMessage";
import isInvalid from "./isInvalid";
import PercentInput from "shared/PercentInput/PercentInput";

const PercentageField = ({
  field,
  form: { touched, errors, isSubmitting }
}) => {
  const invalid = isInvalid(touched, errors, field);
  return (
    <React.Fragment>
      <PercentInput {...field} invalid={invalid} disabled={isSubmitting} />
      <ValidationMessage name={field.name} invalid={invalid} />
    </React.Fragment>
  );
};

PercentageField.propTypes = {
  field: PropTypes.objectOf(Object).isRequired,
  form: PropTypes.objectOf(Object).isRequired
};

export default PercentageField;
