import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { makeMapStateToProps } from "_state/boilerplateKillers";
import { apiRequestType, routerTypes } from "_types";
import { format } from "_utils";
import { startupData } from "_state/ducks/session/selectors";
import {
  queriedLabourAccounts,
  updateLabourAccountObj
} from "_state/ducks/labourAccount/selectors";
import {
  queryLabourAccounts,
  createLabourAccount,
  updateLabourAccount,
  setLabourAccounts
} from "_state/ducks/labourAccount/operations";
import LabourAccountForm from "shared/Forms/LabourAccountForm";
import AccountSelect from "shared/Selects/AccountSelect";
import PercentInput from "shared/PercentInput/PercentInput";
import EditTable from "shared/SmartTable/EditTable";

class LabourAccountsContainer extends Component {
  create = entity => {
    const { createLabourAccount, match } = this.props;

    return createLabourAccount({
      ...entity,
      companyId: match.params.companyId
    });
  };

  update = entity => {
    const { updateLabourAccount, match } = this.props;

    return updateLabourAccount({
      ...entity,
      companyId: match.params.companyId
    });
  };

  render() {
    const {
      match,
      queryLabourAccounts,
      queriedLabourAccounts,
      updateLabourAccountObj,
      setLabourAccounts,
      startupData
    } = this.props;

    const myRole = startupData.data.currentCompany
      ? startupData.data.currentCompany.myRole
      : null;

    return (
      <EditTable
        readOnly={myRole !== "level1"}
        match={match}
        query={queryLabourAccounts}
        queriedEntities={queriedLabourAccounts}
        updateObj={updateLabourAccountObj}
        update={this.update}
        set={setLabourAccounts}
        create={this.create}
        FormComponent={LabourAccountForm}
        columns={[
          {
            Header: "Account",
            accessor: "account",
            MyCellComponent: AccountSelect,
            myCellPassedProps: {
              companyId: match.params.companyId,
              readAccessor: "FullyQualifiedName"
            },
            filterable: false,
            sortable: false
          },
          {
            Header: "Allocation to Direct Labour",
            accessor: "allocation",
            filterable: false,
            sortable: false,
            MyCellComponent: PercentInput,
            myCellPassedProps: {
              readAccessor: format.toPercentString
            }
          }
        ]}
      />
    );
  }
}

LabourAccountsContainer.propTypes = {
  queriedLabourAccounts: apiRequestType.isRequired,
  queryLabourAccounts: PropTypes.func.isRequired,
  createLabourAccount: PropTypes.func.isRequired,
  updateLabourAccountObj: apiRequestType.isRequired,
  updateLabourAccount: PropTypes.func.isRequired,
  setLabourAccounts: PropTypes.func.isRequired,
  startupData: apiRequestType.isRequired,
  match: routerTypes.match.isRequired
};

const mapStateToProps = makeMapStateToProps({
  queriedLabourAccounts,
  updateLabourAccountObj,
  startupData
});

const mapDispatchToProps = {
  queryLabourAccounts,
  createLabourAccount,
  updateLabourAccount,
  setLabourAccounts
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LabourAccountsContainer);
