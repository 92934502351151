import React from "react";
import { Link } from "react-router-dom";
import { Alert, Card, CardBody } from "reactstrap";
import privateRoutes from "_constants/privateRoutes";

const NoCompany = () => (
  <Card>
    <CardBody>
      <Alert color="warning">
        You don't have any companies yet. You can add a company on the{" "}
        <Link to={privateRoutes.myCompanies.path}>My Companies</Link> page.
      </Alert>
    </CardBody>
  </Card>
);

export default NoCompany;
