import React from "react";
import PropTypes from "prop-types";

const FormStatus = ({ status }) => {
  if (status && status.msg) {
    return <div className="invalid-feedback d-block">{status.msg}</div>;
  }
  return null;
};

FormStatus.propTypes = {
  status: PropTypes.shape({
    msg: PropTypes.string
  })
};

FormStatus.defaultProps = {
  status: null
};

export default FormStatus;
