import { makeApiCall } from "_state/api";
import {
  authUserState,
  startupDataState,
  antiForgeTokenState
} from "./creator";

const fetchStartupData = makeApiCall(
  "post",
  "/user/startup-data",
  startupDataState
);

const setAuthUser = authUserState.actions.set;

const fetchAntiForgeToken = makeApiCall(
  "post",
  "/anti-forge-token/fetch",
  antiForgeTokenState
);

export { setAuthUser, fetchStartupData, fetchAntiForgeToken };
