import React from "react";
import PropTypes from "prop-types";
import { componentType } from "_types";
import ValidationMessage from "./ValidationMessage";
import isInvalid from "./isInvalid";

const ReportPeriodField = ({
  field,
  form: { touched, errors, isSubmitting, setFieldValue },
  SelectComponent
}) => {
  const invalid = isInvalid(touched, errors, field);
  return (
    <React.Fragment>
      <SelectComponent
        {...field}
        invalid={invalid}
        disabled={isSubmitting}
        onChange={option => setFieldValue(field.name, option)}
        selected={field.value} // Handles datepicker
      />
      <ValidationMessage name={field.name} invalid={invalid} />
    </React.Fragment>
  );
};

ReportPeriodField.propTypes = {
  field: PropTypes.objectOf(Object).isRequired,
  form: PropTypes.objectOf(Object).isRequired,
  SelectComponent: componentType
};

ReportPeriodField.defaultProps = {
  SelectComponent: null
};

export default ReportPeriodField;
