// Separate file so when we mapStateToProps we can use shorthand object notation
import {
  authUserState,
  startupDataState,
  antiForgeTokenState
} from "./creator";

export const authUser = authUserState.selector;
export const startupData = startupDataState.selector;
export const antiForgeToken = antiForgeTokenState.selector;
