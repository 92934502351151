import React from "react";
import PropTypes from "prop-types";
import Select from "react-select";
import reportPeriods from "_constants/reportPeriods";

const getOption = option => option.value;

const options = reportPeriods.map(item => ({
  value: item
}));

const ReportPeriodSelect = ({ onChange, value, disabled, invalid }) => (
  <div style={{ maxWidth: "200px" }}>
    <Select
      getOptionLabel={getOption}
      getOptionValue={getOption}
      value={value ? { value } : value}
      onChange={option => {
        onChange(option.value);
      }}
      options={options}
      isDisabled={disabled}
      className={invalid && "is-invalid"}
      classNamePrefix="react-select"
    />
  </div>
);

ReportPeriodSelect.propTypes = {
  value: PropTypes.oneOf(reportPeriods),
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  invalid: PropTypes.bool
};

ReportPeriodSelect.defaultProps = {
  value: null,
  disabled: false,
  invalid: false
};

export default ReportPeriodSelect;
